import React from 'react';
import { create } from 'store/create';
import { shallow } from 'zustand/shallow';
import useMeStore from 'store/useMeStore';
import Api from 'api/Api';

const useCompanyStore = create(set => ({
    data: {
        name: '',
        address: '',
        zip: '',
        city: '',
        organizationNumber: '',
        phone: '',
        mail: '',
        web: '',
        provider: 'tinee studio',
        provierLink: 'https://www.pinecostudios.com',
    },
    isSet: false,
    setCompany: payload =>
        set(state => ({ data: { ...state.data, ...payload }, isSet: true })),
}));

export default function useCompany() {
    const { company, isSet, setCompany } = useCompanyStore(
        state => ({
            company: state.data,
            isSet: state.isSet,
            setCompany: state.setCompany,
        }),
        shallow
    );

    const token = useMeStore(state => state.token);

    React.useEffect(() => {
        if (!isSet) {
            fetch();
        }
    }, []);

    const fetch = React.useCallback(async () => {
        try {
            const response = await Api.internalInfo.fetchCompanyInfo(token);
            const { status, data } = response;
            if (status === 200) {
                setCompany(data);
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
        }
    }, [token]);

    return { company };
}
