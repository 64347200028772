import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Travelsheet, TravelsheetKeyType } from '../typings';
import TextField from 'components/interface/textField/TextField';
import Card from 'components/interface/card/Card';
import Collapse from '@mui/material/Collapse';
import Button from 'components/interface/Button/Button';
import { DatePicker } from 'components/interface/datePicker/DatePicker';

const useStyles = makeStyles()(theme => ({
    cardWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    fieldWrapper: {
        'width': '100%',
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'flex-start',
        '& > *': {
            marginTop: theme.spacing(1),
        },
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

interface Props {
    travelsheet: Travelsheet | null;
    canSubmit: boolean;
    onTravelsheetFieldChange: (
        key: TravelsheetKeyType,
        index: number
    ) => (value: string) => void;
}

const TravelsheetComponent = ({
    travelsheet,
    canSubmit,
    onTravelsheetFieldChange,
}: Props): JSX.Element => {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState<{
        [key in TravelsheetKeyType]: boolean;
    }>({
        travel1: true,
        travel2: true,
        travel3: true,
        travel4: true,
        travel5: true,
        travel6: true,
        travel7: true,
    });

    const handleToggleHidden = (key: TravelsheetKeyType) =>
        setOpen(prev => ({ ...prev, [key]: !prev[key] }));

    const buildCard = (key: TravelsheetKeyType) => {
        if (!canSubmit && travelsheet && travelsheet[key] === null) {
            return null;
        }
        const values = travelsheet ? travelsheet[key] : [];
        return (
            <Card className={classes.cardWrapper}>
                <DatePicker
                    label='Datum'
                    value={values[0] || ''}
                    onChange={onTravelsheetFieldChange(key, 0)}
                    variant='outlined'
                    disabled={open[key] || !canSubmit ? true : false}
                    fullWidth
                />
                <Collapse timeout={500} in={!open[key]}>
                    <div className={classes.fieldWrapper}>
                        {buildTextField(
                            'Färdsätt',
                            values && values[1] ? values[1] : '',
                            'text',
                            key,
                            1
                        )}
                        {buildTextField(
                            'Från',
                            values && values[2] ? values[2] : '',
                            'text',
                            key,
                            2
                        )}
                        {buildTextField(
                            'Till',
                            values && values[3] ? values[3] : '',
                            'text',
                            key,
                            3
                        )}
                        {buildTextField(
                            'Antal mil',
                            values && values[4] ? values[4] : '',
                            'number',
                            key,
                            4
                        )}
                        {buildTextField(
                            'Utlägg enl kvitto',
                            values && values[5] ? values[5] : '',
                            'number',
                            key,
                            5
                        )}
                    </div>
                </Collapse>
                <Button
                    className={classes.button}
                    color='primary'
                    variant='outlined'
                    onClick={() => handleToggleHidden(key)}>
                    {open[key] ? (canSubmit ? 'Ändra' : 'Visa') : 'Dölj'}
                </Button>
            </Card>
        );
    };

    const buildTextField = (
        label: string,
        value: string,
        type: string,
        key: TravelsheetKeyType,
        index: number
    ) => {
        return (
            <TextField
                id={key + index}
                type={type}
                key={key + '' + index}
                value={value || ''}
                label={label}
                onChange={onTravelsheetFieldChange(key, index)}
                disabled={open[key] || !canSubmit ? true : false}
                InputLabelProps={type === 'date' ? { shrink: true } : {}}
                fullWidth
            />
        );
    };

    return (
        <>
            {buildCard('travel1')}
            {buildCard('travel2')}
            {buildCard('travel3')}
            {buildCard('travel4')}
            {buildCard('travel5')}
            {buildCard('travel6')}
            {buildCard('travel7')}
        </>
    );
};

export default TravelsheetComponent;
