import React from 'react';
import { makeStyles } from 'tss-react/mui';
import useWindowWidth from 'components/hooks/useWindowWidth';

const useStyles = makeStyles<void, 'safeAreaInset'>()(
    (theme, _params, classes) => ({
        base: {
            position: 'relative',
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: theme.palette.background.backgroundColor,
        },
        mobile: {
            userSelect: 'none',
            touchAction: 'manipulation',
            [`&.${classes.safeAreaInset}`]: {
                paddingTop: 'env(safe-area-inset-top, 0px)',
                paddingLeft: 'env(safe-area-inset-left, 0px)',
                paddingRight: 'env(safe-area-inset-right, 0px)',
            },
        },
        safeAreaInset: {},
    })
);

interface Props {
    children: JSX.Element | Array<JSX.Element>;
    className?: string;
    ignoreSafeAreaInset?: boolean;
}

export default function Page({
    children,
    className,
    ignoreSafeAreaInset,
}: Props) {
    const { classes, cx } = useStyles();
    const { isMobile } = useWindowWidth();

    return (
        <div
            className={cx(
                classes.base,
                {
                    [classes.mobile]: isMobile,
                    [classes.safeAreaInset]: !ignoreSafeAreaInset,
                },
                className
            )}>
            {children}
        </div>
    );
}
