import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import useMeStore from 'store/useMeStore';

const useStyles = makeStyles<void, 'loading'>()((theme, _params, classes) => ({
    body: {
        maxWidth: '350px',
        width: '100%',
        flexShrink: 0,
        opacity: 1,
        transition: 'opacity 2.0s ease-in-out',
        [`&.${classes.loading}`]: {
            transition: 'opacity 1.0s ease-in-out',
            opacity: 0,
        },
    },
    welcomeText: {
        userSelect: 'none',
        color: theme.palette.primaryDark.main,
        paddingBottom: theme.spacing(4),
    },
    welcomeTextTitle: {
        paddingBottom: theme.spacing(1),
    },
    buttonWrapper: {
        paddingTop: theme.spacing(4),
    },
    gotToLoginButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '50%',
        textDecoration: 'underline',
        color: theme.palette.primaryDark.main,
        userSelect: 'none',
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: theme.spacing(2),
    },
    loading: {},
}));

interface ContentProps {
    loading: boolean;
    error: boolean;
}

export default function Content({ loading, error }: ContentProps) {
    const { classes, cx } = useStyles();
    const isLoggedIn = useMeStore(state => state.isLoggedIn);

    return (
        <div
            className={cx(classes.body, {
                [classes.loading]: loading,
            })}>
            <div className={classes.welcomeText}>
                <Typography variant='h5' className={classes.welcomeTextTitle}>
                    <b>
                        {error
                            ? 'Något gick fel'
                            : 'Du har nu avregistrerat dig'}
                    </b>
                </Typography>
                <Typography variant='body1'>
                    {error
                        ? 'Det gick inte att avregistrera dig, försök igen genom att klicka på länken i mailet.'
                        : 'Om du ångrar dig kan du logga in och registrera dig igen.'}
                </Typography>
            </div>
            <div className={classes.buttonWrapper}>
                {error ? null : (
                    <>
                        <div className={classes.gotToLoginButton}>
                            <NavLink exact to={isLoggedIn ? '/' : '/logga-in'}>
                                <Typography variant='body2' color='inherit'>
                                    <b>Tillbaka till login</b>
                                </Typography>
                            </NavLink>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
