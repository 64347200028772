import React from 'react';
import App from 'pages/App';
import useDocumentTitle from 'components/hooks/useDocumentTitle';
import { Redirect, useLocation } from 'react-router-dom';

export default function LandingPage() {
    useDocumentTitle('');
    const location = useLocation()
    return (
        <App>
            <Redirect
                exact
                to={{
                pathname: "/uppdrag",
                state: { from: location }
                }}
            />
        </App>
    );
}