export interface User {
    id: number;
    username: string;
    name: string;
    surname: string;
    ulevel: number;
    userSettings?: {[key in SETTINGS]: UserSetting};
    digitalIDEnabled: boolean;
}

export interface UserSetting {
    id: number;
    name: string;
    label: string;
    value: boolean;
}

export enum SETTINGS  {
    USE_SPLIT_TEXTFIELDS_TIMEREPORT = 'USE_SPLIT_TEXTFIELDS_TIMEREPORT',
};