import UserApi from './UserApi';
import AssignmentApi from './AssignmentApi';
import CompetenceApi from './CompetenceApi';
import PDFApi from './PDFApi';
import InternalInfoApi from './InternalInfoApi';
import ProfileApi from './ProfileApi';
import FilesApi from './FilesApi';
import CountryApi from './CountryApi';
import OpenAPI from './OpenAPI';
import { APP_TYPE } from 'utils/env';
import StaffAPI from './staffApi';
import DigitalIdApi from './DigitalIdApi';
import EmailAPI from './emailAPI';
import QuestionnaireApi from './Questionnaire';

const IS_LOCAL = process.env.REACT_APP_IS_LOCAL === 'true';
const IS_DEV = process.env.REACT_APP_IS_DEV === 'true';
export const AppType = APP_TYPE.KONSULT;

function getHostURL() {
    if (IS_LOCAL && IS_DEV) {
        return 'http://127.0.0.1:8098';
    } else if (!IS_LOCAL && IS_DEV) {
        return 'https://dev-api.vbsverige.se';
    } else if (!IS_LOCAL && !IS_DEV) {
        return 'https://api.vbsverige.se';
    }
}

function getWebSocketURL() {
    if (IS_LOCAL && IS_DEV) {
        return 'ws://127.0.0.1:8034';
    } else if (!IS_LOCAL && IS_DEV) {
        return 'wss://dev-ws.vbsverige.se';
    } else if (!IS_LOCAL && !IS_DEV) {
        return 'wss://ws.vbsverige.se';
    }
    return '';
}

function getOldHostURL() {
    if (IS_LOCAL && IS_DEV) {
        return 'http://portal.vbsverige.test';
    } else if (!IS_LOCAL && IS_DEV) {
        return 'https://dev-portal.vbsverige.se';
    } else if (!IS_LOCAL && !IS_DEV) {
        return 'https://portal.vbsverige.se';
    }
}

class Api {
    Host = getHostURL();
    WebSocketHost = getWebSocketURL();
    OldHost = getOldHostURL();
    Timeout = 35000;
    Timeout5Min = 300000;

    // Create objects of subAPI's
    user = new UserApi();
    assignment = new AssignmentApi();
    competence = new CompetenceApi();
    pdf = new PDFApi();
    internalInfo = new InternalInfoApi();
    openAPI = new OpenAPI();
    profile = new ProfileApi();
    files = new FilesApi();
    country = new CountryApi();
    staff = new StaffAPI();
    digitalId = new DigitalIdApi();
    email = new EmailAPI();
    questionnaire = new QuestionnaireApi();

    constructor() {
        if (!Api.instance) {
            Api.instance = this;
        }

        return Api.instance;
    }
}

const api = new Api();
Object.freeze(api);

export default api;
export { IS_LOCAL, IS_DEV };
