import React from 'react';
import Wrapper from '../Wrapper';
import { LoginWithBankID } from './BankID';
import NormalLogin from './Normal';
import useLoginStore, { LOGIN_METHOD } from './store/useLoginStore';
import { Box, ClickAwayListener, styled, Typography } from '@mui/material';
import MaterialSymbol from 'components/Materialsymbol';
import Button, { ButtonProps } from 'components/interface/Button/Button';
import { BankIDLogo } from './BankIDLogo';

const Body = styled('div', {
    shouldForwardProp: prop => prop !== '$loading',
})<{ $loading: boolean }>(({ $loading }) => {
    return {
        maxWidth: '350px',
        width: '100%',
        flexShrink: 0,
        opacity: 1,
        display: 'flex',
        flexDirection: 'column',
        transition: 'opacity 2.0s ease-in-out',
        ...($loading && {
            transition: 'opacity 1.0s ease-in-out',
            opacity: 0,
        }),
    };
});

interface Props {
    isMobile: boolean;
    redirectedFrom: string | null;
}

export const LoginButton = styled(Button)<ButtonProps>({
    width: '100%',
    height: '56px',
});

export default function LoginComponent({ isMobile, redirectedFrom }: Props) {
    const [loading, setLoading] = React.useState(false);
    React.useState(false); // this is only so we don't have to repeat the selection step after choosing an option
    const [method, setMethod] = useLoginStore(state => [
        state.method,
        state.setMethod,
    ]);

    const handleSetMethod = (v: LOGIN_METHOD) => {
        setMethod(v);
    };

    const renderLogin = () => {
        switch (method) {
            case LOGIN_METHOD.BANKID:
                return (
                    <LoginWithBankID
                        setLoading={setLoading}
                        redirectedFrom={redirectedFrom}
                        setMethod={handleSetMethod}
                    />
                );
            default:
                return (
                    <NormalLogin
                        setLoading={setLoading}
                        redirectedFrom={redirectedFrom}
                    />
                );
        }
    };

    return (
        <Wrapper
            loading={loading}
            isMobile={isMobile}
            footer={<Footer onSelect={handleSetMethod} isMobile={isMobile} />}>
            <Body $loading={loading}>{renderLogin()}</Body>
        </Wrapper>
    );
}

interface FooterProps {
    isMobile: boolean;
    onSelect: (method: LOGIN_METHOD) => void;
}

const FooterWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'open' && prop !== 'isMobile',
})<{ open: boolean; isMobile: boolean }>(({ theme, open, isMobile }) => ({
    width: '100%',
    position: isMobile ? 'fixed' : 'absolute',
    bottom: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingBottom: `calc(${theme.spacing(4)} + env(safe-area-inset-bottom, 0px))`,
    transition: 'all ease-in-out ' + theme.transitions.duration.short + 'ms',
    background: 'white',
    borderRadius: '40px 40px 0px 0px',
    overflow: 'hidden',
    transform:
        'translate3d(0, calc(100% - calc(64px + env(safe-area-inset-bottom, 0px))), 0)', // this will show 64px of the footer
    gap: theme.spacing(4),
    ...(open && {
        transform: 'translate3d(0, 0, 0)',
        boxShadow: 'rgba(0, 0, 0, 0.18) 0px -5px 20px',
    }),
}));

const FooterHeader = styled('div')<{ open: boolean }>(({ theme, open }) => ({
    'display': 'flex',
    'height': '64px',
    'paddingLeft': theme.spacing(4),
    'paddingRight': theme.spacing(4),
    'marginBottom': `env(safe-area-inset-bottom, 0px)`,
    'flexDirection': 'row',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'width': '100%',
    'color': theme.palette.primary[1000],
    'userSelect': 'none',
    'cursor': 'pointer',
    'transition':
        'all ease-in-out ' + theme.transitions.duration.shorter + 'ms',
    '@media (hover: hover)': {
        // this is to prevent the hover effect on touch devices
        '&:hover': {
            color: theme.palette.primary[800],
        },
    },
    ...(open && {
        color: theme.palette.primary[800],
    }),
}));

const MethodSelectorWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}));

const Footer = ({ onSelect, isMobile }: FooterProps) => {
    const [open, setOpen] = React.useState(false);

    const handleSelectMethod = (v: LOGIN_METHOD) => {
        onSelect(v);
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <FooterWrapper open={open} isMobile={isMobile}>
                <FooterHeader
                    onClick={() => setOpen(prev => !prev)}
                    open={open}>
                    <Typography
                        variant='body1'
                        color='inherit'
                        fontFamily='poppins'
                        fontWeight={600}>
                        Fler inloggningssätt
                    </Typography>
                    <Box
                        sx={theme => ({
                            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition:
                                'transform ease-in-out ' +
                                theme.transitions.duration.complex +
                                'ms',
                        })}>
                        <MaterialSymbol
                            symbol='keyboard_arrow_up'
                            color='inherit'
                            fontSize='semi_large'
                        />
                    </Box>
                </FooterHeader>
                <MethodSelectorWrapper>
                    <LoginButton
                        color='primaryDark'
                        variant='contained'
                        onClick={() => handleSelectMethod(LOGIN_METHOD.BANKID)}
                        disableElevation>
                        <BankIDLogo />
                        <Typography variant='body1' color='inherit'>
                            <b>Mobilt BankID</b>
                        </Typography>
                    </LoginButton>
                    <LoginButton
                        color='secondary'
                        variant='contained'
                        onClick={() => handleSelectMethod(LOGIN_METHOD.NORMAL)}
                        disableElevation>
                        <Typography variant='body1' color='inherit'>
                            <b>Lösenord</b>
                        </Typography>
                    </LoginButton>
                </MethodSelectorWrapper>
            </FooterWrapper>
        </ClickAwayListener>
    );
};
