import React from 'react';
import DesktopMenu from 'components/Header/desktop/View';
import MobileMenu from 'components/Header/mobile/Header';
import useWindowWidth from 'components/hooks/useWindowWidth';
import useMeStore from 'store/useMeStore';

export default function Header() {
    const me = useMeStore(state => state.me);
    const puppeteer = useMeStore(state => state.puppeteer);
    const { isMobile } = useWindowWidth();

    const renderDesktopMenu = () => {
        return <DesktopMenu me={me} puppeteer={puppeteer} />;
    };

    const renderMobileMenu = () => {
        return <MobileMenu me={me} puppeteer={puppeteer} />;
    };

    return <>{!isMobile ? renderDesktopMenu() : renderMobileMenu()}</>;
}
