/**
 * Extracts a numeric string from the input string, removing all non-numeric characters.
 *
 * @param value - The input string to extract the numeric string from.
 * @param allowNegative - Optional. If true, allows the input string to start with a negative sign.
 * @returns A string representation of the extracted numeric value.
 */
/*
export function extractNumeric(value: string, allowNegative?: boolean) {
    if (!value) {
        return '';
    }
    if (value === '-') {
        return '0';
    }
    value = value.toString();

    const isNegative = value[0] === '-' && allowNegative;
    if (isNegative) {
        value = value.substring(1);
    }

    value = value.replace(/[^0-9]/g, ''); // remove all non-numeric characters

    // If there are only zeros
    if (/^[0]+$/.test(value)) {
        return isNegative ? '-0' : '0';
    }

    const numbers = value.replace(/^0+/, ''); // remove leading zeros

    if (numbers.length === 0) {
        return '0';
    }

    return isNegative ? '-' + numbers : numbers;
}*/

/**
 * Checks if n is numeric
 * @returns An boolean representing if it's numeric or not
 */
export function isNumeric(n: string) {
    return !isNaN(parseFloat(n)) && isFinite(parseFloat(n));
}

/**
 * Extracts a floating point number from a string, with a maximum number of decimal places.
 * @param value The string to extract the float from.
 * @param maxDecimals The maximum number of decimal places to allow in the extracted float.
 * @returns The extracted float as a string, with decimal places separated by a period.
 */
export function extractNumber(
    value: string,
    maxDecimals: number,
    allowNegative: boolean = false
) {
    if (!value) {
        return '';
    }
    value = value.replace(',', '.');

    const isNegative = value[0] === '-' && allowNegative;
    if (isNegative) {
        value = value.substring(1);
    }

    value = value.replace(/[^0-9.]/g, ''); // remove all non-numeric characters

    let foundSeperator = false;
    let decimalPlaces = 0;
    let v = '';
    for (let i = 0; i < value.length; i++) {
        if (value[i] === '.') {
            if (foundSeperator || maxDecimals === 0) {
                continue;
            }
            foundSeperator = true;
        }
        if (foundSeperator) {
            if (decimalPlaces > maxDecimals) {
                break;
            }
            decimalPlaces++;
        }
        v += value[i];
    }

    return isNegative ? '-' + v : v;
}

export function toFixedNumber(num: number, digits: number): number {
    const base = 10;
    var pow = Math.pow(base, digits);
    return Math.round(num * pow) / pow;
}

export function formatPersonalNumber(pnr?: string, insertDash?: boolean) {
    if (!pnr) {
        return '';
    }
    let processedText = extractNumber(pnr, 0);
    if (
        processedText.length > 5 &&
        processedText.length < 12 &&
        processedText[0] !== '1' &&
        processedText[0] !== '2'
    ) {
        if (processedText[0] === '0') {
            processedText = '20' + processedText;
        } else {
            processedText = '19' + processedText;
        }
    }

    if (insertDash) {
        // Insert a - if needed, and allow for manual placement of '-' if it is at the correct location
        if (processedText.length > 8) {
            processedText =
                processedText.substring(0, 8) +
                '-' +
                processedText.substring(8, 12);
        } else if (processedText.length === 8 && pnr[pnr.length - 1] === '-') {
            processedText += '-';
        }
        return processedText;
    }

    return processedText.substring(0, 12);
}

/**
 * Checks if an pnr is of the correct format
 * Correct formats
 * YYYY MM DD XXX = 12
 * YYYY MM DD = 8
 */
export function isValidPersonalNumber(pnr?: string, requireFull?: boolean) {
    pnr = extractNumber(pnr || '', 0);

    if (requireFull && pnr.length !== 12) {
        return false;
    }

    if (pnr.length !== 8 && pnr.length !== 12) {
        return false;
    }
    return true;
}

export function capitalizeFirstLetter(s: string) {
    if (s === '') {
        return s;
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Extracts the hours and minutes from a string representing a float
 * @param {String} h, the value to be extracted from
 * @return An object with hours and minutes as integers, {hours: int, minutes: int}
 */
export function extractHoursAndMinutes(h:string) {
    const hFloat = parseFloat(h)
    const hour = h ? Math.floor(hFloat) : 0;
    // hFloat % 1 will check if there is any decimals 5.23 % 1 => 0.23 to it is not 0 as in false
    // then 5.23 * 60 will change it to minutes instead of hours. Then % 60 will remove any overflow
    // so it is the same as taking hFloat % 1 * 60
    const minutes = hFloat && hFloat % 1 ? Math.round((hFloat * 60) % 60) : 0;
    return { hours: hour, minutes: minutes };
}

/**
 * Converts minutes to hours in decimals
 * @param {String} value, the value to be converted
 * @return A float.
 */
export function minutesToHoursDecimals(minutes:string) {
    return minutes ? parseInt(minutes) / 60 : 0;
}

/**
 * Extract the minutes from hours in decimal form, rounded to 2 decimal places.
 * @param {String} value, the value to be extracted from
 * @return A float representing the minutes in hour format.
 */
export function extractMinutesFromHoursDecimals(h:string) {
    const hFloat = parseFloat(h) % 1
    return h ? parseFloat(hFloat.toFixed(2)) : 0;
}