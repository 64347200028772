import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import PDFIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DocIcon from '@mui/icons-material/DescriptionOutlined';
import ExcelIcon from '@mui/icons-material/InsertChartOutlined';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DownloadIcon from '@mui/icons-material/GetAppOutlined';
import TrashIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/CreateOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/lab/Skeleton';
import {
    formatBytes,
    extractFileName,
    extractFileExtension,
} from 'utils/utils_legacy';
import LinearProgress from '@mui/material/LinearProgress';
import Collapse from '@mui/material/Collapse';
import {
    QUEUED_FILE_STATUS,
    FILE_STATUS,
} from 'components/Content/assignment/page/hooks/useFiles';
import ConfirmationMenu from 'components/interface/ConfirmationMenu/ConfirmationMenu';
import {
    format,
    parseISO,
    isBefore,
    addDays,
    differenceInYears,
} from 'date-fns';
import { sv } from 'date-fns/locale';
import ReactGA from 'react-ga4';
import Red from '@mui/material/colors/red';
import useMeStore from 'store/useMeStore';
import Alert, {
    STATUS as ALERT_STATUS,
} from 'components/interface/alert/Alert';
import { DigitalIDMethodToLabel, GetStaffSignature } from 'typings/file';
import StatusChip, { STATUS } from 'components/Content/assignment/StatusChip';
import Button from '../Button/Button';

const useStyles = makeStyles()((theme, params, classes) => ({
    queuedFile: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '58px',
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        marginTop: theme.spacing(),
        position: 'relative',
        flexShrink: 0,
    },
    fileBaseComponent: {
        'width': '100%',
        'display': 'flex',
        'flexDirection': 'column',
        'paddingLeft': theme.spacing(),
        'paddingRight': theme.spacing(),
        '& + &': {
            borderTop: '1px solid ' + theme.palette.divider,
        },
        'flexShrink': 0,
        [`&.${classes.mobile}`]: {
            'paddingTop': theme.spacing(2),
            'paddingBottom': theme.spacing(2),
            'height': 'auto',
            'border': 'none',
            'userSelect': 'none',
            '& + &': {
                marginTop: theme.spacing(),
            },
        },
    },
    file: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '58px',
        [`&.${classes.mobile}`]: {
            height: 'auto',
        },
    },
    signature: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: theme.spacing(2),
        [`&.${classes.mobile}`]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(0),
        },
    },
    mobile: {},
    fileStart: {
        width: '1%',
        flexGrow: '1',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    name: {
        'marginLeft': theme.spacing(),
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'flex-start',
        'justifyContent': 'center',
        'maxWidth': '100%',
        'minWidth': 0,
        '& > p': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
        },
    },
    dateText: {
        lineHeight: '1',
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        [`&.${classes.mobile}`]: {
            margin: 0,
            justifyContent: 'flex-start',
        },
    },
    infoText: {
        lineHeight: '1',
    },
    infoDivider: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        background: theme.palette.text.disabled,
        height: 4,
        width: 4,
        borderRadius: 20,
        flexShrink: '0',
    },
    status: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    menuButton: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    editButton: {
        marginRight: theme.spacing(),
        marginLeft: theme.spacing(),
    },
    removeButton: {
        marginRight: theme.spacing(),
        color: Red[500],
    },
    errorIcon: {
        marginLeft: theme.spacing(),
    },
    actionButton: {
        marginRight: theme.spacing(),
    },
    fileEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexShrink: '0',
    },
    loadingBar: {
        width: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
    },
    checkboxWrapper: {
        'display': 'flex',
        'justifyContent': 'flex-start',
        'alignItems': 'center',
        'marginTop': theme.spacing(1),
        'cursor': 'pointer',
        '& > p': {
            userSelect: 'none',
        },
    },
    signButton: {
        width: '100%',
    },
}));

const Title = ({ name, date, uploader, isLoading, isGenerated, isMobile }) => {
    const { classes, cx } = useStyles();
    const uploadedDate = parseISO(date);

    const getIcon = () => {
        const lc = extractFileExtension(name).toLowerCase();

        if (lc.match(/(pdf)$/) != null) {
            return <PDFIcon className={classes.icon} />;
        } else if (lc.match(/(odt|doc|docx|rtf|txt|epub)$/) != null) {
            return <DocIcon className={classes.icon} />;
        } else if (lc.match(/(xml|csv|xlsx|xls|xlsm|xltx)$/) != null) {
            return <ExcelIcon className={classes.icon} />;
        } else if (lc.match(/(jpg|jpeg|png|gif)$/) != null) {
            return <ImageIcon className={classes.icon} />;
        } else {
            return <FileIcon className={classes.icon} />;
        }
    };

    const nameTooltip = () => (
        <>
            <Typography variant='caption' color='inherit'>
                {name}
            </Typography>
            {uploader && date ? (
                <>
                    <br />
                    <Typography variant='caption' color='inherit'>
                        {isGenerated ? 'Skapad' : 'Uppladdad'} den{' '}
                        {format(uploadedDate, 'PPPp', { locale: sv })}
                    </Typography>
                </>
            ) : undefined}
        </>
    );

    const renderDate = () =>
        !!date ? (
            <Typography
                variant='caption'
                color='textSecondary'
                className={classes.dateText}>
                {(isGenerated ? 'Skapad ' : 'Uppladdad ') +
                    format(uploadedDate, 'P', { locale: sv })}
            </Typography>
        ) : undefined;

    return (
        <div className={classes.fileStart}>
            {isLoading ? (
                <Skeleton
                    animation='pulse'
                    variant='circle'
                    width='32px'
                    height='32px'
                />
            ) : (
                getIcon()
            )}
            <Tooltip
                enterTouchDelay={500}
                title={nameTooltip()}
                placement='top'>
                <div className={classes.name}>
                    {isLoading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='156px'
                        />
                    ) : (
                        <Typography variant='body1' color='textPrimary'>
                            {name}
                        </Typography>
                    )}

                    {isLoading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : isMobile ? undefined : (
                        renderDate()
                    )}
                </div>
            </Tooltip>
        </div>
    );
};

const Size = ({ size }) => {
    const { classes, cx } = useStyles();
    return (
        <Typography
            variant='caption'
            color='textSecondary'
            className={classes.infoText}>
            {formatBytes(size, 0)}
        </Typography>
    );
};

const QueuedFile = ({
    id,
    name,
    extension,
    size,
    status,
    statusMessage,
    onRemove,
    onRename,
    isMobile,
}) => {
    const { classes, cx } = useStyles();
    const [open, setOpen] = useState(false);
    const fullName = () => name + (extension ? '.' + extension : '');

    useEffect(() => {
        setOpen(true);
    }, []);

    const handleRemove = () => {
        setOpen(false);
        setTimeout(() => onRemove(id), 500);
    };

    const renderButtons = () => (
        <>
            <IconButton
                color='default'
                onClick={() => onRename(id, name)}
                size='small'
                className={classes.editButton}>
                <EditIcon />
            </IconButton>
            <IconButton
                color='default'
                onClick={handleRemove}
                size='small'
                className={classes.removeButton}>
                <DeleteIcon />
            </IconButton>
        </>
    );

    return (
        <Collapse timeout={500} in={open} style={{ width: '100%' }}>
            <Card className={classes.queuedFile}>
                <Title
                    name={fullName()}
                    renderInfo={() => <Size size={size} />}
                    isMobile={isMobile}
                />
                <div className={classes.fileEnd}>
                    {!isMobile ? <Size size={size} /> : undefined}
                    {status !== QUEUED_FILE_STATUS.UPLOADING
                        ? renderButtons()
                        : undefined}
                    {status === QUEUED_FILE_STATUS.FAILED ? (
                        <Tooltip
                            enterTouchDelay={500}
                            title={
                                <Typography variant='caption' color='inherit'>
                                    {statusMessage}
                                </Typography>
                            }
                            placement='top'>
                            <ErrorIcon
                                color='error'
                                className={classes.errorIcon}
                            />
                        </Tooltip>
                    ) : undefined}
                </div>
                {status === QUEUED_FILE_STATUS.UPLOADING ? (
                    <LinearProgress className={classes.loadingBar} />
                ) : undefined}
            </Card>
        </Collapse>
    );
};

QueuedFile.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    extension: PropTypes.string,
    size: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
};

const BaseComponent = ({ children, isMobile }) => {
    const { classes, cx } = useStyles();

    return isMobile ? (
        <Card className={cx(classes.fileBaseComponent, classes.mobile)}>
            {children}
        </Card>
    ) : (
        <div className={classes.fileBaseComponent}>{children}</div>
    );
};

export default function File({
    id,
    date,
    name,
    type,
    parent,
    uploader,
    requiresSignature,
    signatureData,
    isGenerated,
    onDownload,
    onTrash,
    isLoading,
    isMobile,
    onSignFile,
}) {
    const { classes, cx } = useStyles();
    const [isDeleteFileMenuOpen, setIsDeleteFileMenuOpen] = useState(false);
    const me = useMeStore(state => state.me);
    const mySignature = GetStaffSignature(signatureData);

    const handleDownload = () => {
        onDownload(id);
    };

    const handleTrash = () => {
        setIsDeleteFileMenuOpen(true);
    };

    const renderEnd = () => (
        <>
            <IconButton
                className={classes.actionButton}
                color='default'
                onClick={handleDownload}
                size='small'>
                <DownloadIcon />
            </IconButton>
            {uploader?.id === me.id ? (
                <IconButton
                    className={classes.removeButton}
                    color='default'
                    onClick={handleTrash}
                    size='small'>
                    <TrashIcon />
                </IconButton>
            ) : undefined}
        </>
    );

    const renderDeleteFileMenu = () => (
        <ConfirmationMenu
            open={isDeleteFileMenuOpen}
            message={'Är du säker på att ta bort filen?'}
            title={'Ta bort fil'}
            cancelLabel={'Avbryt'}
            confirmLabel={'Ja, ta bort filen'}
            cancelFunction={() => setIsDeleteFileMenuOpen(false)}
            confirmFunction={() => {
                setIsDeleteFileMenuOpen(false);
                onTrash(id);
            }}
            onClose={() => setIsDeleteFileMenuOpen(false)}
            maxWidth='sm'
            isMobile={isMobile}></ConfirmationMenu>
    );

    const renderSignature = () => {
        const signed = () => {
            const date = parseISO(mySignature.date);

            return (
                <StatusChip
                    status={STATUS.POSITIVE}
                    text={
                        <>
                            Signerad med{' '}
                            <b>
                                {DigitalIDMethodToLabel(
                                    mySignature?.digitalIDMethod
                                )}
                            </b>{' '}
                            {format(date, 'P', { locale: sv })}
                        </>
                    }
                />
            );
        };
        const notsigned = () => (
                <Button
                    variant='outlined'
                    onClick={() => onSignFile(id)}
                    classes={{ root: classes.signButton }}>
                    Signera dokument
                </Button>
        );
        return (
            <div
                className={cx(classes.signature, {
                    [classes.mobile]: isMobile,
                })}>
                {mySignature ? signed() : notsigned()}
            </div>
        );
    };

    return (
        <BaseComponent isMobile={isMobile}>
            <div className={cx(classes.file, { [classes.mobile]: isMobile })}>
                <Title
                    name={name}
                    date={date}
                    uploader={uploader}
                    isLoading={isLoading}
                    isGenerated={isGenerated}
                />
                <div className={classes.fileEnd}>
                    {isLoading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        renderEnd()
                    )}
                </div>
            </div>
            {requiresSignature ? renderSignature() : null}
            {isDeleteFileMenuOpen ? renderDeleteFileMenu() : undefined}
        </BaseComponent>
    );
}

File.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    date: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    parent: PropTypes.object,
    uploader: PropTypes.object.isRequired,
    status: PropTypes.string,
    onDownload: PropTypes.func.isRequired,
    onTrash: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
};

export { QueuedFile };
