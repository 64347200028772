import useMeStore from 'store/useMeStore';
import { resetAllStores } from 'store/create';
import { history } from 'router';

export async function Logout() {
    //const setIsLoggingOut = useMeStore.getState().setIsLoggingOut;
    const clearUserStore = useMeStore.getState().logout;

    window.onbeforeunload = null;
    resetAllStores();
    clearUserStore();

    history.replace('/logga-in');
}
