import React from 'react';
import useCompany from 'store/useCompanyStore';
import './FooterMobile.css';

export default function Footer() {
    const { company } = useCompany();

    return (
        <div className='footer-mobile-wrp'>
            <span>{company.name}</span>
            <span>{company.address}</span>
            <span>{company.zip + ' ' + company.city}</span>
            <span>{'Org. nr: ' + company.organizationNumber}</span>
            <span>
                Telefon:{' '}
                <a href={'tel:' + company.phone.split(' ').join('')}>
                    {company.phone}
                </a>
            </span>
            <a href={'mailto:' + company.mail}>{company.mail}</a>
            <span>Version {process.env.REACT_APP_BUILD_VERSION}</span>
        </div>
    );
}
