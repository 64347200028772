// Translates Golang weekday to swedish
export enum WEEKDAY {
    'Söndag' = 0,
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag',
}
