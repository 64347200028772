import axios from "./Axios";
import Api from "./Api";
import { StaffPayload } from "typings/staff";
import {
    DIGITAL_ID_METHOD,
    DigitalIdPayload,
} from 'components/bankID/typings';

export default class ProfileApi {
    /**
     * Fetches personal profile data.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    fetchProfile(token: string) {
        return axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/me/profile`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            params: {},
        });
    }

    /**
     * Updates personal profile data.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    updateProfile(token: string, fields: StaffPayload) {
        return axios({
            method: "PATCH",
            timeout: Api.Timeout,
            url: `${Api.Host}/me/profile`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                ...fields,
            },
        });
    }

    /**
     * Fetches the user's email.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    fetchUser(token: string) {
        return axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/me/user`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            params: {},
        });
    }

    /**
     * Updates the user's email and confirms the change with the current password.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    updateUsername(token: string, username: string, password: string) {
        return axios({
            method: 'PATCH',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/user/username`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            data: {
                username,
                password,
            },
        });
    }

    updateUsernameDigitalID(
        method: DIGITAL_ID_METHOD,
        token: string,
        transactionId?: string,
        payload?: DigitalIdPayload
    ) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/user/username/digital_id`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                method: method,
                transactionId: transactionId,
            },
            data: {
                ...payload,
            },
        });
    }

    /**
     * Updates the user's password and confirms the change with the current password.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    updatePassword(token: string, oldPassword: string, newPassword: string) {
        return axios({
            method: 'PATCH',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/user/password`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            data: {
                password: oldPassword,
                newPassword,
            },
        });
    }

    updatePasswordDigitalID(
        method: DIGITAL_ID_METHOD,
        token: string,
        transactionId?: string,
        payload?: DigitalIdPayload
    ) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/user/password/digital_id`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                method: method,
                transactionId: transactionId,
            },
            data: {
                ...payload,
            },
        });
    }


    hasPassword(token: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/user/password`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
    }

    removePassword(token: string) {
        return axios({
            method: 'DELETE',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/user/password`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
    }
}
