import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Popper from 'components/interface/Popper/Popper';
import MenuList from 'components/interface/menu/MenuList';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles<void, 'hasChildren' | 'active'>()(
    (theme, _params, classes) => ({
        item: {
            'display': 'flex',
            'alignItems': 'center',
            'cursor': 'pointer',
            'height': '60px',
            'color': theme.palette.text.secondary,
            [`&.${classes.hasChildren}`]: {
                cursor: 'unset',
            },
            [`&.${classes.active}`]: {
                color: theme.palette.text.primary,
            },
            '&:hover': {
                color: theme.palette.text.primary,
            },
        },
        itemText: {
            userSelect: 'none',
            fontFamily: "'Ubuntu', sans-serif",
            fontWeight: '700',
        },
        hasChildren: {},
        active: {},
        expandIcon: {
            color: theme.palette.text.secondary,
            [`&.${classes.active}`]: {
                color: theme.palette.text.primary,
            },
            transition: theme.transitions.create('transform', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        expandIconHover: {
            transform: 'rotate(180deg)',
            color: theme.palette.text.primary,
        },
        child: {
            'color': theme.palette.text.secondary,
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'flex-start',
            'textAlign': 'start',
            'cursor': 'pointer',
            'padding': theme.spacing(1, 2),
            'minHeight': '40px',
            'borderRadius': theme.shape.borderRadius,
            'userSelect': 'none',
            [`&.${classes.active}`]: {
                color: theme.palette.primary[1000],
                background: theme.palette.primary[50],
            },
            '&:hover': {
                color: grey[900],
                background: grey[100],
            },
        },
        childText: {
            userSelect: 'none',
            fontFamily: "'Ubuntu', sans-serif",
            fontWeight: '700',
        },
    })
);

interface NavigationParentProps {
    text: string;
    link?: string;
    active: boolean;
    children: Array<NavigationChildProps>;
}

export const NavigationParent = ({
    text,
    link,
    active,
    children,
}: NavigationParentProps) => {
    const { classes, cx } = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorEl = React.useRef(null);

    const handleEnter = () => {
        setOpen(true);
    };

    const handleLeave = () => {
        setOpen(false);
    };

    const renderItem = () => (
        <div
            ref={anchorEl}
            className={cx(classes.item, {
                [classes.active]: active,
                [classes.hasChildren]: children.length > 0,
            })}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}>
            <Typography
                className={cx(classes.itemText, {
                    [classes.active]: active,
                })}
                variant='body1'
                color='inherit'>
                {text}
            </Typography>
            {children.length > 0 ? renderChildren() : null}
        </div>
    );

    const renderChildren = () => (
        <>
            <ExpandMore
                className={cx(classes.expandIcon, {
                    [classes.active]: active,
                    [classes.expandIconHover]: open,
                })}
                fontSize='small'
            />
            <Popper
                open={open}
                anchorEl={anchorEl.current}
                transition
                disablePortal
                placement='bottom'>
                <MenuList>
                    {children.map((v, i) => (
                        <NavigationChild key={i} {...v} />
                    ))}
                </MenuList>
            </Popper>
        </>
    );

    return link ? <Link to={link}>{renderItem()}</Link> : renderItem();
};

export interface NavigationChildProps {
    text: string;
    link: string;
    active?: boolean;
}

export const NavigationChild = ({
    text,
    link,
    active,
}: NavigationChildProps) => {
    const { classes, cx } = useStyles();

    return (
        <Link to={link}>
            <div className={cx(classes.child, { [classes.active]: active })}>
                <Typography
                    className={cx(classes.childText, {
                        [classes.active]: active,
                    })}
                    variant='body2'
                    color='inherit'>
                    {text}
                </Typography>
            </div>
        </Link>
    );
};
