import React from 'react';
import NotAuthorized from '../pages/ErrorPages/NotAuthorized';
import useHTTPStatusCodeStore from 'store/useHTTPStatusCodeStore';
import { useLocation } from 'react-router-dom';

interface Props {
    children: JSX.Element;
}

export default function HTTPStatusInterceptor({ children }: Props) {
    const code = useHTTPStatusCodeStore(state => state.code);
    const error = useHTTPStatusCodeStore(state => state.error);
    const reset = useHTTPStatusCodeStore(state => state.reset);

    const location = useLocation();

    React.useEffect(() => {
        if (error) {
            reset();
        }
    }, [location.pathname]);

    const renderErrorPage = () => {
        switch (code) {
            case 401:
                return <NotAuthorized />;
            default:
                return <NotAuthorized />;
        }
    };

    return error ? renderErrorPage() : children;
}
