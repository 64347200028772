import React from 'react';
import Api, { AppType, IS_DEV, IS_LOCAL } from 'api/Api';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import { setCookie, getCookie } from 'utils/cookies';
import useMeStore, { SessionData } from 'store/useMeStore';
import { resetAllStores } from 'store/create';
import { APP_TYPE } from 'utils/env';
import jwt_decode from 'jwt-decode';

// Minimum amount of time that the login should take, in ms.
// This ensures that the loading animation is smooth
const LOGIN_MIN_TIME = 600;

export const getSameSiteCookie = (name: string) => {
    const cookie = `${IS_DEV ? 'dev' : ''}-${name}-`;
    // @ts-ignore
    if (AppType === APP_TYPE.TOOLS) {
        return cookie + 'tools';
    } else if (AppType === APP_TYPE.KONSULT) {
        return cookie + 'konsult';
    }
};

const getOppositeSiteCookie = (name: string) => {
    const cookie = `${IS_DEV ? 'dev' : ''}-${name}-`;
    // @ts-ignore
    if (AppType === APP_TYPE.t) {
        return cookie + 'konsult';
    } else if (AppType === APP_TYPE.KONSULT) {
        return cookie + 'tools';
    }
};

const getOppositeSiteURL = () => {
    if (IS_LOCAL && IS_DEV) {
    // @ts-ignore
        if (AppType === APP_TYPE.TOOLS) {
            return 'http://localhost:3001';
        } else {
            return 'http://localhost:3000';
        }
    }

    const url = 'https://' + (IS_DEV ? 'dev-' : '');
    // @ts-ignore
    if (AppType === APP_TYPE.TOOLS) {
        return url + 'konsult.vbsverige.se';
    } else if (AppType === APP_TYPE.KONSULT) {
        return url + 'tools.vbsverige.se';
    }
    return url;
};

export const cookieDomain = () => (IS_LOCAL ? 'localhost' : 'vbsverige.se');

const shouldRedirect = (ulevel: number) => {
    // @ts-ignore
    if (AppType === APP_TYPE.TOOLS) {
        return ulevel === 3;
    } else if (AppType === APP_TYPE.KONSULT) {
        return ulevel === 1 || ulevel === 2;
    }
};

export const isLoggedInCookieName = 'isLoggedIn';

export default function useLogin() {
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const setUserData = useMeStore(state => state.setUserData);
    const logout = useMeStore(state => state.logout);
    const { token, isLoggedIn } = useMeStore(state => state);

    const pushNotification = useNotificationStore(state => state.push);

    React.useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const jwt = searchParams.get('token');
        if (jwt) {
            loginWithSessionToken(jwt);
            return;
        } else {
            setLoading(false);

            // This code will reset the session if the user is logged in but the token has expired
            let decodedToken: any = null;
            try {
                decodedToken = jwt_decode(token);
            } catch (e) {
                decodedToken = null;
            }
            const dateNow = new Date();
            const tokenDate = decodedToken
                ? new Date(decodedToken.exp * 1000)
                : new Date();

            if (isLoggedIn && tokenDate.getTime() < dateNow.getTime()) {
                resetAllStores();
                logout();
            }
        }

        // @ts-ignore - This code is specific for tools and konsult site.
        if (AppType === APP_TYPE.KONSULT) {
            const cookie_name = getOppositeSiteCookie(isLoggedInCookieName);
            const il = getCookie(cookie_name);
            // only proceed if the user is logged in to the opposite site
            if (!il) {
                return;
            }

            const path = window.location.href.split('?')[0];
            const searchParams = new URLSearchParams(window.location.search);
            // from sso is set byt the sso redirect page
            if (
                searchParams.has('fromSSO') ||
                searchParams.has('wasRedirected')
            ) {
                return;
            }
            searchParams.set('wasRedirected', 'true');
            const new_url = `${path}?${searchParams.toString()}`;
            const b64 = window.btoa(new_url);
            const url = `${getOppositeSiteURL()}/sso/check?url=${b64}`;
            window.location.replace(url);
        }
    }, []);

    const login = async (username: string, password: string) => {
        const start = new Date();
        setLoading(true);
        setError(false);

        try {
            const response = await Api.user.loginToUser(username, password);
            const { status, data } = response;
            if (status === 200) {
                const { user, puppeteer, token } = data;
                await setLoginData(start, { user, puppeteer, token });
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setError(true);
            pushNotification(
                NotificationTypes.error,
                'Du har angivit fel användarnamn eller lösenord.',
                'login-fail',
                1
            );
        }
        setLoading(false);
    };

    const loginWithSessionToken = async (jwt: string) => {
        setLoading(true);
        const start = new Date();
        try {
            const response = await Api.user.getSessionData(jwt);
            const { status, data } = response;
            if (status === 200) {
                const { user, puppeteer, token } = data;
                await setLoginData(start, { user, puppeteer, token });
            } else {
                throw new Error('Failed logging in');
            }
        } catch (e) {
            pushNotification(
                NotificationTypes.error,
                'Det gick inte att logga in, försök igen.',
                'login-fail',
                1
            );
        }
        setLoading(false);
    };

    // Internal function that should not be exposed
    const _setLoginData = async (start: Date, data: SessionData) => {
        resetAllStores();

        const end = new Date();
        const diffMS = LOGIN_MIN_TIME - (end.getTime() - start.getTime());
        await new Promise<void>(resolve =>
            setTimeout(
                () => {
                    resolve();
                },
                diffMS > 0 ? diffMS : 1
            )
        );
        setUserData(data);
        // Set the cookie to indicate that the user is logged in, used for the redirect logic
        setCookie(
            getSameSiteCookie(isLoggedInCookieName),
            'true',
            1,
            cookieDomain()
        );
        return;
    };

    // IMPORTANT: this login logic with redirect is specific for tools and konsult site. so this function CANNOT be copied over.
    const setLoginData = async (
        start: Date,
        { user, puppeteer, token }: SessionData
    ) => {
        setLoading(true);
        if (shouldRedirect(user.ulevel)) {
            const searchParams = new URLSearchParams('');
            searchParams.set('token', token);
            searchParams.set('wasRedirected', 'true');
            const url = `${getOppositeSiteURL()}/logga-in?${searchParams.toString()}`;
            window.location.replace(url);
        } else if (!!user && !!token) {
            await _setLoginData(start, { user, puppeteer, token });
        }
        setLoading(false);
    };

    return { login, loading, error, setLoginData };
}
