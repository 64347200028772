import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MuiPopper, { PopperProps } from '@mui/material/Popper';
import Paper, { PaperProps } from '@mui/material/Paper';
import Grow, { GrowProps } from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const useStyles = makeStyles<
    { placement?: PopperProps['placement'] },
    'arrow'
>()((theme, { placement }, classes) => ({
    popper: {
        padding: theme.spacing(
            placement?.startsWith('bottom') ? 1 : 0,
            placement?.startsWith('left') ? 1 : 0,
            placement?.startsWith('top') ? 1 : 0,
            placement?.startsWith('right') ? 1 : 0,
        ),
        zIndex: 100000,
        [`&[data-popper-placement*="bottom"] .${classes.arrow}`]: {
            'top': 0,
            'left': 0,
            'marginTop': '-0.9em',
            'width': '3em',
            'height': '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        [`&[data-popper-placement*="top"] .${classes.arrow}`]: {
            'bottom': 0,
            'left': 0,
            'marginBottom': '-0.9em',
            'width': '3em',
            'height': '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
        [`&[data-popper-placement*="right"] .${classes.arrow}`]: {
            'left': 0,
            'marginLeft': '-0.9em',
            'height': '3em',
            'width': '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        [`&[data-popper-placement*="left"] .${classes.arrow}`]: {
            'right': 0,
            'marginRight': '-0.9em',
            'height': '3em',
            'width': '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
    arrow: {
        'position': 'absolute',
        'fontSize': 7,
        'width': '3em',
        'height': '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
}));

interface Props extends PopperProps {
    children: JSX.Element;
    flip?: boolean;
    handleClose?: (e: MouseEvent | TouchEvent) => void;
    PaperProps?: PaperProps;
    GrowProps?: Omit<GrowProps, 'children'>;
    offset?: number;
    placement?: PopperProps['placement'];
}

export default function Popper({
    children,
    flip,
    handleClose,
    PaperProps,
    GrowProps,
    offset,
    placement,
    ...props
}: Props) {
    const { classes, theme } = useStyles({ placement });
    // https://popper.js.org/docs/v2/modifiers/ check to fix the modifier object. no priority as we dont user the arrows
    return (
        <MuiPopper className={classes.popper} placement={placement} {...props}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    {...GrowProps}
                    timeout={{
                        enter: theme.transitions.duration.standard,
                        exit: theme.transitions.duration.standard,
                    }}
                    id='menu-list-grow'
                    style={{
                        transformOrigin:
                            placement === 'bottom'
                                ? 'center top'
                                : 'center bottom',
                    }}>
                    <Paper {...PaperProps}>
                        <ClickAwayListener
                            onClickAway={e =>
                                !!handleClose ? handleClose(e) : null
                            }>
                            {children}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </MuiPopper>
    );
}
