import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from 'components/interface/Button/Button';
import Typography from '@mui/material/Typography';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles()(theme => ({
    mobile: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-end',
    },
    contentText: {
        marginBottom: theme.spacing(2),
    },
    noPaddingContent: {
        padding: '0',
    },
}));

interface ConfirmationModalProps {
    children?: React.ReactNode;
    message?: React.ReactNode;
    title?: string;
    cancelFunction: (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    confirmFunction: (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    confirmDisabled?: boolean;
    cancelLabel?: string;
    confirmLabel?: string;
    loading?: boolean;
    isMobile?: boolean;
    noPaddingContent?: boolean;
}

// This is its own component due to performance reasons stated in the MUI docs https://material-ui.com/components/modal/#performance
const ConfirmationModal = ({
    children,
    message,
    title,
    cancelFunction,
    confirmFunction,
    confirmDisabled,
    cancelLabel,
    confirmLabel,
    loading,
    isMobile,
    noPaddingContent,
}: ConfirmationModalProps): JSX.Element => {
    const { classes, cx } = useStyles();
    return (
        <>
            {title ? (
                <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            ) : null}
            <DialogContent
                className={cx({
                    [classes.noPaddingContent]: noPaddingContent,
                })}>
                {message ? (
                    <DialogContentText
                        id='alert-dialog-description'
                        className={classes.contentText}>
                        {message}
                    </DialogContentText>
                ) : null}
                {children}
            </DialogContent>
            <DialogActions
                classes={{
                    root: cx({ [classes.mobile]: isMobile }),
                }}>
                {cancelLabel ? (
                    <Button onClick={cancelFunction} disabled={loading}>
                        <Typography variant='button' color='textSecondary'>
                            {cancelLabel}
                        </Typography>
                    </Button>
                ) : null}
                <Button
                    onClick={confirmFunction}
                    disabled={loading || confirmDisabled}
                    autoFocus>
                    <Typography
                        variant='button'
                        color={confirmDisabled ? 'textSecondary' : 'primary'}>
                        {confirmLabel}
                    </Typography>
                </Button>
            </DialogActions>
        </>
    );
};

interface ConfirmationProps extends Omit<DialogProps, 'onClose'> {
    children?: React.ReactNode;
    open: boolean;
    message?: React.ReactNode;
    title?: string;
    cancelLabel?: string;
    confirmLabel?: string;
    cancelFunction: (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    confirmFunction: (
        e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    confirmDisabled?: boolean;
    onClose: (e?: Event) => void;
    loading?: boolean;
    disableClickAway?: boolean;
    isMobile?: boolean;
    noPaddingContent?: boolean;
    fullWidth?: boolean;
}

export default function ConfirmationMenu({
    children,
    open,
    message,
    title,
    cancelLabel,
    confirmLabel,
    cancelFunction,
    confirmFunction,
    confirmDisabled,
    onClose,
    loading,
    disableClickAway,
    isMobile,
    noPaddingContent,
    fullWidth,
    ...rest
}: ConfirmationProps): JSX.Element {
    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (
                    disableClickAway &&
                    (reason === 'escapeKeyDown' || reason === 'backdropClick')
                ) {
                    return;
                } else {
                    onClose();
                }
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth={fullWidth}
            {...rest}>
            <ConfirmationModal
                children={children}
                message={message}
                title={title}
                cancelLabel={cancelLabel}
                confirmLabel={confirmLabel}
                confirmDisabled={confirmDisabled}
                cancelFunction={cancelFunction}
                confirmFunction={confirmFunction}
                loading={loading}
                isMobile={isMobile}
                noPaddingContent={noPaddingContent}
            />
        </Dialog>
    );
}
