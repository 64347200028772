import axios from './Axios';
import Api from './Api';

export default class CompetenceApi {

    /**
     * Fetches all competences.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    fetchCompetences(token = undefined) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/competence`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
    }
}