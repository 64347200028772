import React from 'react';
import Api from 'api/Api';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import { extractFileName, extractFileExtension } from 'utils/utils_legacy';
import useMeStore from 'store/useMeStore';

export const FILE_STATUS = {
    LOADING: 'LOADING',
    NONE: 'NONE',
};

export const QUEUED_FILE_STATUS = {
    UPLOADING: 'UPLOADING',
    FAILED: 'FAILED',
    NONE: 'NONE',
};

export const RESOURCE = {
    ASSIGNMENT: 'assignment',
};

const MAX_FILE_SIZE = 1024 * 1024 * 30; //30MB

const initialStateQueuedFile = {
    id: null,
    name: '',
    extension: '',
    category: null,
    size: 0,
    type: '',
    status: QUEUED_FILE_STATUS.NONE,
    statusMessage: '',
};

export default function useFiles({ id, resource }) {
    const [files, setFiles] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [queue, setQueue] = React.useState([]);
    const nextQueueID = React.useRef(1);

    const token = useMeStore(state => state.token);
    const pushNotification = useNotificationStore(state => state.push);

    React.useEffect(() => {
        setFiles([]);
        setQueue([]);
        setLoading(false);
        setError(false);
    }, [id, resource]);

    const fetchFiles = React.useCallback(async () => {
        setLoading(true);
        try {
            const response = await Api.files.fetchFiles(token, resource, id);
            const { status, data } = response;
            if (status === 200) {
                const files = data || [];
                setFiles(files);
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setFiles([]);
            setError(true);
            pushNotification(
                NotificationTypes.error,
                'Det gick inte att hämta filer.',
                'files-fail',
                1
            );
        }
        setLoading(false);
    }, [resource, id, token]);

    /** File specific actions */

    const setFileStatus = (fileID, status) => {
        setFiles(prev => {
            const i = prev.findIndex(file => file.id === fileID);
            if (i === -1) {
                return prev;
            }
            const head = prev.slice(0, i);
            const tail = prev.slice(i + 1);
            return [
                ...head,
                {
                    ...prev[i],
                    status: status,
                },
                ...tail,
            ];
        });
    };

    const removeFile = fileID =>
        setFiles(prev => prev.filter(file => file.id !== fileID));

    const downloadFile = fileID => {
        const windowReference = window.open();
        const download = async fileID => {
            try {
                const response = await Api.files.fetchFileDownloadURL(
                    token,
                    resource,
                    id,
                    fileID
                );
                const { status, data } = response;
                if (status === 200) {
                    const url = data.url;
                    windowReference.location.replace(url);
                } else {
                    throw new Error('');
                }
            } catch (err) {
                console.log(err);
                windowReference.close();
                pushNotification(
                    NotificationTypes.error,
                    err?.response?.data?.message ||
                        'Det gick inte att ladda ned filen, försök igen.',
                    'files-fail',
                    2
                );
            }
        };
        download(fileID);
    };

    const trashFile = React.useCallback(
        async fileID => {
            setFileStatus(fileID, FILE_STATUS.LOADING);
            try {
                const response = await Api.files.trash(
                    token,
                    resource,
                    id,
                    fileID
                );
                const { status, data } = response;
                if (status === 200) {
                    removeFile(fileID);

                    pushNotification(
                        NotificationTypes.success,
                        'Filen har tagits bort.',
                        'files-success',
                        1
                    );
                } else {
                    throw new Error('');
                }
            } catch (err) {
                console.log(err);
                setFileStatus(fileID, FILE_STATUS.NONE);

                pushNotification(
                    NotificationTypes.error,
                    err?.response?.data?.message ||
                        'Det gick inte att ta bort filen, försök igen.',
                    'files-fail',
                    1
                );
            }
        },
        [resource, id, token]
    );

    /** Queue actions */

    const updateQueuedFileData = (fileID, data) => {
        setQueue(prev => {
            const i = prev.findIndex(file => file.id === fileID);
            if (i === -1) {
                return prev;
            }
            const head = prev.slice(0, i);
            const tail = prev.slice(i + 1);
            return [
                ...head,
                {
                    ...prev[i],
                    ...data,
                },
                ...tail,
            ];
        });
    };

    const setQueuedFileStatus = (fileID, status, message) => {
        updateQueuedFileData(fileID, {
            status: status,
            statusMessage: message || '',
        });
    };

    const uploadQueuedFiles = async () => {
        setUploading(true)
        let uploadedCount = 0;
        const amountInQueue = queue.length;
        for (let i = 0; i < queue.length; i++) {
            const file = queue[i];
            setQueuedFileStatus(file.id, QUEUED_FILE_STATUS.UPLOADING);
            try {
                // Gets an signed url for uploading to google storage
                const response = await Api.files.prepareFileForUpload(
                    token,
                    resource,
                    id,
                    file
                );
                const { status, data } = response;
                if (status !== 200 || !data.url || !data.queueID) {
                    throw new Error('Failed uploading file');
                }
                // Uploads the file
                await Api.files.googleStorageUploadFile(
                    data.url,
                    file.originalFile
                );
                // Records the file upload to the DB
                const newFileResponse = await Api.files.uploadFile(
                    token,
                    resource,
                    id,
                    data.queueID
                );
                const { status: newStatus, data: newData } = newFileResponse;
                if (newStatus !== 200) {
                    throw new Error('Failed uploading file');
                }
                dequeueFile(file.id);
                setFiles(prev => [newData, ...prev]);
                uploadedCount++;
            } catch (err) {
                console.log(err);
                let message =
                    'Filen kunde inte laddas upp, kontrollera filens namn mot redan uppladdade filer.';
                if (
                    !!err?.response?.data?.message &&
                    typeof err.response.data.message === 'string'
                ) {
                    message = err.response.data.message;
                }
                setQueuedFileStatus(
                    file.id,
                    QUEUED_FILE_STATUS.FAILED,
                    message
                );

                pushNotification(
                    NotificationTypes.error,
                    'En eller flera filer misslyckades.',
                    'files-upload',
                    2
                );
            }
        }
        if (uploadedCount === 1) {
            pushNotification(
                NotificationTypes.success,
                'Filen har laddats upp.',
                'files-upload',
                1
            );
        } else if (uploadedCount > 1) {
            pushNotification(
                NotificationTypes.success,
                uploadedCount + '/' + amountInQueue + ' filer har laddats upp.',
                'files-upload',
                1
            );
        }
        setUploading(false)
    };

    const enqueueFiles = React.useCallback(files => {
        for (let i = 0; i < files.length; i++) {
            const file = {
                name: extractFileName(files[i].name),
                extension: extractFileExtension(files[i].name),
                size: files[i].size,
                type: files[i].type,
                originalFile: files[i],
            };
            if (file.type === '' && file.extension === '') {
                pushNotification(
                    NotificationTypes.error,
                    'Filen saknar filändelse, försök igen.',
                    'files-enqueue-fail',
                    1
                );
            } else if (file.size > MAX_FILE_SIZE) {
                pushNotification(
                    NotificationTypes.error,
                    'Filen överskrider den tillåtna maxgränsen. (30MB)',
                    'files-enqueue-fail',
                    1
                );
            } else {
                setQueue(prev => [
                    ...prev,
                    {
                        ...initialStateQueuedFile,
                        ...file,
                        id: nextQueueID.current++,
                    },
                ]);
            }
        }
    }, []);

    const dequeueFile = fileID =>
        setQueue(prev => prev.filter(file => file.id !== fileID));

    const renameQueuedFile = (fileID, name) => {
        updateQueuedFileData(fileID, {
            name,
        });
    };

    return {
        fetchFiles,
        files,
        loading,
        uploading,
        error,
        queue,
        onUpload: uploadQueuedFiles,
        onDownload: downloadFile,
        onTrash: trashFile,
        onEnqueue: enqueueFiles,
        onDequeue: dequeueFile,
        onRenameQueuedFile: renameQueuedFile,
        onRefetch: fetchFiles,
    };
}
