import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Button from 'components/interface/Button/Button';
import AssignmentDesktop from './desktop/View';
import AssignmentMobile from './mobile/View';
import useWindowWidth from 'components/hooks/useWindowWidth';
import useAssignment from './hooks/useAssignment';
import { TYPE, BankIDDialog } from 'components/bankID/BankID';
import api from 'api/Api';

interface Props {
    assignmentID: string;
}

export default function Assignment({ assignmentID }: Props) {
    const { informationProps, timesheetProps, filesProps, statsProps } =
        useAssignment(assignmentID);
    const { isMobile } = useWindowWidth();
    const [selectedSignFileID, setSelectedSignFileID] = React.useState(0);

    const renderTimesheetButton = () => {
        const { status, timesheet } = informationProps.data;
        const isActive = status?.id === 3 || status?.id === 4;
        const hasTimesheet = !!timesheet && !!timesheet?.date;
        if (isActive && hasTimesheet) {
            return (
                <NavLink exact to={'/uppdrag/' + assignmentID + '/tidrapport'}>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => triggerEditTimereportEvent(true)}
                        style={{ width: '100%' }}>
                        Visa tidrapport
                    </Button>
                </NavLink>
            );
        } else if (hasTimesheet) {
            return (
                <NavLink exact to={'/uppdrag/' + assignmentID + '/tidrapport'}>
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={() => triggerViewTimereportEvent()}
                        style={{ width: '100%' }}>
                        Visa tidrapport
                    </Button>
                </NavLink>
            );
        } else if (isActive) {
            return (
                <NavLink exact to={'/uppdrag/' + assignmentID + '/tidrapport'}>
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => triggerEditTimereportEvent(false)}
                        style={{ width: '100%' }}
                        disableElevation>
                        Skicka in tidrapport
                    </Button>
                </NavLink>
            );
        } else {
            return undefined;
        }
    };

    const triggerViewTimereportEvent = () => {
        ReactGA.event({
            category: 'AssignmentPage',
            action: 'View timereport',
        });
    };

    const triggerEditTimereportEvent = (hasTimesheet: boolean) => {
        ReactGA.event({
            category: 'AssignmentPage',
            action: 'Edit timereport',
            label: hasTimesheet ? 'Old' : 'New',
        });
    };

    const getAssignmentIDFromSelectedFile = (): number => {
        for (let i = 0; i < filesProps.files.length; i++) {
            const file: any = filesProps.files[i];
            if (file.id === selectedSignFileID) {
                return file?.resourceLink?.id as number;
            }
        }
        return 0;
    };

    const renderMobile = () => {
        return (
            <AssignmentMobile
                informationProps={informationProps}
                timesheetProps={timesheetProps}
                filesProps={filesProps}
                statsProps={statsProps}
                renderTimesheetButton={renderTimesheetButton}
                onSignFile={setSelectedSignFileID}
            />
        );
    };

    const renderDesktop = () => {
        return (
            <AssignmentDesktop
                informationProps={informationProps}
                timesheetProps={timesheetProps}
                filesProps={filesProps}
                statsProps={statsProps}
                renderTimesheetButton={renderTimesheetButton}
                onSignFile={setSelectedSignFileID}
            />
        );
    };

    return (
        <>
            {!isMobile ? renderDesktop() : renderMobile()}
            <BankIDDialog
                open={!!selectedSignFileID}
                onGoBack={() => setSelectedSignFileID(0)}
                onSuccess={() => {
                    setSelectedSignFileID(0);
                    informationProps.fetch();
                    filesProps.fetchFiles();
                }}
                type={TYPE.SIGN}
                endPoint={api.files.signFileWithDigitalID(
                    getAssignmentIDFromSelectedFile(),
                    selectedSignFileID
                )}
                title='Skriv under dokument'
                subtitle='Välj identifieringsmetod för att fortsätta.'
                isMobile={isMobile}
            />
        </>
    );
}
