import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Prompt } from 'react-router-dom';
import ConfirmationMenu from '../ConfirmationMenu/ConfirmationMenu';

class BlockRoute extends Component {
    state = {
        modalVisible: false,
        blockedLocation: null,
        confirmedNavigation: false,
        action: null,
    };

    componentWillUnmount() {
        window.onbeforeunload = undefined;
    }

    componentDidUpdate(prevProps, prevState) {
        const { shouldBlockNavigation, message } = this.props;
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    showModal = (location, action) =>
        this.setState({
            modalVisible: true,
            blockedLocation: location,
            action: action,
        });

    closeModal = callback =>
        this.setState(
            {
                modalVisible: false,
            },
            callback
        );

    handleBlockedNavigation = (nextLocation, action) => {
        const { confirmedNavigation } = this.state;
        const { shouldBlockNavigation } = this.props;

        if (!confirmedNavigation && shouldBlockNavigation) {
            this.showModal(nextLocation, action);
            return false;
        }
        return true;
    };

    handleConfirmNavigationClick = () =>
        this.closeModal(() => {
            const { history } = this.props;
            const { blockedLocation, action } = this.state;
            if (blockedLocation) {
                this.setState(
                    {
                        confirmedNavigation: true,
                    },
                    () => {
                        if (action === 'POP') {
                            if (history.length < 2) {
                                history.push('/');
                            } else {
                                history.goBack();
                            }
                        } else {
                            history.push(
                                blockedLocation.pathname +
                                    blockedLocation.search
                            );
                        }
                    }
                );
            }
        });

    render() {
        const {
            shouldBlockNavigation,
            message,
            title,
            cancelLabel,
            confirmLabel,
            confirmFunc,
            cancelFunc,
            forceDisplay,
            isMobile,
        } = this.props;
        const { modalVisible } = this.state;
        return (
            <>
                <Prompt
                    when={shouldBlockNavigation}
                    message={this.handleBlockedNavigation}
                />
                <ConfirmationMenu
                    open={modalVisible || !!forceDisplay}
                    message={
                        message ||
                        'Dina ändringar har inte sparats, är du säker på att du vill lämna?'
                    }
                    title={title || 'Spara ändringar'}
                    cancelLabel={cancelLabel || 'Lämna utan att spara'}
                    confirmLabel={confirmLabel || 'Stanna'}
                    cancelFunction={() => {
                        if (typeof cancelFunc === 'function') {
                            cancelFunc();
                        }
                        this.handleConfirmNavigationClick();
                    }}
                    confirmFunction={() => {
                        if (typeof confirmFunc === 'function') {
                            confirmFunc();
                        }
                        this.closeModal();
                    }}
                    onClose={() => this.closeModal()}
                    isMobile={isMobile}
                />
            </>
        );
    }
}

BlockRoute.propTypes = {
    when: PropTypes.bool,
    message: PropTypes.string,
    forceDisplay: PropTypes.bool, // if the confrim dialog should show even though the router hasn't triggered it
};
export default withRouter(BlockRoute);
