import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import CurrentWeek from 'components/interface/Date/CurrentWeek';
import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles()(theme => ({
    base: {
        userSelect: 'none',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    title: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    subtitle: {
        'display': 'flex',
        'width': '100%',
        'alignItems': 'center',
        'justifyContent': 'space-between',
        '& > *': {
            fontWeight: '400',
        },
    },
    actions: {
        display: 'flex',
        width: 'autp',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    description: {
        paddingTop: theme.spacing(1),
    },
}));

interface Props {
    children?: JSX.Element | Array<JSX.Element>;
    title?: string;
    subtitle?: string | null;
    loading?: boolean;
    noWeek?: boolean;
    description?: string;
}

export default function PageInfoText({
    children,
    title,
    subtitle,
    description,
    noWeek,
    loading,
}: Props): JSX.Element {
    const { classes } = useStyles();

    const renderSubtitle = () => (
        <div className={classes.subtitle}>
            {loading ? (
                <Skeleton
                    animation='pulse'
                    variant='text'
                    width='96px'
                    height='32px'
                />
            ) : (
                <Typography variant='h6' color='textPrimary'>
                    {subtitle}
                </Typography>
            )}
            <div className={classes.actions}>{children}</div>
        </div>
    );
    return (
        <div className={classes.base}>
            <div className={classes.title}>
                {loading || !title ? (
                    <Skeleton
                        animation='pulse'
                        variant='text'
                        width='256px'
                        height='32px'
                    />
                ) : (
                    <Typography variant='h5' color='textPrimary'>
                        <b>{title}</b>
                    </Typography>
                )}
                {noWeek ? undefined : <CurrentWeek />}
            </div>
            {!!subtitle ? renderSubtitle() : undefined}
            {description ? (
                <Typography
                    className={classes.description}
                    variant='body2'
                    color='textPrimary'>
                    {description}
                </Typography>
            ) : undefined}
        </div>
    );
}
