import { create } from 'store/create';

type Store = {
    show: boolean,
    appInstalled: boolean,
    deferredPrompt: any | null,
    setShow: (
        value: boolean,
    ) => void
    setAppInstalled: (
        value: boolean,
    ) => void
    setDeferredPrompt: (value: any | null) => void
}

const useInstallerStore = create<Store>(set => ({
    show: false,
    appInstalled: false,
    deferredPrompt: null,
    setShow: (value) => set({ show: value }),
    setAppInstalled: (value) => set({ appInstalled: value }),
    setDeferredPrompt: (value) => set({ deferredPrompt: value }),
}));

export default useInstallerStore;
