import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';

export function currencyString(value) {
    var formatter = new Intl.NumberFormat('sv');

    return formatter.format(value || "0");
}

export function currencyStringPerHour(value) {
    var formatter = new Intl.NumberFormat('sv');
    const perHour = Math.round((parseInt(value || 0)/40)*10)/10;
    return formatter.format(perHour || "0");
}


export default function Currency ({value, currencyCode, noSuffix = false, ...props}) {
    const code = !!currencyCode ? currencyCode : 'SEK';
    return (
        <CurrencyFormat {...props} value={value || 0} displayType={'text'} thousandSeparator={' '} decimalSeparator={','} suffix={noSuffix ? '' : ' '+code} />
    );
}

Currency.propTypes = {
    value: PropTypes.number,
    currencyCode: PropTypes.string,
    noSuffix: PropTypes.bool,
};
