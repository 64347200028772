export function statusToString(status) {
    switch (status) {
        case 1:
            return "Förfrågan";
        case 2:
            return "Önskemål";
        case 3:
            return "Bekräftat";
        case 4:
            return "Tidrapporterat";
        case 5:
            return "Attesterat";
        case 6:
            return "Avslutat";
        case 7:
            return "Avbokat";
    }
    return null;
}
