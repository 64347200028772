import axios from './Axios';
import Api from './Api';
import { RESOURCE_TYPE as RESOURCE } from 'typings/file';
import { DIGITAL_ID_METHOD, DigitalIdPayload } from 'components/bankID/typings';

export default class FilesAPI {
    /**
     * Fetches a upload URL for the file for the specified staff.
     * @param {JWT} token - The token of the account.
     * @param {int} id - The staff's id
     * @param {obj} file - The file object
     * @param {int} categoryID - The category id
     * @returns  A Axios promise
     */
    googleStorageUploadFile(url = '', file: any) {
        return axios({
            method: 'PUT',
            timeout: Api.Timeout5Min,
            url: url,
            headers: {
                'Content-Type': file.type,
            },
            data: file,
        });
    }

    /**
     * Fetches the attached files for the specified resource.
     * @param {JWT} token - The token of the account.
     * @param {string} resource - The endpoint for the resource. For example "staff", "assignment"
     * @param {int} id - The resource's id
     * @returns  A Axios promise
     */
    fetchFiles(token: string, resource: RESOURCE, id: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/${resource}/${id}/files`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
    }

    /**
     * Fetches the attached file for the specified resource.
     * @param {JWT} token - The token of the account.
     * @param {string} resource - The endpoint for the resource. For example "staff", "assignment"
     * @param {int} id - The resource's id
     * @param {int} fileID - The file's id
     * @returns  A Axios promise
     */
    fetchFile(token: string, resource: RESOURCE, id: string, fileID: number) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/${resource}/${id}/files/${fileID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
    }

    /**
     * Fetches the download URL for the file for the specified resource.
     * @param {JWT} token - The token of the account.
     * @param {string} resource - The endpoint for the resource. For example "staff", "assignment"
     * @param {int} id - The resource's id
     * @param {int} fileID - The files's id
     * @returns  A Axios promise
     */
    fetchFileDownloadURL(
        token: string,
        resource: RESOURCE,
        id: string,
        fileID: number
    ) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/${resource}/${id}/files/${fileID}/url`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
    }

    /**
     * Fetches a upload URL for the file for the specified resource.
     * @param {JWT} token - The token of the account.
     * @param {string} resource - The endpoint for the resource. For example "staff", "assignment"
     * @param {int} id - The resource's id
     * @param {obj} file - The file object
     * @returns  A Axios promise
     */
    prepareFileForUpload(
        token: string,
        resource: RESOURCE,
        id: string,
        file: any
    ) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/${resource}/${id}/files/prepare`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            data: {
                name: file.name + (file.extension ? '.' + file.extension : ''),
                size: file.size,
                type: file.type,
            },
        });
    }

    /**
     * Records that the file has been uploaded to Google Storage, for the specified resource.
     * @param {JWT} token - The token of the account.
     * @param {string} resource - The endpoint for the resource. For example "staff", "assignment"
     * @param {int} id - The resource's id
     * @param {int} queueID - The queueID of the file
     * @returns  A Axios promise
     */
    uploadFile(token: string, resource: RESOURCE, id: string, queueID: number) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/${resource}/${id}/files/prepare/${queueID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
    }

    /**
     * Fetches the download URL for the file for the specified resource.
     * @param {JWT} token - The token of the account.
     * @param {string} resource - The endpoint for the resource. For example "staff", "assignment"
     * @param {int} id - The resource's id
     * @param {int} fileID - The files's id
     * @returns  A Axios promise
     */
    trash(token: string, resource: RESOURCE, id: string, fileID: number) {
        return axios({
            method: 'DELETE',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/${resource}/${id}/files/${fileID}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        });
    }

    signFileWithDigitalID(assignmentID: number, fileID: number) {
        return (
            method: DIGITAL_ID_METHOD,
            token: string,
            transactionId?: string,
            _payload?: DigitalIdPayload
        ) =>
            axios({
                method: 'POST',
                timeout: Api.Timeout,
                url: `${Api.Host}/assignment/${assignmentID}/files/${fileID}/sign`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                params: {
                    method: method,
                    transactionId: transactionId,
                },
                data: {},
            });
    }
}
