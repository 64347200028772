import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Timesheet,
    TravelsheetKeyType,
    TIMESHEET_SETTING,
    TimesheetDay,
    Summary,
    OtherTimeReportedHoursForWeek,
} from '../typings';
import NotFound from 'components/interface/NotFound/NotFound';
import TravelsheetComponent from './Travelsheet';
import Spinner from 'react-spinkit';
import TimesheetComponent from './Timesheet';

const useStyles = makeStyles()(theme => ({
    base: {
        marginBottom: theme.spacing(4),
    },

    spinner: {
        width: '75px !important',
        height: '75px !important',
        marginTop: theme.spacing(2),
    },
}));

interface TimesheetProps {
    timesheet: Timesheet | null;
    summary: Summary;
    timesheetSetting: TIMESHEET_SETTING;
    canSubmit: boolean;
    allowTravelsheet: boolean;
    loading: boolean;
    success: boolean;
    error: boolean;
    enableSickHours: boolean;
    otherTimeReportedHoursForWeek: OtherTimeReportedHoursForWeek;
    onEnableSickHours: (value: boolean) => void;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
    onTravelsheetFieldChange: (
        key: TravelsheetKeyType,
        index: number
    ) => (value: string) => void;
    onNotesFieldChange: (value: string) => void;
    useSplitTextfields: boolean;
    setUseSplitTextfields?: (value: boolean) => void;

    commentContainsSickHints: boolean;
    sickHoursDuplicateDays: TimesheetDay[];
}

const View = ({
    timesheet,
    summary,
    timesheetSetting,
    canSubmit,
    allowTravelsheet,
    loading,
    success,
    error,
    enableSickHours,
    otherTimeReportedHoursForWeek,
    onEnableSickHours,
    onUpdateTimesheetDay,
    onTravelsheetFieldChange,
    onNotesFieldChange,
    useSplitTextfields,
    setUseSplitTextfields,

    commentContainsSickHints,
    sickHoursDuplicateDays,
}: TimesheetProps): JSX.Element => {
    const { classes } = useStyles();

    const renderPage = () =>
        !timesheet ? (
            <></>
        ) : (
            <div className={classes.base}>
                <TimesheetComponent
                    timesheetSetting={timesheetSetting}
                    timesheet={timesheet}
                    summary={summary}
                    canSubmit={canSubmit}
                    success={success}
                    loading={loading}
                    enableSickHours={enableSickHours}
                    otherTimeReportedHoursForWeek={
                        otherTimeReportedHoursForWeek
                    }
                    onEnableSickHours={onEnableSickHours}
                    onUpdateTimesheetDay={onUpdateTimesheetDay}
                    onNotesFieldChange={onNotesFieldChange}
                    useSplitTextfields={useSplitTextfields}
                    setUseSplitTextfields={setUseSplitTextfields}
                    commentContainsSickHints={commentContainsSickHints}
                    sickHoursDuplicateDays={sickHoursDuplicateDays}
                />
                {allowTravelsheet && timesheet?.travelsheet ? (
                    <TravelsheetComponent
                        travelsheet={timesheet?.travelsheet}
                        canSubmit={canSubmit}
                        onTravelsheetFieldChange={onTravelsheetFieldChange}
                    />
                ) : null}
            </div>
        );

    return error ? (
        <NotFound variant='error' isMobile={false} />
    ) : loading || !timesheet ? (
        <Spinner name='circle' fadeIn='none' className={classes.spinner} />
    ) : (
        renderPage()
    );
};

export default View;
