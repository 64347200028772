import React from 'react';
import MuiTooltip, {
    TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

export interface TooltipProps extends MuiTooltipProps {}

// A basic wrapper for the MuiTooltip component that should be used instead of the mui one.
// It applies some default props and wraps the title in a Typography with the correct variant if it is a string.
export default function Tooltip({
    title,
    enterTouchDelay = 500,
    placement = 'top',
    disableInteractive = true,
    children,
    ...rest
}: TooltipProps) {
    return title ? (
        <MuiTooltip
            title={
                typeof title === 'string' ? (
                    <Typography color='inherit' variant='caption'>
                        <b>{title}</b>
                    </Typography>
                ) : (
                    title
                )
            }
            enterTouchDelay={enterTouchDelay}
            placement={placement}
            disableInteractive={disableInteractive}
            {...rest}>
            {children}
        </MuiTooltip>
    ) : (
        children
    );
}
