import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Timesheet,
    TimesheetDay,
    Summary,
    TIMESHEET_SETTING,
    OtherTimeReportedHoursForWeek,
} from '../typings';
import Typography from '@mui/material/Typography';
import NormalTimesheet from './NormalTimesheet';
import TextField from 'components/interface/textField/TextField';
import TriStateSwitch from 'components/interface/triStateSwitch/TriStateSwitch';
import StartStopTimesheet from './StartStopTimesheet';
import Warnings from './Warnings';
import { AppType } from 'api/Api';
import { APP_TYPE } from 'utils/env';
import { ProfessionIdentifier } from 'typings/titles';
import Alert, { STATUS } from 'components/interface/alert/Alert';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

const useStyles = makeStyles()(theme => ({
    base: {
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(1),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: theme.spacing(2),
    },
    comment: {
        marginBottom: theme.spacing(4),
    },
}));

export interface TimesheetProps {
    timesheetSetting: TIMESHEET_SETTING;
    timesheet: Timesheet;
    summary: Summary;
    canSubmit: boolean;
    success: boolean;
    loading: boolean;
    enableSickHours: boolean;
    otherTimeReportedHoursForWeek: OtherTimeReportedHoursForWeek;
    onEnableSickHours: (value: boolean) => void;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
    onNotesFieldChange: (value: string) => void;
    useSplitTextfields: boolean;
    setUseSplitTextfields?: (value: boolean) => void;

    commentContainsSickHints: boolean;
    sickHoursDuplicateDays: TimesheetDay[];
}

export default function TimesheetComponent({
    timesheetSetting,
    timesheet,
    canSubmit,
    success,
    loading,
    enableSickHours,
    otherTimeReportedHoursForWeek,
    onEnableSickHours,
    onNotesFieldChange,
    useSplitTextfields,
    setUseSplitTextfields,
    commentContainsSickHints,
    sickHoursDuplicateDays,
    ...rest
}: TimesheetProps): JSX.Element {
    const { classes } = useStyles();
    const isSSK =
        timesheet?.template?.profession?.identifier ===
        ProfessionIdentifier.Nurse;
    const allowSickHours = isSSK && canSubmit;

    const renderComment = () => {
        return (
            <div className={classes.comment}>
                <TextField
                    id='notesTimesheet'
                    label='Kommentar'
                    multiline
                    value={timesheet?.notes || ''}
                    onChange={onNotesFieldChange}
                    variant='outlined'
                    disabled={!canSubmit}
                    fullWidth
                />
            </div>
        );
    };

    return (
        <div className={classes.base}>
            <div className={classes.title}>
                <Typography variant='h6' color='textPrimary'>
                    <b> Tidrapport</b>
                </Typography>
                <div className={classes.actions}>
                    {/* @ts-ignore */}
                    {AppType === APP_TYPE.TOOLS &&
                        timesheetSetting === TIMESHEET_SETTING.NORMAL &&
                        setUseSplitTextfields &&
                        canSubmit && (
                            <TriStateSwitch
                                label='Ange minuter separat'
                                value={useSplitTextfields || false}
                                onChange={v => setUseSplitTextfields(!!v)}
                                disabled={!canSubmit}
                                disableNull
                                asText
                            />
                        )}
                    {allowSickHours && (
                        <TriStateSwitch
                            label='Rapporera sjuktimmar'
                            value={enableSickHours || false}
                            onChange={v => onEnableSickHours(!!v)}
                            disabled={!canSubmit}
                            disableNull
                            asText
                        />
                    )}
                </div>
            </div>
                {/* @ts-ignore */}
            {AppType === APP_TYPE.TOOLS ? (
                <Warnings
                    timesheet={timesheet}
                    timesheetSetting={timesheetSetting}
                    show40hWarning={true}
                    showRestWarning={
                        timesheetSetting === TIMESHEET_SETTING.START_STOP_TIME
                    }
                    otherTimeReportedHoursForWeek={
                        otherTimeReportedHoursForWeek
                    }
                />
            ) : null}
            {sickHoursDuplicateDays.length > 0 && (
                <Alert
                    status={STATUS.WARNING}
                    text={
                        <>
                            Det verkar som att du har rapporterat sjuktimmar som
                            är lika med dina normala timmar. Om du har varit
                            sjuk behöver du inte rapportera sjuktimmarna under
                            arbetade timmar. Påverkade dagar:
                            {sickHoursDuplicateDays.map((day, i) => (
                                <i key={i}>
                                    <br />
                                    <b>
                                        {format(new Date(day.date), 'EEEE', {
                                            locale: sv,
                                        })}
                                    </b>
                                </i>
                            ))}
                        </>
                    }
                    style={{ marginBottom: 16 }}
                    fullWidth
                />
            )}
            {timesheetSetting === TIMESHEET_SETTING.START_STOP_TIME ? (
                <StartStopTimesheet
                    {...rest}
                    timesheet={timesheet}
                    canSubmit={canSubmit}
                    loading={loading}
                    enableSickHours={enableSickHours}
                />
            ) : (
                <NormalTimesheet
                    {...rest}
                    timesheet={timesheet}
                    canSubmit={canSubmit}
                    loading={loading}
                    enableSickHours={enableSickHours}
                    useSplitTextfields={useSplitTextfields}
                />
            )}
            {commentContainsSickHints && allowSickHours && !enableSickHours && (
                <Alert
                    status={STATUS.WARNING}
                    text='Det verkar som att du har skrivit något om sjukdom i din kommentar. Om du har varit sjuk under veckan, glöm inte att rapportera sjuktimmar. Det gör du genom att klicka på "Ja" vid "Rapportera sjuktimmar" ovanför tabellen.'
                    style={{ marginBottom: 16 }}
                    fullWidth
                />
            )}
            {timesheet.template.hasComment ? renderComment() : undefined}
        </div>
    );
}
