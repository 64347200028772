import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    base: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
}));

interface Props {
    children: React.ReactNode;
    classes?: string;
    className?: string;
}

const MenuList = React.forwardRef<HTMLDivElement, Props>(
    (
        { children, classes: classesProps, className: classNameProps }: Props,
        ref
    ) => {
        const { classes, cx } = useStyles();

        return (
            <div
                ref={ref}
                className={cx(classes.base, classesProps, classNameProps)}>
                {children}
            </div>
        );
    }
);

export default MenuList;
