/**
 * Validates an alphanumeric string
 * @param {String} string, the string to be validated as an alphanumeric string
 * @returns An boolean representing if it's an alphanumeric string or not.
 */
export function isAlphanumeric(string) {
    var pattern = new RegExp(/^[a-z0-9]+$/i);
    return pattern.test(string);
}

/**
 * Validates an email
 * @param {String} emailAddress, the string to be validated as an email
 * @returns An boolean representing if it's an email or not.
 */
export function isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
    );
    return pattern.test(emailAddress);
}

/**
 * Checks if n is numeric
 * @returns An boolean representing if it's numeric or not
 */
export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Extracts only the numerics from a string
 * @param {String} value, the value to be extracted from
 * @return A numeric string
 */
export function extractNumeric(value) {
    return value.replace(/\D/g, "");
}

/**
 * Extracts the hours and minutes from a string representing a float
 * @param {String} h, the value to be extracted from
 * @return An object with hours and minutes as integers, {hours: int, minutes: int}
 */
export function extractHoursAndMinutes(h) {
    const hour = h ? Math.floor(parseFloat(h)) : 0;
    const minutes = h && h % 1 ? Math.round((parseFloat(h) * 60) % 60) : 0;
    return { hours: hour, minutes: minutes };
}

/**
 * Converts minutes to hours in decimals
 * @param {String} value, the value to be converted
 * @return A float.
 */
export function minutesToHoursDecimals(minutes) {
    return minutes ? parseInt(minutes) / 60 : 0;
}

/**
 * Extract the minutes from hours in decimal form, rounded to 2 decimal places.
 * @param {String} value, the value to be extracted from
 * @return A float representing the minutes in hour format.
 */
export function extractMinutesFromHoursDecimals(hours) {
    return hours ? parseFloat((hours % 1).toFixed(2)) : 0;
}

/**
 * Formats an SSN
 */
export function formatSsn(ssn) {
    var processedText = extractNumeric(ssn);
    if (
        processedText.length > 5 &&
        processedText.length < 12 &&
        processedText[0] !== "1" &&
        processedText[0] !== "2"
    ) {
        if (processedText[0] === 0) {
            processedText = "20" + processedText;
        } else {
            processedText = "19" + processedText;
        }
    }

    // Insert a - if needed, and allow for manual placement of '-' if it is at the correct location
    if (processedText.length > 8) {
        processedText =
            processedText.substr(0, 8) + "-" + processedText.substr(8, 4);
    } else if (processedText.length === 8 && ssn[ssn.length - 1] === "-") {
        processedText += "-";
    }
    return processedText;
}

/**
 * Converts an int to the corresponding status as a string
 * @param {Int} statusInt
 */

export function convertStatusToString(statusInt) {
    if (statusInt == 1) return "Förfrågan";
    else if (statusInt == 2) return "Önskemål";
    else if (statusInt == 3) return "Bekräftat";
    else if (statusInt == 4) return "Tidrapporterat";
    else if (statusInt == 5) return "Attesterat";
    else if (statusInt == 6) return "Avslutat";
    else if (statusInt == 7) return "Avbokat";
    else return "-";
}

/**
 * Formats bytes to a string with the correct suffix.
 * @param {int} bytes
 * @param {int} decimals
 */
export function formatBytes(bytes, decimals) {
    if (bytes == 0) return "0 Byte";
    var k = 1024; // or 1024 for binary
    var dm = decimals + 1 || 3;
    var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

/**
 * source: https://stackoverflow.com/a/16591175
 * @param {int} w the week of the year
 * @param {int} y the year
 */
export function getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}

export function getDateDDMM(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    return dd + "/" + mm;
}

/**
 * Converts hours in float to a string of format HH:MM (h)
 * @param {float} h, hours as float
 * @param {bool} compact, if the format should be compact, results in the format HH:MM
 * @returns string of format HH:MM (h)
 */
export function formatHoursToHHMM(h, compact) {
    const hours = Math.floor(h);
    const minutes = (h * 60) % 60;
    return (
        hours.toFixed(0) +
        "h " +
        minutes.toFixed(0) +
        "m" +
        (!compact ? " (" + h.toFixed(2) + ")" : "")
    );
}

/**
 * Replaces all occurrences in a string
 * @param string target, the string that gets searched
 * @param string search, the string that should be replaced
 * @param string replacement, the replacement string
 * @returns returns a string where all occurrences 'search' has been replaced with 'replacement'.
 */
export function replaceAll(target, search, replacement) {
    return target.split(search).join(replacement);
}

/**
 * Generates a string of todays date in the format DD/MM/YYYY
 * @returns A string of todays date in the format DD/MM/YYYY
 */
export function getDateDDMMYYYY() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return dd + "/" + mm + "/" + yyyy;
}
/**
 * Checks if two arrays are equal
 * @param {array} a
 * @param {array} b
 * @returns true if they are equal otherwise false.
 */
export function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

/**
 * Checks if two objects are equal
 * @param {Object} a
 * @param {Object} b
 * @param {array} keys, specified array of keys that should be compared
 * @param {bool} isBlacklist, if the provided keys should not be compared.
 * @returns true if they are equal otherwise false.
 */
export function objectsEqual(a, b, keys, isBlacklist) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    // Removes the blacklisted keys from both objects
    if (keys && isBlacklist) {
        a = objectWithoutProperties(a, keys);
        b = objectWithoutProperties(b, keys);
        keys = null;
    }
    // If no keys is specified, check if both objects contains the same keys and then set the keys array to those
    if (!keys) {
        let keysA = Object.keys(a);
        let keysB = Object.keys(b);
        if (arraysEqual(keysA, keysB)) {
            keys = keysA;
        } else {
            return false;
        }
    }
    // compare each key-value
    for (let i = 0; i < keys.length; i++) {
        let k = keys[i];
        let type = typeof a[k] === typeof b[k] ? typeof a[k] : null;
        if (type === null) {
            return false;
        }

        switch (type) {
            case "object":
                if (!objectsEqual(a[k], b[k])) {
                    return false;
                }
                break;
            default:
                if (a[k] !== b[k]) {
                    return false;
                }
        }
    }
    return true;
}

/**
 * Removes the keys from the object
 * @param {Object} obj
 * @param {array} keys
 * @returns Return a new object without the specified keys.
 */
export function objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

/**
 * Gets the major version number from a Semantic versioning string
 * @param {string} s
 * @returns int representing the major version number
 */
export function getMajorVersionNumber(s) {
    if (s == null || s.length === 0 || typeof s !== "string") {
        return null;
    }
    const major = s.split(".")[0];
    return isNaN(major) ? null : parseInt(major);
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function formatZip(zip) {
    if (zip.length < 4) {
        return zip;
    }
    const head = zip.substr(0, 3);
    const tail = zip.substr(3);
    return head + " " + tail;
}

export function isInStandaloneMode() {
    return (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone
    );
}

export function extractFileName(name) {
    const splitted = name.split(".");
    return splitted.slice(0, splitted.length - 1 || 1).join(".");
}

export function extractFileExtension(name) {
    const splitted = name.split(".");
    return splitted.length > 1 ? splitted.pop() : "";
}

// Arguments :
//  method : 'GET'|'POST'
//  target : an optional opening target (a name, or "_blank"), defaults to "_self"
//  EX1: openURL('POST', 'fileServer.jsp', {request: {key:"42", cols:[2, 3, 34]}});
//  EX2: openURL('POST', someURL, someArgs, '_blank');
export function openURL(method, url, data, target) {
    var form = document.createElement("form");
    //strip data from url
    form.action = url;
    form.method = method;
    form.target = target || "_self";
    if (data) {
        for (var key in data) {
            var input = document.createElement("textarea");
            input.name = key;
            input.value =
                typeof data[key] === "object"
                    ? JSON.stringify(data[key])
                    : data[key];
            form.appendChild(input);
        }
    }
    form.style.display = "none";
    document.body.appendChild(form);
    form.submit();
}

export function removeNullKeys(obj) {
    let copy = { ...obj };
    for (var prop in copy) {
        if (copy[prop] === null || copy[prop] === undefined) {
            delete copy[prop];
        }
    }
    return copy;
}

// Rounds to one decimal
export function RoundMoneyValue(value) {
    return Math.round((value || 0) * 10) / 10;
}

/**
 * Validates an password
 * @param {String} password, the string to be validated as a password
 * @returns An boolean representing if it's an password or not.
 */
export function isValidPassword(password) {
    var pattern = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{8,60}$/gm
    );
    return pattern.test(password);
}

/**
 * Validates an password to check if contains lowercase character
 * @param {String} password, the string to be validated as a password
 * @returns An boolean representing if it's an password or not.
 */
export function hasLowercase(password) {
    var pattern = new RegExp(/[a-z]+/g);
    return pattern.test(password);
}

/**
 * Validates an password to check if contains uppercase character
 * @param {String} password, the string to be validated as a password
 * @returns An boolean representing if it's an password or not.
 */
export function hasUppercase(password) {
    var pattern = new RegExp(/[A-Z]+/g);
    return pattern.test(password);
}

/**
 * Validates an password to check if contains digit character
 * @param {String} password, the string to be validated as a password
 * @returns An boolean representing if it's an password or not.
 */
export function hasDigit(password) {
    var pattern = new RegExp(/[\d]/g);
    return pattern.test(password);
}

/**
 * Validates an password to check if it only contains legal symbol character
 * @param {String} password, the string to be validated as a password
 * @returns An boolean representing if it's an password or not.
 */
export function hasOnlyLegalCharacters(password) {
    var pattern = new RegExp(/^[A-Za-z\d!@#$%^&*]+$/g);
    return pattern.test(password);
}

export const PASSWORD_MIN_LENGTH = 8;
