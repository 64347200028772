import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Popper from 'components/interface/Popper/Popper';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import { useRouteMatch } from 'react-router-dom';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { User } from 'typings/user';
import { NavigationChild } from './Navigation';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    base: {
        display: 'flex',
        alignItems: 'center',
        height: '60px',
    },
    user: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '38px',
        border: '1px solid ' + theme.palette.primary[1000],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1),
        background: theme.palette.primary[50],
        userSelect: 'none',
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary[50],
        borderRadius: '50px',
        marginRight: theme.spacing(1),
        userSelect: 'none',
    },
    avatarIcon: {
        color: theme.palette.primary[1000],
    },
    userInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexGrow: '1',
        userSelect: 'none',
    },
    userInfo: {
        color: theme.palette.primary[1000],
        fontFamily: "'Ubuntu', sans-serif",
        lineHeight: '1',
    },
    children: {
        padding: theme.spacing(1),
    },
    expandIcon: {
        color: theme.palette.primary[1000],
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    expandIconHover: {
        transform: 'rotate(180deg)',
    },
}));

interface UserButtonProps {
    me: User;
    puppeteer: User | null;
}

export default function UserButton({ me, puppeteer }: UserButtonProps) {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorEl = React.useRef(null);

    const handleEnter = () => {
        setOpen(true);
    };

    const handleLeave = () => {
        setOpen(false);
    };

    return (
        <div
            className={classes.base}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
            ref={anchorEl}>
            <UserAvatar me={me} puppeteer={puppeteer} open={open} />
            <Popper
                open={open}
                anchorEl={anchorEl.current}
                transition
                disablePortal
                placement='bottom'>
                <div className={classes.children}>
                    <NavigationChild
                        active={
                            !!useRouteMatch({
                                path: '/min-profil',
                                strict: false,
                            })
                        }
                        link='/min-profil/anvandare'
                        text='Min profil'
                    />
                    <NavigationChild link='/logga-ut' text='Logga ut' />
                </div>
            </Popper>
        </div>
    );
}

interface UserAvatarProps extends UserButtonProps {
    open: boolean;
}

const UserAvatar = ({ me, puppeteer, open }: UserAvatarProps) => {
    const { classes, cx } = useStyles();

    return (
        <div className={classes.user}>
            <div className={classes.avatar}>
                <PersonIcon fontSize='small' className={classes.avatarIcon} />
            </div>
            <span className={classes.userInfoWrapper}>
                <Typography
                    className={classes.userInfo}
                    variant='subtitle2'
                    color='inherit'>
                    <b>
                        {me?.name?.split(' ')[0] +
                            ' ' +
                            me?.surname?.split(' ')[0]}
                    </b>
                </Typography>
                {puppeteer ? (
                    <Typography
                        className={classes.userInfo}
                        variant='caption'
                        color='inherit'>
                        Inloggad av:{' '}
                        <b>{puppeteer.name + ' ' + puppeteer.surname}</b>
                    </Typography>
                ) : null}
            </span>
            <ExpandMore
                className={cx(classes.expandIcon, {
                    [classes.expandIconHover]: open,
                })}
                fontSize='small'
            />
        </div>
    );
};
