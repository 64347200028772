import React from 'react';
import DocumentTitle from 'react-document-title';
import App from 'pages/App';
import useLogout from './useLogout';

export default function LogoutPage() {
    const { logout } = useLogout();

    React.useEffect(() => {
        logout();
    }, []);
    return (
        <App>
            <DocumentTitle title='Logga ut - Vårdbemanning Sverige'></DocumentTitle>
        </App>
    );
}
