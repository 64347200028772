import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Logout } from 'store/logout';

export default function ErrorBoundaryPage({ error }: FallbackProps) {
    console.log('ErrorBoundaryPage', error);

    const handleReload = async () => {
        await Logout();

        if ('serviceWorker' in navigator) {
            const r = await navigator.serviceWorker.getRegistration();
            if (r) {
                await r.unregister();
            }
        }

        setTimeout(() => {
            window.location.reload();
        }, 250);
    };

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '16px',
            }}>
            <div
                style={{
                    width: '500px',
                    maxWidth: '100%',
                    wordBreak: 'break-word',
                }}>
                <h1>Tjenanixen - något gick fel.</h1>
                <p>
                    Börja med att klicka på denna knapp för att ladda om sidan.{' '}
                    <br />
                </p>
                <button
                    onClick={handleReload}
                    style={{
                        padding: '8px 16px',
                        borderRadius: '4px',
                        border: 'none',
                        backgroundColor: '#16aabd',
                        color: 'white',
                        cursor: 'pointer',
                        width: '100%',
                        height: '50px',
                        fontWeight: 'bold',
                        fontSize: '20px',
                    }}>
                    Ladda om sidan
                </button>
                <p>
                    Men om det inte hjälper, stäng ned alla flikar (alternativt
                    webbläsaren) och öppna sidan igen. Om felet kvarstår,
                    kontakta supporten med screenshot på meddelandet nedan.
                </p>
                <p>
                    <b>Felmeddelande: </b>
                    {error?.message}
                </p>
                <p>
                    <b>Stacktrace: </b>
                    {error?.stack}
                </p>
            </div>
        </div>
    );
}
