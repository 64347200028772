import React from 'react';
import PropTypes from 'prop-types';
import SuccessIcon from '@mui/icons-material/TaskAltOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { green, amber } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { makeStyles } from 'tss-react/mui';

const variantIcon = {
    success: SuccessIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles()((theme, params, classes) => ({
    snackbar: {
        bottom: 'calc(' + theme.spacing(1) + ' + env(safe-area-inset-bottom))',
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function CustomizedSnackbar({
    className,
    message,
    variant,
    isOpen,
    onClose,
    isMobile,
    autoHideDuration,
    transitionDuration,
    type,
    ...rest
}) {
    const { classes, cx } = useStyles();
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        setOpen(isOpen);
    }, []);

    const handleClose = (e, type) => {
        if (type === 'clickaway') {
            return;
        }
        setOpen(false);
        if (onClose) {
            onClose(type);
        }
    };

    const Icon = variantIcon[variant];
    const position = isMobile
        ? { vertical: 'bottom', horizontal: 'center' }
        : { vertical: 'bottom', horizontal: 'right' };

    return (
        <Snackbar
            classes={{ anchorOriginBottomCenter: classes.snackbar }}
            open={open}
            autoHideDuration={autoHideDuration ? autoHideDuration : 4000}
            onClose={handleClose}
            anchorOrigin={position}
            transitionDuration={transitionDuration}>
            <SnackbarContent
                className={cx(classes[variant], className)}
                aria-describedby='client-snackbar'
                message={
                    <span id='client-snackbar' className={classes.message}>
                        <Icon
                            className={cx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key='close'
                        aria-label='Close'
                        color='inherit'
                        className={classes.close}
                        onClick={handleClose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...rest}
            />
        </Snackbar>
    );
}

CustomizedSnackbar.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    onClose: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.instanceOf(undefined),
    ]),
    isOpen: PropTypes.bool,
    message: PropTypes.node.isRequired,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
        .isRequired,
};
