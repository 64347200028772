import axios from "./Axios";
import Api from "./Api";
import {
    DIGITAL_ID_METHOD,
    DigitalIdPayload,
} from 'components/bankID/typings';

export default class UserApi {
    /**
     * Log in to a User.
     * @param {string} email - The email of the user.
     * @param {string} password - The password of the user.
     * @returns  A Axios promise
     */
    loginToUser(email = "", password = "") {
        return axios({
            method: "POST",
            timeout: Api.Timeout,
            url: `${Api.Host}/session/`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                username: email,
                password: password,
            },
        });
    }

    loginDigitalID(
        method: DIGITAL_ID_METHOD,
        _token: string,
        transactionId?: string,
        payload?: DigitalIdPayload
    ) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/session/digital_id`,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                method: method,
                transactionId: transactionId,
            },
            data: {
                ...payload,
            },
        });
    }

    enableDigitalID(
        method: DIGITAL_ID_METHOD,
        token: string,
        transactionId?: string,
        _payload?: DigitalIdPayload
    ) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/user/digital_id/enable`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                method: method,
                transactionId: transactionId,
            },
            data: {},
        });
    }

    disableDigitalID(
        method: DIGITAL_ID_METHOD,
        token: string,
        transactionId?: string,
        _payload?: DigitalIdPayload
    ) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/user/digital_id/disable`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                method: method,
                transactionId: transactionId,
            },
            data: {},
        });
    }

    /**
     * Gets the user session data. The same data returned when logging in.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
     getSessionData(token: string) {
        return axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/session`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            data: {},
        });
    }

    /**
     * extends the user's access token and session
     * @param {JWT} token - The token of the account.
     * @param {string} password - the password for authentication
     * @returns  A Axios promise
     */
     extendSession(token: string, password = "") {
        return axios({
            method: "POST",
            timeout: Api.Timeout,
            url: `${Api.Host}/session/refresh`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                password: password,
            },
        });
    }

    /**
     * Log out from a User.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
     logout(token: string) {
        return axios({
            method: "DELETE",
            timeout: Api.Timeout,
            url: `${Api.Host}/session`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: {},
        });
    }

    /**
     * Fetches user settings.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    fetchSettings(token: string) {
        return axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/me/settings`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            params: {},
        });
    }

    /**
     * Requests a password recovery email for the user.
     * @param {string} email - The email of the user.
     * @returns  A Axios promise
     */
    requestPasswordRecovery(email = "") {
        return axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/reset_password`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                email: email,
            },
        });
    }

    /**
     * Changes the users password using the recovery token sent in mail.
     * @param {string} password - The new password.
     * @param {string} token - The token authenticating the password change.
     * @returns  A Axios promise
     */
    changePasswordWithRecoveryToken(password = "", token = "") {
        return axios({
            method: "POST",
            timeout: Api.Timeout,
            url: `${Api.Host}/reset_password`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                password: password,
                token: token,
            },
        });
    }
}
