export enum DigitalIDMethod {
    BANKID = 'BANKID',
}

export function DigitalIDMethodToLabel(method: DigitalIDMethod): string {
    switch (method) {
        case DigitalIDMethod.BANKID:
            return 'BankID';
    }
}

export enum RESOURCE_TYPE {
    ASSIGNMENT = 'assignment',
}

export interface File {
    id: number;
    date: string;
    name: string;
    size: number;
    type: string;
    uploader: Uploader;
    isGenerated: boolean;
    requiresSignature: boolean;
    signatureData: SignatureData;
}

export interface SignatureData {
    signatures: Array<Signature>;
    isSignedByStaff: boolean;
    isSignedByUs: boolean;
}

export interface Signature {
    digitalIDMethod: DigitalIDMethod;
    date: string;
    signedBy: Uploader;
}

export function GetStaffSignature(sd: SignatureData): Signature | null {
    const signatures = sd?.signatures;
    if(!signatures) {
        return null
    }
    for (let i = 0; i < signatures.length; i++) {
        const signature = signatures[i];
        if (signature.signedBy?.isStaff) {
            return signature;
        }
    }
    return null;
}

export interface Uploader {
    name: string;
    surname: string;
    isStaff: boolean;
}
