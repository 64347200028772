import React, { lazy, Suspense } from 'react';
import App from 'pages/App';
import Page from 'components/Page';
import Header from 'components/Header/Header';
import Content from 'components/Content/Content';
import Footer from 'components/Footer/Footer';
import { LoadingGrid } from 'components/interface/Loading';
import useDocumentTitle from 'components/hooks/useDocumentTitle';

const AssignmentsList = lazy(() =>
    import('components/Content/assignment/list/View')
);

export default function AssignmentPage({}) {
    useDocumentTitle('Aktuella uppdrag');
    return (
        <App>
            <Page>
                <Header />
                <Content>
                    <Suspense fallback={<LoadingGrid />}>
                        <AssignmentsList onlyCurrent={true} />
                    </Suspense>
                </Content>
                <Footer />
            </Page>
        </App>
    );
}
