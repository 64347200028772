import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import './index.css';
import { getRoutes } from './Routes';
import swConfig from './swConfig';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Router } from 'react-router-dom';
import { history } from 'router';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryPage from 'pages/ErrorPages/ErrorBoundryPage';

if (process.env.REACT_APP_IS_DEV === 'false') {
    ReactGA.initialize('UA-70927272-4'); // Unique Google Analytics tracking number
} else if (process.env.REACT_APP_IS_LOCAL === 'false') {
    ReactGA.initialize('UA-70927272-3', {
        testMode: true,
    });
}

if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
) {
    ReactGA.event({
        category: 'Preferred color scheme',
        action: 'Dark mode',
    });
} else if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: light)').matches
) {
    ReactGA.event({
        category: 'Preferred color scheme',
        action: 'Light mode',
    });
} else if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: no-preference)').matches
) {
    ReactGA.event({
        category: 'Preferred color scheme',
        action: 'No preference',
    });
} else {
    ReactGA.event({
        category: 'Preferred color scheme',
        action: 'Not available',
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <ErrorBoundary fallbackRender={ErrorBoundaryPage}>
        <Router history={history}>{getRoutes()}</Router>
    </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(swConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
