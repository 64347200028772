import React from 'react';
import TimesheetDesktop from './desktop/View';
import TimesheetMobile from './mobile/View';
import useWindowWidth from 'components/hooks/useWindowWidth';
import useTimesheet from './hooks/useTimesheet';
import useMeStore from 'store/useMeStore';
import { SETTINGS } from 'typings/user';
import TimesheetComponent from 'components/interface/timesheet/View';
import { TIMESHEET_SETTING } from 'components/interface/timesheet/typings';
import { useAssignmentInformation } from '../page/hooks/useAssignment';

interface Props {
    assignmentID: string;
}

export default function View({ assignmentID }: Props): JSX.Element {
    const me = useMeStore(state => state.me);
    const token = useMeStore(state => state.token);
    const { isMobile } = useWindowWidth();

    const timesheetProps = useTimesheet(assignmentID);

    const useSplitTextfields = me?.userSettings
        ? me?.userSettings[SETTINGS.USE_SPLIT_TEXTFIELDS_TIMEREPORT]?.value
        : false;

    const canSubmit =
        timesheetProps?.assignmentInfo?.status?.id === 3 ||
        timesheetProps?.assignmentInfo?.status?.id === 4;

    const setting = timesheetProps.timesheetSetting || TIMESHEET_SETTING.NORMAL;

    const { fetch } = useAssignmentInformation({
        id: assignmentID,
        token,
    });

    // This will refetch the assignment after successfully submitting the timesheet. This is so it updates the row data with the new status
    React.useEffect(() => {
        if (timesheetProps.success) {
            fetch();
        }
    }, [timesheetProps.success]);

    const renderTimesheet = () => (
        <TimesheetComponent
            timesheet={timesheetProps?.timesheet || null}
            setTimesheet={timesheetProps.setTimesheet}
            timesheetSetting={setting}
            canSubmit={canSubmit}
            useSplitTextfields={useSplitTextfields}
            allowTravelsheet={
                !!timesheetProps?.assignmentInfo?.allowTravelsheet
            }
            loading={timesheetProps?.loading}
            submitting={timesheetProps?.submitting}
            success={timesheetProps?.success}
            error={timesheetProps?.error}
            isMobile={isMobile}
            enableSickHours={timesheetProps?.enableSickHours}
            otherTimeReportedHoursForWeek={
                timesheetProps?.otherTimeReportedHoursForWeek
            }
            onEnableSickHours={timesheetProps?.setEnableSickHours}
        />
    );

    return (
        <>
            {isMobile ? (
                <TimesheetMobile
                    assignmentID={assignmentID}
                    assignment={timesheetProps?.assignmentInfo}
                    year={timesheetProps?.assignmentInfo?.year || null}
                    week={timesheetProps?.assignmentInfo?.week || null}
                    loading={timesheetProps?.loading}
                    submitting={timesheetProps?.submitting}
                    success={timesheetProps?.success}
                    canSubmit={canSubmit}
                    onSubmit={timesheetProps.submit}>
                    {renderTimesheet()}
                </TimesheetMobile>
            ) : (
                <TimesheetDesktop
                    assignmentID={assignmentID}
                    assignment={timesheetProps?.assignmentInfo}
                    year={timesheetProps?.assignmentInfo?.year || null}
                    week={timesheetProps?.assignmentInfo?.week || null}
                    loading={timesheetProps?.loading}
                    submitting={timesheetProps?.submitting}
                    success={timesheetProps?.success}
                    canSubmit={canSubmit}
                    onSubmit={timesheetProps.submit}>
                    {renderTimesheet()}
                </TimesheetDesktop>
            )}
        </>
    );
}
