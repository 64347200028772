import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MuiBottomNavigation from '@mui/material/BottomNavigation';

const useStyles = makeStyles()(theme => ({
    bottomNav: {
        width: '100%',
        position: 'fixed',
        bottom: '0',
        left: '0',
        background: theme.palette.background.backgroundColor,
        flexShrink: 0,
        paddingBottom: 'env(safe-area-inset-bottom)',
        boxSizing: 'content-box',
    },
    bottomNavText: {
        fontSize: theme.typography.caption.fontSize + '!important',
    },
}));

interface Props {
    children: JSX.Element | Array<JSX.Element>;
    className?: string;
    [x: string]: any;
}

const BottomNavigation = ({
    children,
    className: classNameProp,
    ...other
}: Props) => {
    const { classes, cx } = useStyles();
    const childrenArray = React.Children.toArray(children);

    return (
        <MuiBottomNavigation
            className={cx(classes.bottomNav, classNameProp)}
            showLabels
            {...other}>
            {childrenArray.map((nav: any) => {
                return React.cloneElement(nav, {
                    classes: { label: classes.bottomNavText },
                });
            })}
        </MuiBottomNavigation>
    );
};

export default BottomNavigation;
