import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { TimesheetDay, TimesheetDayInterval } from '../typings';
import Typography from '@mui/material/Typography';
import TextField, { Label } from 'components/interface/textField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { red } from '@mui/material/colors';
import { Tooltip, lighten } from '@mui/material';
import SickOutlined from '@mui/icons-material/SickOutlined';
import NumberField from 'components/interface/textField/formats/NumberField';

const useStyles = makeStyles()(theme => ({
    base: {
        width: '100%',
    },
    row: {
        'width': '100%',
        '& + &': {
            marginTop: theme.spacing(2),
        },
    },
    sickHoursLabel: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    isSickHours: {
        background: lighten(red[50], 0.4),
    },
    sickHoursIcon: {
        color: red[900],
    },
}));

interface Props {
    day: TimesheetDay;
    dayIndex: number;
    totalHours: string;
    canSubmit: boolean;
    loading: boolean;
    enableSickHours: boolean;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
}

export default function StartStopTimesheet({
    day,
    dayIndex,
    totalHours,
    canSubmit,
    enableSickHours,
    onUpdateTimesheetDay,
}: Props): JSX.Element {
    const { classes, cx } = useStyles();

    const handleUpdateTimesheetDay = (
        value: Partial<TimesheetDayInterval>,
        columnIndex: number,
        isSickHours: boolean
    ) => {
        onUpdateTimesheetDay(prev => {
            const d = { ...prev };
            d.intervals = [...d.intervals];
            d.intervals[columnIndex] = {
                ...d.intervals[columnIndex],
                ...value,
                isSickHours,
            };

            return d;
        }, dayIndex);
    };

    const renderInterval = (
        value: TimesheetDayInterval,
        intervalIndex: number
    ) => (
        <>
            {value.isSickHours ? (
                <div className={classes.sickHoursLabel}>
                    <Typography variant='body1' color='textPrimary'>
                        <b>Sjuktimmar</b>
                    </Typography>
                </div>
            ) : null}
            <div className={classes.row}>
                <TextField
                    label='Start'
                    value={value.start || ''}
                    onChange={v =>
                        handleUpdateTimesheetDay(
                            { start: v },
                            intervalIndex,
                            value.isSickHours
                        )
                    }
                    type='time'
                    error={!value.start && !!value.stop}
                    disabled={!canSubmit}
                    fullWidth
                    classes={{field: cx({
                        [classes.isSickHours]: value.isSickHours,
                    })}}
                    white={!value.isSickHours}
                    InputProps={{
                        startAdornment: value.isSickHours ? (
                            <InputAdornment position='start'>
                                <Tooltip
                                    title='Sjuktimmar'
                                    placement='top'
                                    disableInteractive>
                                    <SickOutlined
                                        className={classes.sickHoursIcon}
                                    />
                                </Tooltip>
                            </InputAdornment>
                        ) : null,
                    }}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    label='Stop'
                    value={value.stop || ''}
                    onChange={v =>
                        handleUpdateTimesheetDay(
                            { stop: v },
                            intervalIndex,
                            value.isSickHours
                        )
                    }
                    type='time'
                    error={!value.stop && !!value.start}
                    disabled={!canSubmit}
                    fullWidth
                    classes={{field: cx({
                        [classes.isSickHours]: value.isSickHours,
                    })}}
                    white={!value.isSickHours}
                    InputProps={{
                        startAdornment: value.isSickHours ? (
                            <InputAdornment position='start'>
                                <Tooltip
                                    title='Sjuktimmar'
                                    placement='top'
                                    disableInteractive>
                                    <SickOutlined
                                        className={classes.sickHoursIcon}
                                    />
                                </Tooltip>
                            </InputAdornment>
                        ) : null,
                    }}
                />
            </div>
            {value.isSickHours ? null : (
                <div className={classes.row}>
                    <NumberField
                        label='Rast'
                        value={value.breakInMinutes || 0}
                        onChange={v =>
                            handleUpdateTimesheetDay(
                                { breakInMinutes: v },
                                intervalIndex,
                                value.isSickHours
                            )
                        }
                        inputProps={{
                            style: { textAlign: 'right' },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    minuter
                                </InputAdornment>
                            ),
                        }}
                        disabled={!canSubmit}
                        allowZero
                        maxValue={120}
                        fullWidth
                    />
                </div>
            )}
        </>
    );

    return (
        <div className={classes.base}>
            {renderInterval(
                day.intervals && day.intervals[0]
                    ? day.intervals[0]
                    : {
                          start: '',
                          stop: '',
                          breakInMinutes: 0,
                          isSickHours: false,
                      },
                0
            )}
            {enableSickHours
                ? renderInterval(
                      day.intervals && day.intervals[1]
                          ? day.intervals[1]
                          : {
                                start: '',
                                stop: '',
                                breakInMinutes: 0,
                                isSickHours: true,
                            },
                      1
                  )
                : null}
            <div className={classes.row}>
                <Label text='Totalt antal timmar' />
                <Typography variant='body1' color='textPrimary'>
                    <b>{totalHours || '-'}</b>
                </Typography>
            </div>
        </div>
    );
}
