import React from 'react';
import App from 'pages/App';
import Page from 'components/Page';
import DocumentTitle from 'react-document-title';
import useWindowWidth from 'components/hooks/useWindowWidth';
import useMeStore from 'store/useMeStore';
import Header from 'components/Header/Header';
import Content from 'components/Content/Content';
import NotFound from 'components/interface/NotFound/NotFound';

export default function NotAuthorized() {
    const isLoggedIn = useMeStore(state => state.isLoggedIn);
    const { isMobile } = useWindowWidth();

    return (
        <DocumentTitle title='401 - Vårdbemanning Sverige'>
            <App>
                <Page>
                    <Header />
                    <Content>
                        <NotFound variant='401' isMobile={isMobile} />
                    </Content>
                </Page>
            </App>
        </DocumentTitle>
    );
}
