import React from 'react';
import DocumentTitle from 'react-document-title';
import App from 'pages/App';
import useWindowWidth from 'components/hooks/useWindowWidth';
import LandingPageWrapper from 'components/landingPage/Wrapper';
import ReplySMSComponent from 'components/replySMS/View';
import { useParams } from 'react-router';

export default function ReplySMSPage() {
    const { isMobile } = useWindowWidth();
    const [loading, setLoading] = React.useState(false);
    const { smshash } = useParams();

    return (
        <DocumentTitle title='Svara SMS - Vårdbemanning Sverige'>
            <App>
                <LandingPageWrapper isMobile={isMobile} loading={loading}>
                    <ReplySMSComponent
                        hash={smshash}
                        setLoading={setLoading}
                        isMobile={isMobile}
                    />
                </LandingPageWrapper>
            </App>
        </DocumentTitle>
    );
}
