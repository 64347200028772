import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Spinner from 'react-spinkit';
import Typography from '@mui/material/Typography';
import Button from 'components/interface/Button/Button';
import LoginPageWrapper from 'components/landingPage/Wrapper';
import useWindowWidth from 'components/hooks/useWindowWidth';

const useStyles = makeStyles()(theme => ({
    centerFixed: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',
        height: '100%',
        top: '0',
        left: '0',
    },
    screenFixed: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        position: 'fixed',
        height: '100vh',
        top: '0',
        left: '0',
        zIndex: 10000,
        background: 'white',
    },
    screenFixedContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
    },
    textWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    text: {
        color: theme.palette.primaryDark.main,
        userSelect: 'none',
        paddingBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(4),
    },
    loadingCircleWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingCircle: {
        width: '50px !important',
        height: '50px !important',
    },
    cubeGrid: {
        width: '75px !important',
        height: '75px !important',
    },
}));

interface LoadingProps {
    className?: string;
}

const Loading = (props: LoadingProps) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.loadingCircleWrapper, props.className)}>
            <Spinner
                name='circle'
                fadeIn='none'
                className={classes.loadingCircle}
            />
        </div>
    );
};

const LoadingGrid = () => {
    const { classes } = useStyles();

    return (
        <div className={classes.centerFixed}>
            <Spinner
                name='cube-grid'
                fadeIn='none'
                className={classes.cubeGrid}
            />
        </div>
    );
};

interface LoadingScreenGridProps {
    text?: string;
    subtitle?: string;
    cancelFunc?: () => void;
    classes: { [key: string]: string };
}

const LoadingScreenGridContent = ({
    text,
    subtitle,
    cancelFunc,
    classes,
}: LoadingScreenGridProps) => {
    return (
        <div className={classes.screenFixedContent}>
            <div className={classes.textWrapper}>
                {text ? (
                    <Typography
                        variant='h5'
                        className={classes.text}
                        align='left'>
                        <b>{text}</b>
                    </Typography>
                ) : null}
                {subtitle ? (
                    <Typography
                        variant='body1'
                        className={classes.text}
                        align='left'>
                        {subtitle}
                    </Typography>
                ) : null}
            </div>
            {!!cancelFunc ? (
                <Button
                    className={classes.button}
                    color='primaryDark'
                    variant='contained'
                    onClick={cancelFunc}
                    disableRipple
                    disableElevation>
                    Avbryt
                </Button>
            ) : undefined}
        </div>
    );
};

interface LoadingScreenProps {
    text?: string;
    subtitle?: string;
    cancelFunc?: () => void;
}

const LoadingScreen = ({ text, subtitle, cancelFunc }: LoadingScreenProps) => {
    const { classes } = useStyles();
    const { isMobile } = useWindowWidth();

    return (
        <div className={classes.screenFixed}>
            <LoginPageWrapper isMobile={isMobile} loading={true}>
                <LoadingScreenGridContent
                    text={text}
                    subtitle={subtitle}
                    cancelFunc={cancelFunc}
                    classes={classes}
                />
            </LoginPageWrapper>
        </div>
    );
};

export { Loading, LoadingGrid, LoadingScreen };
