import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Dialog, Typography } from '@mui/material';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { useBankID } from './hooks/useBankID';
import { WelcomeText } from 'components/landingPage/Wrapper';
import { Loading } from 'components/interface/Loading';
import { DigitalIdPayload, DigitalIdRequestEndpoint, STATUS } from './typings';
import { BankIDLogo } from 'components/landingPage/login/BankIDLogo';
import { LoginButton } from 'components/landingPage/login/View';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    base: {
        maxWidth: '350px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    status: {
        color: theme.palette.primaryDark.main,
        paddingBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    statusText: {
        alignSelf: 'center',
    },
    loadingSpinner: {
        marginBottom: theme.spacing(4),
        alignSelf: 'center',
    },
    qrCodeWrapper: {
        marginBottom: theme.spacing(2),
        borderRadius: '16px',
        height: '256px',
        width: '256px',
        alignSelf: 'center',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    qrCode: {
        borderRadius: '16px',
        height: '256px',
        width: '256px',
        backgroundColor: 'white',
        opacity: 0,
        transition:
            'all ease-in-out ' + theme.transitions.duration.shortest + 'ms',
    },
    qrCodeReady: {
        opacity: 1,
    },
    desktop: {},
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
}));

interface BankIDProps<T> {
    onGoBack: () => void;
    onSuccess: (respone: T) => void;
    type: TYPE; // this will only decide the title and subtitle. The actual request will be the same.
    endPoint: DigitalIdRequestEndpoint;
    title: string;
    subtitle?: string;
    closeText?: string;
    payload?: DigitalIdPayload;
    instant?: boolean;
    useRedirect?: boolean; // if the user should be redirected back to the same page by BankID, only works on mobile. And should for now only be used on login.
}

export enum BANKID_METHOD {
    MOBILE_SAME_DEVICE = 'MOBILE_SAME_DEVICE', // BankID app
    MOBILE_OTHER_DEVICE = 'MOBILE_OTHER_DEVICE', // QR code
    DESKTOP_SAME_DEVICE = 'DESKTOP_SAME_DEVICE', // BankID desktop
}

export enum TYPE {
    AUTH = 'AUTH',
    SIGN = 'SIGN',
}

export default function BankID<T>({
    onGoBack,
    onSuccess,
    type,
    endPoint,
    title,
    subtitle,
    closeText,
    payload,
    instant,
    useRedirect,
}: BankIDProps<T>) {
    const { classes, cx } = useStyles();
    const history = useHistory();
    const location = useLocation();

    // These setters uses the normla window.location.search to set the query parameters
    // this is because it wont have the newest location without a rerender if we use the react-router-dom location object
    const setSelectedMethod = (method?: BANKID_METHOD) => {
        if (method) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('bankid_method', method);
            history.replace({ search: searchParams.toString() });
        } else {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('bankid_method');
            history.replace({ search: searchParams.toString() });
        }
    };

    const setTransactionId = (id: string) => {
        if (id) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('transactionId', id);
            history.replace({ search: searchParams.toString() });
        } else {
            const searchParams = new URLSearchParams(window.location.search);
            if (searchParams.has('transactionId')) {
                searchParams.delete('transactionId');
                history.replace({ search: searchParams.toString() });
            }
        }
    };

    React.useEffect(() => {
        return () => {
            setSelectedMethod(undefined);
            setTransactionId('');
        };
    }, []);

    const selectedMethod = React.useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        const temp = searchParams.get('bankid_method');
        if (temp) {
            return temp as BANKID_METHOD;
        }
        return undefined;
    }, [location.search]);

    const transactionId = React.useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('transactionId') || '';
    }, [location.search]);

    const asMobile = isMobile || isTablet;

    React.useEffect(() => {
        if (!asMobile && instant) {
            handleSetMethod(BANKID_METHOD.MOBILE_OTHER_DEVICE);
        }
    }, []);

    const handleSuccess = (payload: any) => {
        onSuccess(payload as T);
    };

    const { start, cancel, status, message, qrCode, autoStartToken } =
        useBankID({
            onSuccess: handleSuccess,
            endPoint,
            payload,
            transactionId,
            setTransactionId,
        });

    React.useEffect(() => {
        if (
            status === STATUS.STARTED &&
            autoStartToken &&
            transactionId &&
            selectedMethod !== BANKID_METHOD.MOBILE_OTHER_DEVICE
        ) {
            handleOpenLinkInApp();
        }
    }, [status, autoStartToken, transactionId]);

    const handleSetMethod = (v: BANKID_METHOD) => {
        setSelectedMethod(v);
        start(v);
    };

    const handleCancelAuthentication = () => {
        cancel();
        setSelectedMethod(undefined);
    };

    const handleGoBack = () => {
        handleCancelAuthentication();
        onGoBack();
    };

    const handleOpenLinkInApp = () => {
        if (!autoStartToken) {
            return;
        }

        let redirect = 'null';
        if (useRedirect && asMobile && transactionId && isIOS) {
            const path = window.location.href.split('?')[0];
            // check if the query parameter transactionId exists, if not manually add it
            // Use URLSEarchParams to avoid adding the transactionId multiple times
            const searchParams = new URLSearchParams(window.location.search);
            if (!searchParams.has('transactionId')) {
                searchParams.append('transactionId', transactionId);
            }
            const url = `${path}?${searchParams.toString()}`;
            redirect = encodeURIComponent(url);
        }

        window.open(
            `bankid:///?autostarttoken=${autoStartToken}&redirect=${redirect}`,
            '_self'
        );
    };

    const renderBankIDMethods = () => {
        const renderMobile = () => (
            <>
                <LoginButton
                    color='primaryDark'
                    variant='contained'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.MOBILE_SAME_DEVICE)
                    }>
                    <BankIDLogo />
                    <Typography variant='body1' color='inherit'>
                        <b>Öppna Mobilt BankID</b>
                    </Typography>
                </LoginButton>
                <LoginButton
                    color='white'
                    variant='contained'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.MOBILE_OTHER_DEVICE)
                    }
                    disableElevation>
                    <Typography variant='body1' color='inherit'>
                        <b>Visa QR-kod</b>
                    </Typography>
                </LoginButton>
            </>
        );
        const renderDesktop = () => (
            <>
                <LoginButton
                    color='primaryDark'
                    variant='contained'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.MOBILE_OTHER_DEVICE)
                    }
                    disableElevation>
                    <BankIDLogo />
                    <Typography variant='body1' color='inherit'>
                        <b>Visa QR-kod</b>
                    </Typography>
                </LoginButton>
                <LoginButton
                    color='white'
                    variant='contained'
                    onClick={() =>
                        handleSetMethod(BANKID_METHOD.DESKTOP_SAME_DEVICE)
                    }>
                    <Typography variant='body1' color='inherit'>
                        <b>Öppna BankID på samma enhet</b>
                    </Typography>
                </LoginButton>
            </>
        );

        return (
            <div className={classes.buttons}>
                {asMobile ? renderMobile() : renderDesktop()}
                {closeText && (
                    <LoginButton
                        color='primaryDark'
                        variant='text'
                        onClick={handleGoBack}>
                        <Typography variant='body1' color='inherit'>
                            <b>{closeText}</b>
                        </Typography>
                    </LoginButton>
                )}
            </div>
        );
    };

    const renderAuthenticating = () => {
        const renderQRCode = () => (
            <>
                {type === TYPE.SIGN && (
                    <WelcomeText
                        title={'Skriv under med BankID'}
                        subtitle='Öppna BankID-appen och scanna QR-koden.'
                    />
                )}
                <div className={classes.qrCodeWrapper}>
                    <img
                        src={'data:image/png;base64,' + qrCode}
                        className={cx(classes.qrCode, {
                            [classes.qrCodeReady]: !!qrCode,
                        })}
                        draggable='false'
                    />
                </div>
            </>
        );

        const renderNormal = () => (
            <>
                {type === TYPE.SIGN && (
                    <WelcomeText
                        title={'Skriv under med BankID'}
                        subtitle='Öppna BankID-appen och scanna QR-koden.'
                    />
                )}
                {status === STATUS.PENDING ? (
                    <Loading className={classes.loadingSpinner} />
                ) : null}
            </>
        );

        return (
            <>
                <div className={classes.status}>
                    {selectedMethod === BANKID_METHOD.MOBILE_OTHER_DEVICE
                        ? renderQRCode()
                        : renderNormal()}
                    <Typography
                        className={classes.statusText}
                        variant='body1'
                        color='inherit'>
                        <b>{message || ''}&nbsp;</b>
                    </Typography>
                </div>
                <div className={classes.buttons}>
                    {status !== STATUS.FAILED && (
                        <LoginButton
                            color='primaryDark'
                            variant={'contained'}
                            onClick={handleOpenLinkInApp}>
                            <Typography variant='body1' color='inherit'>
                                <b>Starta BankID på denna enhet</b>
                            </Typography>
                        </LoginButton>
                    )}
                    <LoginButton
                        key='cancel-button'
                        color='primaryDark'
                        variant={
                            status === STATUS.FAILED ? 'contained' : 'outlined'
                        }
                        onClick={handleCancelAuthentication}>
                        <Typography variant='body1' color='inherit'>
                            <b>
                                {status === STATUS.FAILED
                                    ? 'Försök igen'
                                    : 'Avbryt'}
                            </b>
                        </Typography>
                    </LoginButton>
                </div>
            </>
        );
    };

    const renderStep = () => {
        if (selectedMethod) {
            return renderAuthenticating();
        }
        return (
            <>
                {renderTitle()}
                {renderBankIDMethods()}
            </>
        );
    };

    const renderTitle = () => <WelcomeText title={title} subtitle={subtitle} />;

    return <div className={classes.base}>{renderStep()}</div>;
}

interface BankIDDialogProps<T> extends BankIDProps<T> {
    open: boolean;
    isMobile?: boolean;
}

const useStylesDialog = makeStyles<void, 'success' | 'mobile'>()(
    (theme, _params, classes) => ({
        base: {
            backgroundImage: 'url(/images/pattern.png)',
            padding: theme.spacing(4),
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'row',
            [`&.${classes.success}`]: {
                backgroundImage: 'url(/images/pattern_animation.gif)',
            },
            [`&.${classes.mobile}`]: {
                paddingTop: 'calc(32px + env(safe-area-inset-top, 0px))',
                paddingBottom: 'calc(32px + env(safe-area-inset-bottom, 0px))',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                overflow: 'scroll',
            },
        },
        success: {},
        mobile: {},
        preloadLoadingAnimation: {
            backgroundImage: 'url(/images/pattern_animation.gif)',
            display: 'hidden',
        },
        wrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            opacity: 1,
            transition: 'opacity 0.5s ease-in-out',
            [`&.${classes.success}`]: {
                opacity: 0,
            },
        },
        successText: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0,
            transition: 'opacity 0.5s ease-in-out',
            color: theme.palette.primaryDark.main,
            pointerEvents: 'none',
            [`&.${classes.success}`]: {
                opacity: 1,
            },
        },
    })
);

export function BankIDDialog<T>({
    open,
    onSuccess,
    isMobile,
    ...rest
}: BankIDDialogProps<T>) {
    const { classes, cx } = useStylesDialog();
    const [success, setSuccess] = React.useState(false);

    React.useEffect(() => {
        if (!open && success) {
            setSuccess(false);
        }
    }, [open]);

    const handleSuccess = (v: T) => {
        setSuccess(true);
        setTimeout(() => {
            onSuccess(v);
        }, 1500);
    };

    return (
        <Dialog
            open={open}
            classes={{
                paper: cx(classes.base, {
                    [classes.success]: success,
                    [classes.mobile]: isMobile,
                }),
            }}
            fullScreen={isMobile}>
            <div className={classes.preloadLoadingAnimation} />
            <div
                className={cx(classes.wrapper, { [classes.success]: success })}>
                <BankID<T>
                    closeText='Stäng'
                    instant={!isMobile}
                    {...rest}
                    onSuccess={handleSuccess}
                />
            </div>
            <div
                className={cx(classes.successText, {
                    [classes.success]: success,
                })}>
                <Typography variant='h6' color='inherit'>
                    Klart!
                </Typography>
            </div>
        </Dialog>
    );
}
