import { create } from 'store/create';
import { create as persistCreate } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

// This store is used to keep track of the build version of the app.
// Most of the code here can be removed now that we use the brute force method of updating the app.
type Store = {
    version: null | string;
    updateAvailable: boolean;
    installing: boolean;
    doInstall: boolean;
    registration?: ServiceWorkerRegistration;
    waitingWorker?: ServiceWorker;
    isWaiting: boolean;
    timestamp: number | null;
    setVersion: (value: string) => void;
    setInstalling: (value: boolean) => void;
    setDoInstall: (value: boolean) => void;
    setAvailable: (value: boolean) => void;
    setIsWaiting: (value: boolean) => void;
    setWaitingWorker: (value?: ServiceWorker) => void;
    setRegistration: (value?: ServiceWorkerRegistration) => void;
    reset: () => void;
};

const useBuildVersionStore = create<Store>(set => ({
    version: null,
    updateAvailable: false,
    installing: false,
    doInstall: false,
    isWaiting: false,
    timestamp: null,
    setVersion: version =>
        set({ version: version, timestamp: new Date().getTime() }),
    setInstalling: value => set({ installing: value }),
    setDoInstall: value => set({ doInstall: value }),
    setAvailable: value => set({ updateAvailable: value }),
    setIsWaiting: value => set({ isWaiting: value }),
    setRegistration: value => set({ registration: value }),
    setWaitingWorker: value => set({ waitingWorker: value }),
    reset: () =>
        set({
            updateAvailable: false,
            installing: false,
            doInstall: false,
            isWaiting: false,
        }),
}));

type ForceInstallStore = {
    forceInstall: boolean;
    setForceInstall: (value: boolean) => void;
};
export const useForceInstallStore = persistCreate<ForceInstallStore>()(
    persist(
        set => ({
            forceInstall: false,
            setForceInstall: value => set({ forceInstall: value }),
        }),
        {
            name: 'installer', // name of item in the storage (must be unique)
            version: 1,
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useBuildVersionStore;
