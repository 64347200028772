import axios from './Axios';
import Api from './Api';
import { EMAIL_TYPE } from 'typings/email';

export default class EmailAPI {
    /**
     *
     * @param token - the token of the account
     * @param ids - (optional). list of ids
     * @param type - the type of the email that will be sent
     * @returns
     */
    unsubscribeFromEmailsOfType(id: string, type: EMAIL_TYPE) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/email-preference/unsubscribe/${id}?type=${type}`,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}
