import React from 'react';
import {
    Answer,
    Answers,
    QUESTION_TYPE,
    Question,
    QuestionnaireSimple,
    Section,
} from '../typings';
import { makeStyles } from 'tss-react/mui';
import { styled, Typography } from '@mui/material';
import Button from 'components/interface/Button/Button';
import TextField from 'components/interface/textField/TextField';
import { grey } from '@mui/material/colors';
import MultipleChoicesType from '../MultipleChoicesType';
import BooleanType from '../BooleanType';
import CloseTabIcon from '@mui/icons-material/ExitToAppOutlined';
import useMeStore from 'store/useMeStore';
import { useHistory } from 'react-router';
import NumericScaleType from '../NumericScaleType';

const useStyles = makeStyles<void, 'smallGap'>()((theme, _params, classes) => ({
    base: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    description: {},
    descriptionText: {
        paddingTop: theme.spacing(1),
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: theme.spacing(2),
        rowGap: theme.spacing(4),
        [`&.${classes.smallGap}`]: {
            rowGap: theme.spacing(2),
        },
    },
    question: {
        display: 'flex',
        flexDirection: 'column',
        columnGap: theme.spacing(4),
    },
    questionText: {
        width: '100%',
        userSelect: 'none',
        padding: theme.spacing(1,2),
        background: grey[300],
        borderRadius: theme.shape.borderRadius,
    },
    answer: {
        width: '100%',
        userSelect: 'none',
        padding: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        width: '100%',
    },
    smallGap: {},
}));

const SectionHeaderWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
}));

const SectionHeader = styled('div', {
    shouldForwardProp: prop => prop !== 'themeColor',
})<{ themeColor: string }>(({ theme, themeColor }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    borderRadius: theme.shape.borderRadius,
    background: themeColor,
    color: 'white',
    fontWeight: 700,
    textTransform: 'uppercase',
    userSelect: 'none',
}));

interface MobileViewProps {
    questionnaire: QuestionnaireSimple | null;
    section: Section | undefined;
    isSubmitted: boolean;
    sectionIndex: number;
    answers: Answers;
    description: string;
    isSectionValid: boolean;
    isLastSection: boolean;
    loading: boolean;
    error: boolean;
    onChange: (id: number, updateFunc: (prev: Answer) => Answer) => void;
    onNextSection: () => void;
    onPreviousSection: () => void;
    onSubmit: () => void;
    onResetError: () => void;
}

export default function MobileView({
    questionnaire,
    section,
    isSubmitted,
    sectionIndex,
    answers,
    description,
    isSectionValid,
    isLastSection,
    loading,
    error,
    onChange,
    onNextSection,
    onPreviousSection,
    onSubmit,
    onResetError,
}: MobileViewProps) {
    const { classes, cx } = useStyles();
    const isLoggedIn = useMeStore(state => state.isLoggedIn);
    const history = useHistory();

    return (
        <div className={classes.base}>
            <div className={classes.description}>
                <Typography
                    variant='h5'
                    fontFamily='Poppins'
                    color='textPrimary'>
                    <b>{questionnaire?.name}</b>
                </Typography>
                {(sectionIndex === 0 || isSubmitted) && (
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        fontFamily='Poppins'
                        className={classes.descriptionText}>
                        Hej! <br /> {description}
                    </Typography>
                )}
            </div>
            {section && !error && !isSubmitted && (
                <>
                    {sectionIndex === 0 && (
                        <>
                            {questionnaire?.headers.map(header => (
                                <div
                                    className={cx(
                                        classes.section,
                                        classes.smallGap
                                    )}
                                    key={header.order}>
                                    <SectionHeader
                                        themeColor={questionnaire.themeColor}>
                                        <Typography
                                            variant='h6'
                                            fontFamily='Poppins'
                                            color='inherit'>
                                            <b>{header.title}</b>
                                        </Typography>
                                    </SectionHeader>
                                    {header.fields.map(field => (
                                        <TextField
                                            key={field.order}
                                            value={field.value}
                                            label={field.title}
                                            variant='standard'
                                            fullWidth
                                            disabled
                                        />
                                    ))}
                                </div>
                            ))}
                            <div
                                className={cx(
                                    classes.section,
                                    classes.smallGap
                                )}>
                                <SectionHeader
                                    themeColor={questionnaire!.themeColor}>
                                    <Typography
                                        variant='h6'
                                        fontFamily='Poppins'
                                        color='inherit'>
                                        <b>Instruktioner</b>
                                    </Typography>
                                </SectionHeader>
                                <Typography
                                    variant='body2'
                                    fontFamily='Poppins'
                                    color='textPrimary'>
                                    {questionnaire?.description}
                                </Typography>
                            </div>
                        </>
                    )}
                    <div className={classes.section}>
                        <SectionHeaderWrapper>
                            <SectionHeader
                                themeColor={questionnaire!.themeColor}>
                                <Typography
                                    variant='h6'
                                    fontFamily='Poppins'
                                    color='inherit'>
                                    <b>{`${sectionIndex + 1}.${
                                        section.title
                                    }`}</b>
                                </Typography>
                            </SectionHeader>
                            <Typography
                                variant='body2'
                                fontFamily='Poppins'
                                color='textPrimary'>
                                {section.instructions}
                            </Typography>
                        </SectionHeaderWrapper>
                        {section.questions.map(question => (
                            <QuestionRow
                                key={question.id}
                                question={question}
                                answer={
                                    answers[question.id] || {
                                        questionID: question.id,
                                        answer: '',
                                        comment: '',
                                    }
                                }
                                onChange={updateFunc =>
                                    onChange(question.id, updateFunc)
                                }
                            />
                        ))}
                    </div>
                    <div className={classes.buttons}>
                        <Typography
                            variant='caption'
                            color='textSecondary'
                            fontFamily='Poppins'
                            style={{ userSelect: 'none' }}>
                            Sida {sectionIndex + 1} av{' '}
                            {questionnaire?.sections.length}
                        </Typography>
                        <Button
                            onClick={onPreviousSection}
                            disabled={sectionIndex === 0 || loading}>
                            Föregående
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={isLastSection ? onSubmit : onNextSection}
                            disabled={!isSectionValid}
                            loading={loading}>
                            {isLastSection ? 'Skicka in' : 'Nästa'}
                        </Button>
                    </div>
                </>
            )}
            {error && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: '16px',
                        marginTop: '16px',
                    }}>
                    <Typography
                        variant='body1'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        Något gick fel, försök igen. Om problemet kvarstår,
                        kontakta support.
                    </Typography>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onResetError}>
                        Försök igen
                    </Button>
                </div>
            )}
            {isSubmitted && isLoggedIn && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        rowGap: '16px',
                        marginTop: '16px',
                    }}>
                    <Button
                        variant='outlined'
                        color='default'
                        onClick={() => {
                            history.replace('/');
                        }}
                        startIcon={<CloseTabIcon />}>
                        Tillbaka till startsidan
                    </Button>
                </div>
            )}
        </div>
    );
}

interface QuestionRowProps {
    question: Question;
    answer: Answer;
    onChange: (updateFunc: (prev: Answer) => Answer) => void;
}

const QuestionRow = ({ question, answer, onChange }: QuestionRowProps) => {
    const { classes } = useStyles();

    const renderOptions = () => {
        if (question.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
            return (
                <MultipleChoicesType
                    question={question}
                    answer={answer}
                    onChange={onChange}
                />
            );
        } else if (question.type === QUESTION_TYPE.BOOLEAN) {
            return (
                <BooleanType
                    question={question}
                    answer={answer}
                    onChange={onChange}
                />
            );
        } else if (question.type === QUESTION_TYPE.NUMERIC_SCALE) {
            return (
                <NumericScaleType
                    question={question}
                    answer={answer}
                    onChange={onChange}
                />
            );
        } else if (question.type === QUESTION_TYPE.TEXT) {
            return (
                <TextField
                    value={answer.answer as string}
                    onChange={v =>
                        onChange(prev => ({
                            ...prev,
                            answer: v,
                        }))
                    }
                    placeholder='Skriv ditt svar här'
                    multiline
                    maxRows={10}
                    minRows={4}
                    fullWidth
                />
            );
        }
    };

    return (
        <div className={classes.question}>
            {question.question && (
                <Typography
                    variant='body1'
                    color='textPrimary'
                    fontFamily='Poppins'
                    className={classes.questionText}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: question.question,
                        }}
                    />
                </Typography>
            )}
            <div className={classes.answer}>{renderOptions()}</div>
        </div>
    );
};
