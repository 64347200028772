import axios from "./Axios";
import Api from "./Api";

export default class OpenAPI {
    /**
     * Sends an reply to the sms
     * @param {string} hash - The sms hash
     * @param {string} message - The message
     * @returns  A Axios promise
     */
    replySMS(hash = null, message = null) {
        return axios({
            method: "POST",
            timeout: Api.Timeout,
            url: `${Api.Host}/openapi/sms/reply/${hash}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                message,
            },
        });
    }
}
