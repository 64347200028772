import React from 'react';
import App from 'pages/App';
import ChangePasswordComponent from 'components/landingPage/changePassword/View';
import useDocumentTitle from 'components/hooks/useDocumentTitle';
import useWindowWidth from 'components/hooks/useWindowWidth';

export default function ChangePasswordPage() {
    useDocumentTitle('Byt lösenord');
    const { isMobile } = useWindowWidth();
    return (
        <App>
            <ChangePasswordComponent isMobile={isMobile} />
        </App>
    );
}
