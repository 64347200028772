import React from 'react';
import Api from 'api/Api';
import useMeStore from 'store/useMeStore';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import useFiles, { RESOURCE } from './useFiles';
import { useListStore } from '../../list/store/useStore';
import useAssignmentPDF from './useAssignmentPDF';
import useTimesheet from '../../timesheet/hooks/useTimesheet';
import { Assignment, emptyAssignmentData } from 'typings/assignment';

export default function useAssignment(id: string) {
    const token = useMeStore(state => state.token);

    const {
        reset: resetData,
        ...informationProps
    } = useAssignmentInformation({ id, token });

    const { fetch: fetchStats, ...statsProps } = useAssignmentStats({
        id,
        token,
    });

    const timesheetProps = useTimesheet(id);

    const { ...filesProps } = useFiles({
        id,
        resource: RESOURCE.ASSIGNMENT,
    });

    React.useEffect(() => {
        if (!id) {
            resetData();
            return;
        }
        informationProps.fetch();
        filesProps.fetchFiles();
        fetchStats();
    }, [id]);

    return {
        informationProps,
        timesheetProps,
        filesProps,
        statsProps,
    };
}

interface useAssignmentInformationProps {
    id: string;
    token: string;
}

export const useAssignmentInformation = ({
    id,
    token,
}: useAssignmentInformationProps) => {
    const [information, setInformation] = React.useState<Assignment>({
        ...emptyAssignmentData,
    });
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const timestamp = React.useRef(0);
    const pushNotification = useNotificationStore(state => state.push);
    const updateRowInList = useListStore(state => state.updateRow);

    const { fetchTimesheetPDF, fetchBookingConfirmationPDF } =
        useAssignmentPDF();

    const reset = () => {
        setInformation({
            ...emptyAssignmentData,
        });
        setLoading(false);
        setError(false);
    };

    const fetch = React.useCallback(async () => {
        setLoading(true);
        setError(false);
        const initiatedTimestamp = new Date().getTime();
        timestamp.current = initiatedTimestamp;
        try {
            const response = await Api.assignment.fetchAssignment(token, id);
            const { status, data } = response;
            if (status === 200) {
                if (timestamp.current > initiatedTimestamp) {
                    return;
                }
                setInformation(data);
                updateRowInList(data);
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setError(true);
            pushNotification(
                NotificationTypes.error,
                'Det gick inte att hämta uppdrag.',
                'assignment',
                1
            );
        }
        setLoading(false);
    }, [id, token]);

    const handleFetchTimesheetPDF = () => fetchTimesheetPDF(id, information?.year, information?.week);
    const handleFetchBookingConfirmationPDF = () =>
        fetchBookingConfirmationPDF(id);

    return {
        fetch,
        fetchTimesheetPDF: handleFetchTimesheetPDF,
        fetchBookingConfirmationPDF: handleFetchBookingConfirmationPDF,
        reset,
        data: information,
        loading,
        error,
        timestamp: timestamp.current,
    };
};

const emptyAssignmentStats = {
    carePlaceID: null,
    totalAssignments: 0,
    finishedAssignments: 0,
};

interface useAssignmentStatsProps {
    id: string;
    token: string;
}

const useAssignmentStats = ({ id, token }: useAssignmentStatsProps) => {
    const [stats, setStats] = React.useState({
        ...emptyAssignmentStats,
    });
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const fetch = React.useCallback(async () => {
        setLoading(true);
        setError(false);
        try {
            const response = await Api.assignment.fetchAssignmentStats(
                token,
                id
            );
            const { status, data } = response;
            if (status === 200) {
                setStats(data);
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setError(true);
        }
        setLoading(false);
    }, [id, token]);

    return {
        fetch,
        stats,
        loading,
        error,
    };
};
