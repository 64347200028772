import React from "react";

import { makeStyles } from 'tss-react/mui';
import { ReactComponent as BlobLogo } from "assets/blob_logo.svg";
import { ReactComponent as PrimaryLogo } from "assets/vbs_logo_new_primary.svg";
import { ReactComponent as DefaultLogo } from "assets/vbs_logo_new_white.svg";

const useStyles = makeStyles()((_theme, __params, _classes) => ({
    logoLarge: {
        width: "300px",
        height: "100px",
        "& svg": {
            width: "300px",
            height: "100px",
        },
    },
    logoHeader: {
        width: "135px",
        height: "65px",
        "& svg": {
            width: "135px",
            height: "65px",
        },
    },
    logoSidedrawer: {
        width: "150px",
        "& svg": {
            width: "150px",
        },
    },
    blob: {
        position: "absolute",
        top: 0,
        right: 0,
        flexShrink: 0,
        "& svg": {
            maxWidth: "100%",
            height: "auto",
        },
    },
    noShrink: {
        flexShrink: 0,
    },
}));

interface Props {
    isHeader?: boolean;
    isSidedrawer?: boolean;
    variant?: 'primary' | 'blob';
    className?: string;
    [x: string]: any;
}

export default function Logo({
    isHeader,
    isSidedrawer,
    variant,
    className,
    ...rest
}: Props) {
    const { classes, cx } = useStyles();
    const Component = (() => {
        switch (variant) {
            case "primary":
                return PrimaryLogo;
            case "blob":
                return BlobLogo;
            default:
                return DefaultLogo;
        }
    })();

    return (
        <Component
            className={cx(
                classes.noShrink,
                {
                    [classes.logoLarge]:
                        !isHeader && !isSidedrawer && variant !== "blob",
                    [classes.logoHeader]: isHeader && variant !== "blob",
                    [classes.logoSidedrawer]: isSidedrawer,
                    [classes.blob]: variant === "blob",
                },
                className
            )}
            {...rest}
        />
    );
}