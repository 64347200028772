import React from "react";
import {
    DetailsTable,
    DetailsRow,
} from "components/interface/DetailsTable/DetailsTable";
import Currency from "components/interface/currency/Currency";

export default function InformationComponent({
    data,
    loading,
}) {
    return (
        <DetailsTable title="Information" isMobile>
            <DetailsRow
                name={"Land"}
                value={data?.country?.name || "-"}
                loading={loading}
                noSelect
                isMobile
            />
            <DetailsRow
                name={"Region"}
                value={data?.county?.name || "-"}
                loading={loading}
                noSelect
                isMobile
            />
            <DetailsRow
                name={"Bruttoersättning"}
                value={
                    <Currency
                        value={
                            data?.pay?.revisedValue ||
                            data?.pay?.value
                        }
                        currencyCode={data?.country?.currencyCode}
                    />
                }
                loading={loading}
                noSelect
                isMobile
            />
        </DetailsTable>
    );
}
