import React from 'react';
import App from 'pages/App';
import ForgotPasswordComponent from 'components/landingPage/forgotPassword/View';
import useDocumentTitle from 'components/hooks/useDocumentTitle';
import useWindowWidth from 'components/hooks/useWindowWidth';

export default function ForgotPasswordPage() {
    useDocumentTitle('Glömt lösenord');
    const { isMobile } = useWindowWidth();
    return (
        <App>
            <ForgotPasswordComponent isMobile={isMobile} />
        </App>
    );
}
