import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Travelsheet, TravelsheetKeyType } from '../typings';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from 'components/interface/textField/TextField';
import { DatePicker } from 'components/interface/datePicker/DatePicker';

const useStyles = makeStyles()(theme => ({
    base: {
        width: '100%',
    },
    table: {
        background: '#fff',
    },
    button: {
        marginBottom: theme.spacing(2),
    },
    buttonIcon: {
        marginLeft: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    headerCell: {
        background: grey[200],
        color: grey[800],
        fontSize: '14px',
        fontWeight: '400',
    },
    cell: {
        'height': '48px',
        'wordBreak': 'break-word',
        'hyphens': 'auto',
        'textAlign': 'center',
        'paddingLeft': theme.spacing(2),
        'paddingRight': theme.spacing(2),
        'border': '1px solid ' + grey[800],
        '&:last-child': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    cellSums: {
        background: theme.palette.primary['200'],
    },
    dateField: {
        width: '140px',
    },
}));

interface Props {
    travelsheet: Travelsheet;
    canSubmit: boolean;
    onTravelsheetFieldChange: (
        key: TravelsheetKeyType,
        index: number
    ) => (value: string) => void;
}

const TravelsheetComponent = ({
    travelsheet,
    canSubmit,
    onTravelsheetFieldChange,
}: Props): JSX.Element => {
    const { classes, cx } = useStyles();

    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell
                        align='center'
                        colSpan={1}
                        className={cx(classes.headerCell, classes.cell)}>
                        Datum
                    </TableCell>
                    <TableCell
                        align='center'
                        colSpan={1}
                        className={cx(classes.headerCell, classes.cell)}>
                        Färdsätt
                    </TableCell>
                    <TableCell
                        align='center'
                        colSpan={1}
                        className={cx(classes.headerCell, classes.cell)}>
                        Från
                    </TableCell>
                    <TableCell
                        align='center'
                        colSpan={1}
                        className={cx(classes.headerCell, classes.cell)}>
                        Till
                    </TableCell>
                    <TableCell
                        align='center'
                        colSpan={1}
                        className={cx(classes.headerCell, classes.cell)}>
                        Antal mil
                    </TableCell>
                    <TableCell
                        align='center'
                        colSpan={1}
                        className={cx(classes.headerCell, classes.cell)}>
                        Utlägg enl kvitto
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

    const renderRow = (key: TravelsheetKeyType) => {
        const values = travelsheet ? travelsheet[key] : [];

        return (
            <TableRow>
                {renderCell(
                    values && values[0] ? values[0] : '',
                    'date',
                    key,
                    0
                )}
                {renderCell(
                    values && values[1] ? values[1] : '',
                    'text',
                    key,
                    1
                )}
                {renderCell(
                    values && values[2] ? values[2] : '',
                    'text',
                    key,
                    2
                )}
                {renderCell(
                    values && values[3] ? values[3] : '',
                    'text',
                    key,
                    3
                )}
                {renderCell(
                    values && values[4] ? values[4] : '',
                    'number',
                    key,
                    4
                )}
                {renderCell(
                    values && values[5] ? values[5] : '',
                    'number',
                    key,
                    5
                )}
            </TableRow>
        );
    };

    const renderCell = (
        value: string,
        type: string,
        key: TravelsheetKeyType,
        index: number
    ) => {
        if (canSubmit) {
            return (
                <TableCell className={classes.cell}>
                    {type === 'date' ? (
                        <DatePicker
                            className={classes.dateField}
                            value={value}
                            onChange={onTravelsheetFieldChange(key, index)}
                            variant='standard'
                            noBorder
                           
                        />
                    ) : (
                        <TextField
                            id={key + index.toString()}
                            value={value || ''}
                            type={type}
                            onChange={onTravelsheetFieldChange(key, index)}
                            inputProps={{
                                style: { textAlign: 'center' },
                            }}
                            variant='outlined'
                            noBorder
                        />
                    )}
                </TableCell>
            );
        } else {
            return (
                <TableCell colSpan={1} className={classes.cell}>
                    {value}
                </TableCell>
            );
        }
    };

    const renderSum = () => {
        return (
            <TableRow>
                <TableCell
                    align='center'
                    colSpan={4}
                    className={cx(classes.headerCell, classes.cell)}>
                    Summering
                </TableCell>
                <TableCell
                    colSpan={1}
                    className={cx(classes.cellSums, classes.cell)}>
                    {travelsheet.totalMileage}
                </TableCell>
                <TableCell
                    colSpan={1}
                    className={cx(classes.cellSums, classes.cell)}>
                    {travelsheet.totalPayment}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <div className={classes.base}>
            <Typography
                variant='h6'
                className={classes.title}
                color='textPrimary'>
                <b>Reseräkning</b>
            </Typography>
            <Table padding='none' className={classes.table}>
                {renderHeader()}
                <TableBody>
                    {renderRow('travel1')}
                    {renderRow('travel2')}
                    {renderRow('travel3')}
                    {renderRow('travel4')}
                    {renderRow('travel5')}
                    {renderRow('travel6')}
                    {renderRow('travel7')}
                    {renderSum()}
                </TableBody>
            </Table>
        </div>
    );
};

export default TravelsheetComponent;
