import React from 'react';
import { cx } from '@emotion/css';
import { makeStyles } from 'tss-react/mui';
import Card from 'components/interface/card/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material//Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

const useStyles = makeStyles<void, 'small' | 'grow' | 'mobile'>()(
    (theme, _params, classes) => ({
        widgetWrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
        },
        widgetColumn: {
            'width': '50%',
            'display': 'flex',
            'flexDirection': 'column',
            '&:first-of-type': {
                paddingRight: theme.spacing(2),
            },
            '& + &': {
                paddingLeft: theme.spacing(2),
            },
            [`&.${classes.small}`]: {
                width: '33%',
                minWidth: '470px',
            },
            [`&.${classes.grow}`]: {
                flexGrow: '1',
            },
        },
        small: {},
        grow: {},
        card: {
            'width': '100%',
            '& + &': {
                marginTop: theme.spacing(4),
            },
        },
        header: {
            width: '100%',
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
            userSelect: 'none',
        },
        widgetActionButton: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        body: {
            width: '100%',
        },
        fullWidth: {},
        row: {
            'width': '100%',
            'display': 'flex',
            'flexDirection': 'column',
            'paddingTop': theme.spacing(2),
            'paddingBottom': theme.spacing(2),
            'paddingLeft': theme.spacing(),
            'paddingRight': theme.spacing(),
            '& + &': {
                borderTop: '1px solid ' + theme.palette.divider,
            },
            'flexShrink': 0,
            [`&.${classes.mobile}`]: {
                'border': 'none',
                'userSelect': 'none',
                'padding': theme.spacing(2),
                '& + &': {
                    marginTop: theme.spacing(),
                },
            },
        },
        mobile: {},
        rowHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        rowHeaderTitle: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        headerMenuButton: {
            marginLeft: theme.spacing(),
            marginRight: theme.spacing(),
        },
        rowBody: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginTop: theme.spacing(2),
        },
        rowField: {
            'display': 'flex',
            'alignItems': 'center',
            '&:not(:first-of-type)': {
                marginTop: theme.spacing(0.5),
            },
        },
        rowFieldText: {
            marginLeft: theme.spacing(),
            userSelect: 'text',
        },
    })
);

type ColumnWrapperProps = {
    children: React.ReactNode;
};

const ColumnWrapper = ({ children }: ColumnWrapperProps) => {
    const { classes } = useStyles();

    return <div className={classes.widgetWrapper}>{children}</div>;
};

type ColumnProps = {
    children: React.ReactNode;
    small: boolean;
    grow: boolean;
};

const Column = ({ children, small, grow }: ColumnProps) => {
    const { classes, cx } = useStyles();

    return (
        <div
            className={cx(classes.widgetColumn, {
                [classes.small]: !!small,
                [classes.grow]: !!grow,
            })}>
            {children}
        </div>
    );
};

type WidgetProps = {
    children: React.ReactNode;
    title: string;
    icon?: React.ReactNode;
    onClick?: () => void;
    showButton?: boolean;
    classes?: string;
};

const Widget = ({
    children,
    title,
    icon,
    onClick,
    showButton,
    classes: classesProps,
}: WidgetProps) => {
    const { classes, cx } = useStyles();

    return (
        <Card className={cx(classes.card, classesProps)}>
            <div className={classes.header}>
                <Typography variant='h6' color='textPrimary'>
                    {title}
                </Typography>
                {showButton && onClick && icon ? (
                    <IconButton
                        color='default'
                        onClick={() => onClick()}
                        size='small'
                        className={classes.widgetActionButton}>
                        {icon}
                    </IconButton>
                ) : null}
            </div>
            <Divider />
            <div className={classes.body}>{children}</div>
        </Card>
    );
};

type RowProps = {
    children: React.ReactNode;
    isMobile?: boolean;
};

const Row = ({ children, isMobile }: RowProps) => {
    const { classes } = useStyles();

    return isMobile ? (
        <Card className={cx(classes.row, classes.mobile)}>{children}</Card>
    ) : (
        <div className={classes.row}>{children}</div>
    );
};

type RowHeaderProps = {
    children: React.ReactNode;
    title: string;
    subtitle?: string;
    subtitleProps?: { [key: string]: any };
    endButton?: React.ReactNode;
    loading?: boolean;
};

const RowHeader = ({
    title,
    subtitle,
    subtitleProps,
    endButton,
    loading,
}: RowHeaderProps) => {
    const { classes } = useStyles();

    return (
        <div className={classes.rowHeader}>
            <div className={classes.rowHeaderTitle}>
                {loading && !subtitle ? (
                    <>
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='192px'
                            height='20px'
                        />
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                            height='19px'
                        />
                    </>
                ) : (
                    <>
                        <Typography variant='body2' color='textPrimary'>
                            <b>{title}</b>
                        </Typography>
                        <Typography
                            variant='caption'
                            color='textSecondary'
                            {...subtitleProps}>
                            {subtitle}
                        </Typography>
                    </>
                )}
            </div>
            <div className={classes.headerMenuButton}>{endButton}</div>
        </div>
    );
};

type RowBodyProps = {
    children: React.ReactNode;
};

const RowBody = ({ children }: RowBodyProps) => {
    const { classes } = useStyles();

    return <div className={classes.rowBody}>{children}</div>;
};

type RowFieldProps = {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    text: React.ReactNode;
    tooltipText?: React.ReactNode;
    shouldRender?: boolean;

    classes?: { [key: string]: string };
    textProps?: { [key: string]: any };
    tooltipProps?: { [key: string]: any };
    loading?: boolean;
};

const RowField = ({
    icon: Icon,
    text,
    tooltipText,
    shouldRender,
    classes: classesProps,
    textProps,
    tooltipProps,
    loading,
}: RowFieldProps) => {
    const { classes } = useStyles();

    const render = () => (
        <div className={classes.rowField}>
            <Icon fontSize='small' />
            <Typography
                className={cx(classes.rowFieldText, classesProps?.text)}
                variant='body2'
                color='textPrimary'
                {...textProps}>
                <b>{text}</b>
            </Typography>
        </div>
    );

    return !!loading ? (
        <Skeleton
            animation='pulse'
            variant='text'
            width='128px'
            height='20px'
            className={classes.rowField}
        />
    ) : !!shouldRender ? (
        !!tooltipText ? (
            <Tooltip
                enterTouchDelay={500}
                title={tooltipText}
                placement='top'
                {...tooltipProps}>
                {render()}
            </Tooltip>
        ) : (
            render()
        )
    ) : null;
};

export { ColumnWrapper, Column, Widget, Row, RowHeader, RowBody, RowField };
