import React from 'react';
import Api from 'api/Api';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import TextField, { Label } from 'components/interface/textField/TextField';
import ReactGA from 'react-ga4';
import Button from 'components/interface/Button/Button';
import { Typography } from '@mui/material';
import { isValidEmailAddress } from 'utils/utils_legacy';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import Wrapper, { WelcomeText } from '../Wrapper';

const useStyles = makeStyles<void, 'loading' | 'hidden'>()(
    (theme, _params, classes) => ({
        body: {
            maxWidth: '350px',
            width: '100%',
            flexShrink: 0,
            opacity: 1,
            transition: 'opacity 2.0s ease-in-out',
            [`&.${classes.loading}`]: {
                transition: 'opacity 1.0s ease-in-out',
                opacity: 0,
            },
        },
        textFieldBase: {
            width: '100%',
            maxWidth: '350px',
            flexShrink: 0,
            marginTop: theme.spacing(0.5),
        },
        textField: {
            maxWidth: '350px',
        },
        textFieldRoot: {
            height: '48px',
        },
        showPasswordIcon: {
            color: theme.palette.primaryDark.main,
        },
        loginButtonWrapper: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: theme.spacing(4),
        },
        loginButton: {
            width: '50%',
            height: '40px',
            opacity: 1,
            transition: 'opacity 1.0s ease-in-out',
            [`&.${classes.hidden}`]: {
                opacity: 0,
            },
        },
        forgotPasswordButtonWrapper: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '50%',
            textDecoration: 'underline',
            color: theme.palette.primaryDark.main,
            userSelect: 'none',
        },
        inputWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            width: '100%',
            paddingBottom: theme.spacing(2),
        },
        welcomeText: {
            userSelect: 'none',
            color: theme.palette.primaryDark.main,
            paddingBottom: theme.spacing(4),
        },
        welcomeTextTitle: {
            paddingBottom: theme.spacing(1),
        },
        label: {
            'color': theme.palette.primaryDark.main,
            '& p': {
                fontWeight: 'bold',
            },
        },
        successText: {
            userSelect: 'none',
            color: theme.palette.primaryDark.main,
            paddingTop: theme.spacing(2),
        },
        desktop: {},
        loading: {},
        hidden: {},
    })
);

interface Props {
    isMobile: boolean;
}

export default function ForgotPasswordComponent({ isMobile }: Props) {
    const { classes, cx } = useStyles();
    const [username, setUsername] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const pushNotification = useNotificationStore(state => state.push);

    const requestPasswordRecovery = async () => {
        setLoading(true);
        try {
            const response = await Api.user.requestPasswordRecovery(username);
            const { status } = response;
            if (status === 200) {
                setSuccess(true);
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            pushNotification(
                NotificationTypes.error,
                'Det gick inte att begära nytt lösenord, försök igen',
                'forgot-password-fail',
                1
            );
        }
        setLoading(false);
    };

    const handleForgotPassword = () => {
        if (username.length < 2 || !isValidEmailAddress(username)) {
            pushNotification(
                NotificationTypes.error,
                'Ange en e-post',
                'forgot-password-fail',
                2
            );
            ReactGA.event({
                category: 'ForgotPassword',
                action: 'Submit fail',
                label: 'invalid username',
            });
            return;
        }
        ReactGA.event({
            category: 'ForgotPassword',
            action: 'Submit',
        });
        requestPasswordRecovery();
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleForgotPassword();
        }
    };

    const loginButtons = () => {
        return (
            <div className={classes.loginButtonWrapper}>
                <div className={classes.forgotPasswordButtonWrapper}>
                    <NavLink exact to={`/logga-in`}>
                        <Typography
                            variant='body2'
                            color='inherit'
                            onClick={() => {
                                ReactGA.event({
                                    category: 'ForgotPassword',
                                    action: 'Go to login',
                                });
                            }}>
                            <b>Tillbaka till login</b>
                        </Typography>
                    </NavLink>
                </div>
                <Button
                    color='primaryDark'
                    variant='contained'
                    onClick={handleForgotPassword}
                    className={cx(classes.loginButton, {
                        [classes.hidden]: success || loading,
                    })}
                    disabled={success || loading}
                    disableRipple
                    disableElevation>
                    <Typography variant='body1' color='inherit'>
                        <b>Skicka</b>
                    </Typography>
                </Button>
            </div>
        );
    };
    const textFields = () => {
        return (
            <>
                <div className={classes.inputWrapper}>
                    <div className={classes.label}>
                        <Label text='E-post' inheritColor />
                    </div>
                    <TextField
                        id='username'
                        autoComplete='username'
                        classes={{
                            base: classes.textFieldBase,
                            field: classes.textField,
                        }}
                        InputProps={{
                            classes: { root: classes.textFieldRoot },
                        }}
                        value={username || ''}
                        onChange={value => setUsername(value)}
                        placeholder='exempel@gmail.com'
                        onKeyPress={handleKeyPress}
                        variant='filled'
                        disabled={success}
                        white
                        noAutofillColor
                    />
                </div>
            </>
        );
    };

    return (
        <Wrapper loading={loading} isMobile={isMobile}>
            <div className={cx(classes.body)}>
                <WelcomeText
                    title='Har du glömt ditt lösenord?'
                    subtitle='Ange din e-post nedan för att få en återställningslänk skickad till dig.'
                />
                {textFields()}
                {success ? (
                    <div className={classes.successText}>
                        <Typography variant='body1'>
                            Ett mail har skickats till e-postadressen. Följ
                            anvisningarna i mailet för att återställa ditt
                            lösenord.
                        </Typography>
                    </div>
                ) : null}
                {loginButtons()}
            </div>
        </Wrapper>
    );
}
