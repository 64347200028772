import axios from './Axios';
import Api from './Api';
import { CancelTokenSource } from 'axios';
import { InsertTimesheetPayload } from 'components/interface/timesheet/typings';

export default class AssignmentApi {
    /**
     * Fetches assignments.
     * @param {JWT} token - The token of the account.
     * @param {bool} onlyCurrent - If only current assignments should be fetched
     * @param {string} orderattr - ASC for ascending order, and DESC for descending.
     * @param {string} orderby - What attribute to order by.
     * @returns  A Axios promise
     */
    fetchAssignments(
        token = undefined,
        onlyCurrent = true,
        limit = null,
        offset = null,
        orderBy = null,
        orderAttr = null,
        source?: CancelTokenSource
    ) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/assignment/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                onlyCurrent: onlyCurrent,
                limit: limit,
                offset: offset,
                orderBy: orderBy,
                orderAttr: orderAttr,
            },
            cancelToken: source?.token,
        });
    }

    /**
     * Fetches the assignment.
     * @param {JWT} token - The token of the account.
     * @param {string} uuid - the assignment's uuid
     * @returns  A Axios promise
     */
    fetchAssignment(token: string, uuid: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/assignment/${uuid}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {},
        });
    }

    /**
     * Fetches the stats between the place and staff.
     * @param {JWT} token - The token of the account.
     * @param {string} uuid - the assignment's uuid
     * @returns  A Axios promise
     */
    fetchAssignmentStats(token: string, uuid: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/assignment/${uuid}/stats`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {},
        });
    }

    /**
     * Fetches the timesheet of an assignment.
     * @param {JWT} token - The token of the account.
     * @param {string} uuid - the assignment's uuid
     * @returns  A Axios promise
     */
    fetchAssignmentTimesheet(token: string, uuid: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/assignment/${uuid}/timesheet`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {},
        });
    }

    /**
     * Updates the timesheet of an assignment.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    submitAssignmentTimesheet(
        token: string,
        uuid: string,
        payload: InsertTimesheetPayload
    ) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/assignment/${uuid}/timesheet`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            data: payload,
        });
    }

    /**
     * Fetches a token to generate a PDF for the timesheet.
     * @param {JWT} token - The token of the account.
     * @param {string} uuid - the assignment's uuid
     * @returns  A Axios promise
     */
    fetchAssignmentTimesheetPDF(token: string, uuid: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/assignment/${uuid}/timesheet/pdf`,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {},
        });
    }

    /**
     * Fetches a token to generate a PDF for the booking confirmation.
     * @param {JWT} token - The token of the account.
     * @param {string} uuid - the assignment's uuid
     * @returns  A Axios promise
     */
    fetchAssignmentBookingConfirmationPDF(token: string, uuid: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/me/assignment/${uuid}/booking_confirmation/pdf`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {},
        });
    }
}
