import React from 'react';
import { amber, green, grey, red } from '@mui/material/colors';
import Tooltip from 'components/interface/tooltip/Tooltip';
import { useStyles } from 'tss-react/mui';

export type MaterialSymbolProps = {
    symbol: string;
    fontSize?: 'small' | 'medium' | 'semi_large' | 'large' | 'very_large';
    color?:
        | 'primary'
        | 'secondary'
        | 'textPrimary'
        | 'textSecondary'
        | 'grey'
        | 'amber'
        | 'green'
        | 'error'
        | 'black'
        | 'red-dark'
        | 'white'
        | 'disabled'
        | 'inherit';
    tooltipText?: React.ReactNode;
    rounded?: boolean;
    classes?: string;
    iconPadding?: boolean;
    filled?: boolean;
};

export default function MaterialSymbol({
    symbol,
    fontSize = 'small',
    color,
    tooltipText,
    rounded = true,
    classes,
    iconPadding,
    filled,
}: MaterialSymbolProps) {
    const style: React.CSSProperties = {};
    const { cx, theme } = useStyles();

    if (fontSize === 'small') {
        style.fontSize = '20px';
    } else if (fontSize === 'medium') {
        style.fontSize = '24px';
    } else if (fontSize === 'semi_large') {
        style.fontSize = '32px';
    } else if (fontSize === 'large') {
        style.fontSize = '40px';
    } else if (fontSize === 'very_large') {
        style.fontSize = '75px';
    }
    style.userSelect = 'none';

    if (color === 'primary') {
        style.color = theme.palette.primary.main;
    } else if (color === 'secondary') {
        style.color = theme.palette.secondary.main;
    } else if (color === 'textPrimary') {
        style.color = theme.palette.text.primary;
    } else if (color === 'textSecondary') {
        style.color = theme.palette.text.secondary;
    } else if (color === 'grey') {
        style.color = grey[700];
    } else if (color === 'amber') {
        style.color = amber[900];
    } else if (color === 'green') {
        style.color = green[700];
    } else if (color === 'red-dark') {
        style.color = red[900];
    } else if (color === 'error') {
        style.color = theme.palette.error.main;
    } else if (color === 'black') {
        style.color = 'black';
    } else if (color === 'white') {
        style.color = '#fff';
    } else if (color === 'inherit') {
        style.color = 'inherit';
    } else if (color === 'disabled') {
        style.color = theme.palette.text.hint;
    }

    if (iconPadding) style.padding = '8px';

    if (filled) style.fontVariationSettings = "'FILL' 1";

    const icon = () => (
        <span
            className={cx(
                rounded
                    ? 'material-symbols-rounded'
                    : 'material-symbols-outlined',
                classes
            )}
            style={style}>
            {symbol}
        </span>
    );

    return tooltipText ? (
        <Tooltip title={tooltipText}>{icon()}</Tooltip>
    ) : (
        icon()
    );
}
