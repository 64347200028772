import React from 'react';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { isValid, format } from 'date-fns';
import TextField, {
    TextFieldProps,
} from 'components/interface/textField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DateIcon from '@mui/icons-material/EventOutlined';
import { extractNumber } from 'utils/utils';
import { PopperProps } from '@mui/material';

interface DatePickerProps
    extends Omit<TextFieldProps, 'value' | 'onChange' | 'onError'> {
    value?: string | Date;
    // only triggered when there is a valid date, both in string and date format
    // string format is YYYY-MM-DD without the time
    onChange: (s: string, d: Date) => void;
    onError?: (b: boolean) => void;
    className?: string;

    headless?: boolean; // if no textfield should be rendered.
    open?: boolean; // controls if the picker is open or not, only works with headless
    onClose?: () => void; // only needed in heeadless mode
    PopperProps?: Partial<PopperProps>; // in headless mode you must set the anchorEl
}

type Caret = {
    selectionStart: number;
    selectionEnd: number;
};

export const DatePicker = ({
    value,
    onChange,
    onError,
    className: classNameProp,
    disabled,
    headless,
    open: openProp,
    onClose,
    PopperProps,
    ...rest
}: DatePickerProps): JSX.Element => {
    const [_date, setDate] = React.useState<Date | null>(null); // internal state as a Date
    const [_text, setText] = React.useState<string>(''); // internal state in string format
    const [open, setOpen] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const [cursor, setCursor] = React.useState(0);
    const [forceUpdate, setForceUpdate] = React.useState(false);
    const dateRef = React.useRef(null);
    const firstRender = React.useRef(true);
    const caretRef = React.useRef<Caret>({
        selectionStart: 0,
        selectionEnd: 0,
    });

    React.useEffect(() => {
        setDateFromValue();
    }, []);

    const setDateFromValue = () => {
        if (typeof value === 'string') {
            const parsed = new Date(value);
            if (parsed && isValid(parsed)) {
                setDate(parsed);
                setText(value);
            }
        } else if (value && isValid(value)) {
            setDate(value);
            setText(dateToString(value));
        }
    };

    React.useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        if (!!caretRef.current) {
            caretRef.current.selectionStart = cursor;
            caretRef.current.selectionEnd = cursor;
        }
        if (forceUpdate) {
            setForceUpdate(false);
        }

        const parsed = new Date(_text);
        if (parsed && isValid(parsed) && _text.length >= 9) {
            onChange(_text, parsed);
            setDate(parsed);
            setError(false);
        } else {
            if (_text.length > 0) {
                setError(true);
            } else {
                setError(false);
            }
        }
    }, [_text, forceUpdate]);

    React.useEffect(() => {
        if (onError) {
            onError(error);
        }
    }, [error]);

    React.useEffect(() => {
        if (headless) {
            setOpen(openProp || false);
            // this is so we can update the date in headless mode
            if (openProp) {
                setDateFromValue();
            }
        }
    }, [openProp]);

    const handleSetOpen = (value: boolean) => {
        if (onClose && value === false) {
            onClose();
        }
        if (headless) {
            return;
        }
        setOpen(value);
    };

    const onBlur = () => {
        setText(formatToDate(_text, true));
    };

    // Formats the string to the yyyy-mm-dd format if the input is valid, otherwise it returns the string.
    const formatToDate = (s: string, insertZero: boolean) => {
        let v = s.replaceAll('-', '').trim();
        if (v.length === 7 && v[6] !== '0' && insertZero) {
            v = v.slice(0, 6) + 0 + v[6]; //turns 2022012 => 20220102
        }

        if (v.length > 6) {
            return v.slice(0, 4) + '-' + v.slice(4, 6) + '-' + v.slice(6, 8);
        } else if (v.length > 4) {
            return v.slice(0, 4) + '-' + v.slice(4, 6);
        }
        return v;
    };

    const dateToString = (d: Date): string =>
        d && isValid(d) ? format(d, 'yyyy-MM-dd') : '';

    const handleChange = (
        v: string,
        _e: React.ChangeEvent<HTMLInputElement>
    ) => {
        let pos = caretRef.current.selectionStart;

        // If the new position is 5, which is 2022-|01-02
        // Move it to position 6, which is 2022-0|1-02
        // If the new position is 8, which is 2022-01-|02
        // Move it to position 9, which is 2022-01-0|2
        if (pos === 5) {
            pos = 6;
        } else if (pos === 8) {
            pos = 9;
        }

        let newText = v;
        const e = _e.nativeEvent as InputEvent;

        // If there is a simply input of text then replace the character of the current cursor position
        // Only if the date is full, otherwise push the numbers as usual.
        if (e.inputType === 'insertText' && _text.length >= 10) {
            const newInput = extractNumber(e.data || '', 0);
            if (newInput.length > 0) {
                let index = pos - 1;
                newText =
                    _text.slice(0, index) +
                    newInput +
                    _text.slice(index + newInput.length || 0);
            } else {
                // If nothing is written, revert the position of the cursor
                pos -= 1;
            }
        }

        newText = formatToDate(extractNumber(newText, 0), false);
        setText(newText);
        setCursor(pos);
        // If the overwritten character is the same as the previous, the text will not update which means that we need to
        // force update the caret position.
        if (newText === _text) {
            setForceUpdate(true);
        }
    };

    return (
        <div className={classNameProp}>
            {headless ? null : (
                <TextField
                    {...rest}
                    ref={dateRef}
                    inputRef={caretRef}
                    value={_text}
                    onChange={handleChange}
                    onBlur={onBlur}
                    error={error}
                    placeholder={'yyyy-mm-dd'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => handleSetOpen(true)}
                                    disabled={disabled}>
                                    <DateIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    helperText={error ? 'Felaktigt format' : ''}
                    disabled={disabled}
                />
            )}
            <MUIDatePicker
                renderInput={() => null as any}
                open={open}
                onOpen={() => handleSetOpen(true)}
                onClose={() => handleSetOpen(false)}
                value={_date}
                onChange={v => {
                    if (v && isValid(v)) {
                        setText(dateToString(v));
                    }
                    setDate(v);
                }}
                PopperProps={{
                    anchorEl: dateRef.current,
                    ...PopperProps,
                }}
            />
        </div>
    );
};
