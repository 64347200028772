import React from "react";
import {
    DetailsTable,
    DetailsRow,
} from "components/interface/DetailsTable/DetailsTable";

export default function StatsComponent({ stats, isStatsLoading }) {
    return (
        <DetailsTable title="Statistik hos vårdgivare" isMobile>
            <DetailsRow
                name={"Antal uppdrag"}
                value={stats?.totalAssignments + " st"}
                tooltipText={"Inkluderar alla uppdrag"}
                tooltipPlacement="top"
                loading={isStatsLoading}
                isMobile
            />
            <DetailsRow
                name={"Antal avslutade uppdrag"}
                value={stats?.finishedAssignments + " st"}
                tooltipText={"Inkluderar uppdrag med status 'Avslutad'"}
                tooltipPlacement="top"
                loading={isStatsLoading}
                noSelect
                isMobile
            />
        </DetailsTable>
    );
}
