import React, {  } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import 'utils/dateutils.js';

const useStyles = makeStyles()((theme, params, classes) => ({
    base: {
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    fullWidth: {
        width: '100%',
    },
}));

export default function CurrentWeek({ fullWidth }) {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.base, {[classes.fullWidth]: fullWidth})}>
            <Typography variant="body2" color="textPrimary">
                Vecka: <b>{(new Date().getWeek())}</b>
            </Typography>
        </div>
    );
}
CurrentWeek.propTypes = {
    fullWidth: PropTypes.bool,
}