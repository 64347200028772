import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Timesheet,
    TravelsheetKeyType,
    TIMESHEET_SETTING,
    TimesheetDay,
} from '../typings';
import NotFound from 'components/interface/NotFound/NotFound';
import TravelsheetComponent from './Travelsheet';
import Spinner from 'react-spinkit';
import BottomNavigation from 'components/interface/bottomNavigation/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import CarIcon from '@mui/icons-material/DirectionsCarOutlined';
import IconButton from '@mui/material/IconButton';
import ArrowIcon from '@mui/icons-material/ArrowBackIosNew';
import Typography from '@mui/material/Typography';
import { WEEKDAY } from '../utils';
import TriStateSwitch from 'components/interface/triStateSwitch/TriStateSwitch';
import TextField from 'components/interface/textField/TextField';
import { Skeleton } from '@mui/material';
import StartStopTimesheet from './StartStopTimesheet';
import NormalTimesheet from './NormalTimesheet';
import { ProfessionIdentifier } from 'typings/titles';
import Alert, { STATUS } from 'components/interface/alert/Alert';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

const useStyles = makeStyles()(theme => ({
    spinner: {
        width: '75px !important',
        height: '75px !important',
        marginTop: theme.spacing(2),
    },
    selector: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flexWrap: 'wrap',
    },
    dateTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    flip: {
        transform: 'scale(-1, -1)',
    },
    enableSickHours: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1),
    },
    footer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    comment: {
        marginBottom: theme.spacing(4),
    },
}));

export interface TimesheetProps {
    timesheet: Timesheet | null;
    timesheetSetting: TIMESHEET_SETTING;
    canSubmit: boolean;
    useSplitTextfields: boolean;
    allowTravelsheet: boolean;
    loading: boolean;
    success: boolean;
    error: boolean;
    enableSickHours: boolean;
    onEnableSickHours: (value: boolean) => void;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
    onTravelsheetFieldChange: (
        key: TravelsheetKeyType,
        index: number
    ) => (value: string) => void;
    onNotesFieldChange: (value: string) => void;

    commentContainsSickHints: boolean;
    sickHoursDuplicateDays: TimesheetDay[];
}

const View = ({
    timesheet,
    timesheetSetting,
    canSubmit,
    useSplitTextfields,
    allowTravelsheet,
    loading,
    error,
    enableSickHours,
    onEnableSickHours,
    onUpdateTimesheetDay,
    onTravelsheetFieldChange,
    onNotesFieldChange,

    commentContainsSickHints,
    sickHoursDuplicateDays,
}: TimesheetProps): JSX.Element => {
    const { classes } = useStyles();
    const [activePage, setActivePage] = React.useState<1 | 2>(1);
    const [selectedDay, setSelectedDay] = React.useState<number>(0);
    const isSSK =
        timesheet?.template?.profession?.identifier ===
        ProfessionIdentifier.Nurse;
    const allowSickHours = isSSK && canSubmit;

    if (timesheet === null) {
        return <></>;
    }

    const getNextDayIndex = (current: number): number => {
        for (let i = current + 1; i < 8; i++) {
            if (timesheet.days[i]) {
                return i;
            }
        }
        return current;
    };

    const getPrevDayIndex = (current: number): number => {
        for (let i = current - 1; i >= 0; i--) {
            if (timesheet.days[i]) {
                return i;
            }
        }
        return current;
    };

    const isNextArrowDisabled = () =>
        getNextDayIndex(selectedDay) === selectedDay;
    const isPrevArrowDisabled = () =>
        getPrevDayIndex(selectedDay) === selectedDay;

    const handleIncrementSelector = () =>
        setSelectedDay(prev => getNextDayIndex(prev));

    const handleDecrementSelector = () =>
        setSelectedDay(prev => getPrevDayIndex(prev));

    const handlePageChange = (_: any, newValue: number) => {
        if (newValue !== 1 && newValue !== 2) {
            return;
        }
        setActivePage(prev => {
            if (newValue !== prev) {
                window.scrollTo(0, 0);
            }
            return newValue;
        });
    };

    const renderBottomNav = () => {
        return (
            <BottomNavigation value={activePage} onChange={handlePageChange}>
                <BottomNavigationAction
                    label='Tidrapport'
                    value={1}
                    icon={<AssignmentIcon />}
                />
                <BottomNavigationAction
                    label='Reseräkning'
                    value={2}
                    icon={<CarIcon />}
                />
            </BottomNavigation>
        );
    };

    const renderSelector = (): React.ReactNode => {
        const day = timesheet.days[selectedDay];
        if (!day) {
            return null;
        }

        return (
            <div className={classes.selector}>
                <IconButton
                    disabled={isPrevArrowDisabled()}
                    onClick={handleDecrementSelector}>
                    <ArrowIcon />
                </IconButton>
                <div className={classes.dateTextWrapper}>
                    <Typography
                        variant='h6'
                        color={
                            day?.holiday?.IsPublicHoliday
                                ? 'error'
                                : 'textPrimary'
                        }>
                        {`${WEEKDAY[day.weekday]} ${day.day}/${day.month}`}
                    </Typography>
                    {day?.holiday?.Name ? (
                        <Typography
                            variant='caption'
                            color={
                                day?.holiday?.IsPublicHoliday
                                    ? 'error'
                                    : 'textPrimary'
                            }>
                            <b>
                                {day?.holiday?.Name +
                                    (day?.holiday?.IsMajorHoliday
                                        ? ' (storhelg)'
                                        : '')}
                            </b>
                        </Typography>
                    ) : null}
                </div>

                <IconButton
                    disabled={isNextArrowDisabled()}
                    onClick={handleIncrementSelector}>
                    <ArrowIcon className={classes.flip} />
                </IconButton>
            </div>
        );
    };

    const renderTimesheet = () => {
        return (
            <>
                {renderSelector()}
                {allowSickHours && (
                    <div className={classes.enableSickHours}>
                        <TriStateSwitch
                            label='Rapporera sjuktimmar'
                            value={enableSickHours || false}
                            onChange={v => onEnableSickHours(!!v)}
                            disabled={!canSubmit}
                            disableNull
                        />
                    </div>
                )}
                {sickHoursDuplicateDays.length > 0 && (
                    <Alert
                        status={STATUS.WARNING}
                        text={
                            <>
                                Det verkar som att du har rapporterat sjuktimmar
                                som är lika med dina normala timmar. Om du har
                                varit sjuk behöver du inte rapportera
                                sjuktimmarna under arbetade timmar. Påverkade
                                dagar:
                                {sickHoursDuplicateDays.map((day, i) => (
                                    <i key={i}>
                                        <br />
                                        <b>
                                            {format(
                                                new Date(day.date),
                                                'EEEE',
                                                {
                                                    locale: sv,
                                                }
                                            )}
                                        </b>
                                    </i>
                                ))}
                            </>
                        }
                        style={{ marginBottom: 16 }}
                        fullWidth
                    />
                )}
                {timesheetSetting === TIMESHEET_SETTING.START_STOP_TIME ? (
                    <StartStopTimesheet
                        day={timesheet.days[selectedDay]}
                        dayIndex={selectedDay}
                        totalHours={timesheet.totalHours}
                        canSubmit={canSubmit}
                        loading={loading}
                        enableSickHours={enableSickHours}
                        onUpdateTimesheetDay={onUpdateTimesheetDay}
                    />
                ) : (
                    <NormalTimesheet
                        day={timesheet.days[selectedDay]}
                        dayIndex={selectedDay}
                        template={timesheet.template}
                        useSplitTextfields={useSplitTextfields}
                        canSubmit={canSubmit}
                        loading={loading}
                        enableSickHours={enableSickHours}
                        onUpdateTimesheetDay={onUpdateTimesheetDay}
                    />
                )}
                <Typography
                    className={classes.footer}
                    variant='subtitle2'
                    {...(loading
                        ? {}
                        : {
                              dangerouslySetInnerHTML: {
                                  __html:
                                      timesheet?.template?.footnote?.text || '',
                              },
                          })}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='300px'
                        />
                    ) : null}
                </Typography>
                {commentContainsSickHints &&
                    allowSickHours &&
                    !enableSickHours && (
                        <Alert
                            status={STATUS.WARNING}
                            text='Det verkar som att du har skrivit något om sjukdom i din kommentar. Om du har varit sjuk under veckan, glöm inte att rapportera sjuktimmar. Det gör du genom att klicka på "Ja" vid "Rapportera sjuktimmar" högst upp på skärmen.'
                            style={{ marginBottom: 16 }}
                            fullWidth
                        />
                    )}
                <TextField
                    label='Kommentar'
                    multiline
                    value={timesheet?.notes || ''}
                    className={classes.comment}
                    onChange={onNotesFieldChange}
                    variant='outlined'
                    disabled={!canSubmit}
                    fullWidth
                />
            </>
        );
    };

    const renderTravelsheet = () => {
        if (allowTravelsheet && timesheet?.travelsheet) {
            return (
                <TravelsheetComponent
                    travelsheet={timesheet.travelsheet}
                    canSubmit={canSubmit}
                    onTravelsheetFieldChange={onTravelsheetFieldChange}
                />
            );
        } else {
            return (
                <Typography variant='h5' color='textSecondary'>
                    Uppdraget har ej reseräkning
                </Typography>
            );
        }
    };

    const renderActivePage = () => {
        if (!allowTravelsheet) {
            return renderTimesheet();
        }
        switch (activePage) {
            case 1:
                return renderTimesheet();
            case 2:
                return renderTravelsheet();
        }
    };

    const renderPage = () => (
        <>
            {renderActivePage()}
            {allowTravelsheet && timesheet?.travelsheet
                ? renderBottomNav()
                : null}
        </>
    );

    return error ? (
        <NotFound variant='error' isMobile={false} />
    ) : loading ? (
        <Spinner name='circle' fadeIn='none' className={classes.spinner} />
    ) : (
        renderPage()
    );
};

export default View;
