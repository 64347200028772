import { styled, Typography } from '@mui/material';
import React from 'react';
import { Answer, Question } from './typings';
import { grey } from '@mui/material/colors';

const Base = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const NumericScaleWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: `1px solid ${grey[300]}`,
    borderRadius: '8px',
    overflow: 'hidden',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
}));

const NumericScaleOption = styled('div', {
    shouldForwardProp: prop => prop !== '$selected',
})<{
    $selected: boolean;
}>(({ $selected }) => ({
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'height': '40px',
    'width': '40px',
    '&:hover': {
        backgroundColor: grey[300],
    },
    'cursor': 'pointer',
    'border': $selected ? `3px solid ${grey[800]}` : 'none',
    'borderRadius': '50%',
}));

interface NumericScaleTypeProps {
    question: Question;
    answer: Answer;
    onChange: (updateFunc: (prev: Answer) => Answer) => void;
}

export default function NumericScaleType({
    answer,
    onChange,
}: NumericScaleTypeProps) {
    const a = answer.answer as number;

    const handleOptionClick = (option: number) => {
        onChange(prev => ({
            ...prev,
            answer: option,
        }));
    };

    const scale = [1, 2, 3, 4, 5];

    return (
        <Base>
            <NumericScaleWrapper>
                {scale.map(option => (
                    <NumericScaleOption
                        key={option}
                        $selected={a === option}
                        onClick={() => handleOptionClick(option)}>
                        <Typography
                            variant='body2'
                            color={
                                a === option ? 'textPrimary' : 'textSecondary'
                            }
                            fontFamily='Poppins'
                            fontWeight={a === option ? 700 : 400}>
                            {option}
                        </Typography>
                    </NumericScaleOption>
                ))}
            </NumericScaleWrapper>
        </Base>
    );
}
