import React from 'react';
import {
    DetailsTable,
    DetailsRow,
} from 'components/interface/DetailsTable/DetailsTable';
import { Typography } from '@mui/material';

export default function StatsComponent({
    data,
    totalReportedDays,
    totalReportedTime,
    isTimereportLoading,
    renderTimesheetButton,
}) {
    return (
        <>
            <DetailsTable title='Tidrapport' isMobile>
                <DetailsRow
                    name={'Totalt antal timmar'}
                    value={totalReportedTime}
                    loading={isTimereportLoading}
                    noSelect
                    isMobile
                />
                <DetailsRow
                    name={'Antal rapporterade dagar'}
                    value={totalReportedDays}
                    loading={isTimereportLoading}
                    noSelect
                    isMobile
                />
                <DetailsRow
                    name={'Status'}
                    value={
                        !!data?.timesheet?.date ? 'Inskickad' : 'Ej inskickad'
                    }
                    loading={isTimereportLoading}
                    noSelect
                    isMobile
                />
            </DetailsTable>
            {!!data?.timesheet?.date && (
                <Typography
                    style={{ paddingTop: '16px' }}
                    variant='body2'
                    color='textPrimary'>
                    <b>
                        Gå till fliken dokument för att ladda ned tidrapporten
                        som PDF.
                    </b>
                </Typography>
            )}
            <div style={{ paddingTop: '16px', width: '100%' }}>
                {renderTimesheetButton()}
            </div>
        </>
    );
}
