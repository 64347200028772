import { styled } from '@mui/material';
import { ReactComponent as BankIDLogoSVG } from 'assets/BankID_logo_no_padding_white.svg';
import { ReactComponent as BankIDLogoColorSVG } from 'assets/BankID_logo_no_padding.svg';

const BankIDLogo = styled(BankIDLogoSVG)({
    height: '26px',
    marginRight: '13px', // has to be halv of size according to BankID spec https://www.bankid.com/utvecklare/varumaerkesregler/varumaerke-logotypen
});

const BankIDLogoColor = styled(BankIDLogoColorSVG)({
    height: '26px',
    marginRight: '13px', // has to be halv of size according to BankID spec https://www.bankid.com/utvecklare/varumaerkesregler/varumaerke-logotypen
});

export { BankIDLogo, BankIDLogoColor };
