import React from 'react';
import App from 'pages/App';
import LoginView from 'components/landingPage/login/View';
import useDocumentTitle from 'components/hooks/useDocumentTitle';
import useWindowWidth from 'components/hooks/useWindowWidth';
import { useLocation } from 'react-router-dom';
import { LocationState } from 'Routes';

export default function LoginPage() {
    useDocumentTitle('Logga in');
    const { isMobile } = useWindowWidth();
    const location = useLocation<LocationState>();
    return (
        <App>
            <LoginView
                redirectedFrom={location?.state?.redirectedFrom}
                isMobile={isMobile}
            />
        </App>
    );
}
