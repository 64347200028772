import React from 'react';
import HTTPStatusInterceptor from './HTTPStatusInterceptor';
import { Redirect } from 'react-router-dom';
import { authData } from 'Routes';
import { useLocation } from 'react-router-dom';
import { LocationState } from 'Routes';

interface Props {
    children: JSX.Element;
    auth: () => authData;
}

export const RequiresLoggedOut = ({ children, auth }: Props) => {
    const { isAuthed } = auth();
    const location = useLocation<LocationState>();
    const params = new URLSearchParams(location.search);
    let redirectedFrom = params.get('redirectedFrom');
    redirectedFrom = redirectedFrom === '/logga-ut' ? null : redirectedFrom;
    const pathname = redirectedFrom?.split('?')[0] || '';
    const search = redirectedFrom?.split('?')[1] || '';

    return !isAuthed ? (
        <HTTPStatusInterceptor>{children}</HTTPStatusInterceptor>
    ) : (
        <Redirect
            exact
            to={{
                pathname: pathname || '/',
                search: search ? `?${search}` : undefined,
            }}
        />
    );
};