import React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, {
    TextFieldProps,
} from 'components/interface/textField/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface PasswordFieldProps extends TextFieldProps {
    iconClassName?: string;
}

export default function PasswordField({
    iconClassName,
    ...props
}: PasswordFieldProps) {
    const [show, setShow] = React.useState(false);

    return (
        <TextField
            {...props}
            type={show ? 'text' : 'password'}
            InputProps={{
                ...props.InputProps,
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label='Toggle password visibility'
                            onClick={() => setShow(prev => !prev)}>
                            {show ? (
                                <VisibilityOff className={iconClassName} />
                            ) : (
                                <Visibility className={iconClassName} />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
