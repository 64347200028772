import { create } from 'store/create';

export enum NotificationTypes {
    success = 'success',
    warning = 'warning',
    error = 'error',
    info = 'info',
};


type Store = {
    notifications: Array<Notification>;
    push: (
        type: NotificationTypes,
        message: string,
        group: string,
        priority: number,
    ) => void
    pop: () => void
}

type Notification = {
    type: NotificationTypes;
    message: string;
    group: string;
    priority: number;
    timestamp: number;
    shownTimestamp?: number;
}


const useNotificationStore = create<Store>(set => ({
    notifications: [],
    push: (type: NotificationTypes, message: string, group: string, priority: number) =>
        set(state => {
            const payload = {
                type,
                message,
                group,
                priority,
                timestamp: new Date().getTime(),
            };
            // If there is no notifications in the queue, push it an set the shownTimestamp
            if (state.notifications.length === 0) {
                return {
                    notifications: [
                        {
                            ...payload,
                            shownTimestamp: new Date().getTime(),
                        },
                    ],
                };
            }

            const i = state.notifications.findIndex(v => v.group === group);
            if (i >= 0) {
                // If a notifcation exists within the group, check if the new notification has higher priority (lower number)
                if (state.notifications[i].priority >= priority) {
                    // Replace the old notification with the new one.
                    const head = state.notifications.slice(0, i);
                    const tail = state.notifications.slice(i + 1);
                    return {
                        notifications: [...head, { ...payload }, ...tail],
                    };
                } else {
                    return {
                        notifications: [...state.notifications],
                    };
                }
            } else {
                return {
                    notifications: [...state.notifications, { ...payload }],
                };
            }
        }),
    pop: () =>
        set(state => {
            let newArr = [...state.notifications.slice(1)];
            if (newArr.length > 0) {
                const [head, ...rest] = newArr;
                newArr = [
                    { ...head, shownTimestamp: new Date().getTime() },
                    ...rest,
                ];
            }
            return {
                notifications: newArr,
            };
        }),
}));

export default useNotificationStore;
