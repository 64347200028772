import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Button from 'components/interface/Button/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles<void, 'sticky'>()((theme, _params, classes) => ({
    root: {
        display: 'flex',
        width: '100%',
        height: 'calc(55px + env(safe-area-inset-top, 0px))',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10,
        background: theme.palette.background.backgroundColor,
        paddingLeft:
            'calc(' + theme.spacing(1) + ' + env(safe-area-inset-left, 0px))',
        paddingRight:
            'calc(' + theme.spacing(1) + ' + env(safe-area-inset-right, 0px))',
        paddingTop: 'env(safe-area-inset-top, 0px)',
        //marginBottom: theme.spacing(3),
        userSelect: 'none',
        flexShrink: 0,
        [`&.${classes.sticky}`]: {
            height: '55px',
            paddingTop: 0,
            position: 'sticky',
        },
    },
    sticky: {},
    spacer: {
        width: '100%',
        minHeight: '55px',
    },
    actionsLeft: {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'flex-start',
        'width': '100%',
        '& span': {
            color: theme.palette.text.primary,
        },
    },
    title: {
        paddingLeft: theme.spacing(1),
    },
    actionsRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexShrink: '0',
    },
}));

interface MiniNavProps {
    title: string;
    clearButton?: boolean;
    saveFunc?: () => void;
    saveText?: string;
    clearFunc?: () => void;
    forceGoBack?: boolean;
    hideClearButton?: boolean;
    sticky?: boolean;
    disabled?: boolean;
}

export default function MiniNav({
    title,
    clearButton,
    saveFunc,
    saveText,
    clearFunc,
    forceGoBack,
    hideClearButton,
    sticky,
    disabled,
}: MiniNavProps) {
    const history = useHistory();
    const { classes, cx } = useStyles();

    const goBack = () => {
        // If there is no history, push the root location and go there.
        if (history.length < 2) {
            history.push('/');
        } else {
            history.goBack();
        }
    };

    const renderSaveButton = () => {
        if (saveText) {
            return (
                <Button
                    color='primary'
                    variant='text'
                    onClick={saveFunc}
                    upperCase
                    disabled={disabled}>
                    {saveText}
                </Button>
            );
        } else {
            return (
                <IconButton onClick={saveFunc}>
                    <Icon color='primary'>check</Icon>
                </IconButton>
            );
        }
    };

    const onClear = () => {
        if (!!forceGoBack) {
            if (!!clearFunc) {
                clearFunc();
            }
            goBack();
        } else {
            if (!!clearFunc) {
                clearFunc();
            } else {
                goBack();
            }
        }
    };

    return (
        <>
            <div className={cx(classes.root, { [classes.sticky]: sticky })}>
                <div className={classes.actionsLeft}>
                    {!!hideClearButton ? undefined : (
                        <IconButton onClick={onClear}>
                            <Icon>{clearButton ? 'close' : 'arrow_back'}</Icon>
                        </IconButton>
                    )}
                    <Typography
                        className={classes.title}
                        variant='h6'
                        color='textPrimary'>
                        {title}
                    </Typography>
                </div>
                <div className={classes.actionsRight}>
                    {saveFunc ? renderSaveButton() : undefined}
                </div>
            </div>
            {!sticky ? <div className={classes.spacer} /> : undefined}
        </>
    );
}
