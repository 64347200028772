import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { ReactComponent as NotFoundSVG } from 'assets/not_found.svg';
import { ReactComponent as UnderConstructionSVG } from 'assets/under_construction.svg';
import { ReactComponent as NotAuthorizedSVG } from 'assets/401.svg';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme) => ({
    base: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        flexShrink: 0,
    },
    desktop: {
        maxWidth: '600px',
        padding: theme.spacing(4),
    },
    mobile: {
        padding: theme.spacing(2),
    },
    svg: {
        width: '100%',
        height: 'auto',
        marginBottom: theme.spacing(4),
    },
    weightBold: {
        fontWeight: '700',
    },
    underlined: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

interface Props {
    className?: string;
    variant: 'normal' | 'error' | 'construction' | '401';
    title?: string;
    body?: JSX.Element | string;
    isMobile: boolean;
};

export default function NotFound({
    className,
    variant,
    title,
    body,
    isMobile,
    ...rest
}: Props): JSX.Element {
    const { classes, cx } = useStyles();
    let Component = NotFoundSVG;
    if (variant === 'error') {
        title = 'Hoppsan, något gick fel…';
        body = (
            <span>
                {'Kontrollera din internetuppkoppling och '}
                <span
                    onClick={() => window.location.reload()}
                    className={classes.underlined}>
                    {'försök igen.'}
                </span>
            </span>
        );
    } else if (variant === 'construction') {
        title = 'Hej, sidan  är under konstruktion.';
        body = (
            <span>
                {'Navigera tillbaka till portalen via menyn till vänster.'}
            </span>
        );
        Component = UnderConstructionSVG;
    } else if (variant === '401') {
        title = '401';
        body = <span>{'Du saknar behörighet för att se denna sida.'}</span>;
        Component = NotAuthorizedSVG;
    }
    return (
        <div
            className={cx(
                classes.base,
                className,
                { [classes.desktop]: !isMobile },
                { [classes.mobile]: isMobile }
            )}>
            <Component className={cx(classes.svg)} {...rest} />
            <Typography variant='h6' className={classes.weightBold}>
                {title}
            </Typography>
            <Typography variant='body1'>{body}</Typography>
        </div>
    );
}
