import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingGrid } from 'components/interface/Loading';
import App from 'pages/App';
import Page from 'components/Page';
import Assignment from 'components/Content/assignment/page/Assignment';
import useDocumentTitle from 'components/hooks/useDocumentTitle';

export default function AssignmentPage() {
    useDocumentTitle('Uppdrag');
    const { id } = useParams();
    return (
        <App>
            <Page>
                <Suspense fallback={<LoadingGrid />}>
                    {id ? <Assignment assignmentID={id} /> : <LoadingGrid />}
                </Suspense>
            </Page>
        </App>
    );
}
