import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Button from "components/interface/Button/Button";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import FooterMobile from "components/Footer/FooterMobile";
import UserAvatar from "components/interface/UserAvatar/UserAvatar";
import Logo from "components/interface/Logo/Logo";
import ButtonBase from "@mui/material/ButtonBase";
import { matchPath, useLocation } from "react-router-dom";
import clsx from "clsx";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLateOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToAppOutlined";
import { isMobile } from "react-device-detect";
import InstallBanner from "../installBanner";
import UpdateBanner from "../updateBanner";
import useInstallerStore from 'store/useInstallerStore';

const useStyles = makeStyles()((theme, params, classes) => ({
    base: {
        width: "100%",
        minHeight: "calc(70px + env(safe-area-inset-top, 0px))",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.background.backgroundColor,
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "100",
        flexShrink: 0,
        paddingLeft: "env(safe-area-inset-left, 0px)",
        paddingTop: "env(safe-area-inset-top, 0px)",
    },
    spacer: {
        width: "100%",
        minHeight: "70px",
    },
    header: {
        width: "100%",
        height: "70px",
        minHeight: "70px",
        maxHeight: "70px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1, 2),
    },
    button: {
        borderRadius: "50px",
        minWidth: "0px",
    },
    paper: {
        width: "70%",
        maxWidth: "calc(300px + env(safe-area-inset-left, 0px))",
    },
    drawer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        justifyContent: "space-between",
        backgroundColor: theme.palette.background.backgroundColor,
        paddingBottom: "env(safe-area-inset-top, 0px)",
    },
    drawerHeader: {
        width: "100%",
        height: "calc(172px + env(safe-area-inset-top, 0px))",
        background: theme.palette.secondary[500] + " url(/images/pattern.png)",
        backgroundSize: "contain",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: theme.spacing(1, 2),
        paddingTop:
            "max(" + theme.spacing(2) + ", env(safe-area-inset-top, 0px))",
        paddingLeft:
            "calc(" + theme.spacing(2) + " + env(safe-area-inset-left, 0px))",
    },
    userWrapper: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: theme.spacing(4, 2),
    },
    menuList: {
        width: "100%",
        padding: theme.spacing(1, 2),
        paddingLeft:
            "calc(" + theme.spacing(2) + " + env(safe-area-inset-left, 0px))",
    },
    remoteLogin: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(1),
    },
    logo: {
        "& path": {
            color: theme.palette.primaryDark.main,
            fill: theme.palette.primaryDark.main,
        },
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const useStylesMenuItem = makeStyles()((theme, params, classes) => ({
    base: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "48px",
        width: "100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [`&.${classes.active}`]: {
            backgroundColor: theme.palette.primary[100],
        },
    },
    icon: {
        [`&.${classes.active}`]: {
            color: theme.palette.primaryDark[500],
        },
    },
    title: {
        paddingLeft: theme.spacing(2),
        [`&.${classes.active}`]: {
            color: theme.palette.primaryDark[500],
        },
    },
    active: {},
}));

const MenuItem = ({ title, icon: Icon, link, onClick }) => {
    const { classes, cx } = useStylesMenuItem();
    const location = useLocation();
    const active = !!matchPath(location.pathname, { path: link, exact: true });
    return (
        <NavLink exact to={link}>
            <ButtonBase
                className={clsx(classes.base, { [classes.active]: active })}
                onClick={onClick}
            >
                <Icon
                    className={clsx(classes.icon, { [classes.active]: active })}
                    color="inherit"
                    fontSize="small"
                />
                <Typography
                    className={clsx(classes.title, {
                        [classes.active]: active,
                    })}
                    variant="body2"
                    color="textPrimary"
                >
                    <b>{title}</b>
                </Typography>
            </ButtonBase>
        </NavLink>
    );
};

export default function MobileMenu({ me, puppeteer }) {
    const { classes, cx } = useStyles();
    const theme = useTheme();
    const headerRef = useRef(null);
    const showInstallBanner = useInstallerStore(state => state.show);

    const [open, setOpen] = useState(false);
    const [bannerIsOpen, setBannerIsOpen] = useState(false);

    const handleSideDrawerToggle = (open) => {
        setOpen(open);
    };

    const renderSideSwipeableDrawer = () => {
        return (
            <SwipeableDrawer
                open={open}
                onClose={() => handleSideDrawerToggle(false)}
                onOpen={() => handleSideDrawerToggle(true)}
                classes={{ paper: classes.paper }}
                swipeAreaWidth={20}
            >
                <div className={classes.drawer}>
                    <div className={classes.drawerContent}>
                        <div className={classes.drawerHeader}>
                            <Logo
                                className={classes.logo}
                                variant="normal"
                                isSidedrawer
                            />
                            <UserAvatar me={me} puppeteer={puppeteer} isMobile />
                        </div>
                        <div className={classes.menuList}>
                            <MenuItem
                                title="Aktuella uppdrag"
                                icon={AssignmentLateIcon}
                                link="/uppdrag"
                                onClick={() => handleSideDrawerToggle(false)}
                            />
                            <MenuItem
                                title="Alla uppdrag"
                                icon={AssignmentTurnedInIcon}
                                link="/uppdrag/alla"
                                onClick={() => handleSideDrawerToggle(false)}
                            />
                            <MenuItem
                                title="Inställningar"
                                icon={SettingsIcon}
                                link="/min-profil"
                                onClick={() => handleSideDrawerToggle(false)}
                            />
                            <MenuItem
                                title="Logga ut"
                                icon={ExitToAppIcon}
                                link="/logga-ut"
                                onClick={() => handleSideDrawerToggle(false)}
                            />
                        </div>
                    </div>
                    <FooterMobile />
                </div>
            </SwipeableDrawer>
        );
    };

    return (
        <>
            <div ref={headerRef} className={classes.base}>
                {showInstallBanner && isMobile ? (
                    <InstallBanner setBannerIsOpen={setBannerIsOpen} />
                ) : undefined}
                <UpdateBanner
                    hide={showInstallBanner && isMobile}
                    setBannerIsOpen={setBannerIsOpen}
                />
                {renderSideSwipeableDrawer()}
                <div className={classes.header}>
                    <Button
                        color="primary"
                        variant="text"
                        className={classes.button}
                        onClick={() => handleSideDrawerToggle(true)}
                    >
                        <Icon>menu</Icon>
                    </Button>
                </div>
            </div>
            <div className={classes.spacer} />
            {bannerIsOpen ? <div className={classes.spacer} /> : undefined}
        </>
    );
}
