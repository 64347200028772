import axios from './Axios';
import Api from './Api';
import { DIGITAL_ID_METHOD } from 'components/bankID/typings';
export default class DigitalIdApi {

    /**
     * Gets the user session data. The same data returned when logging in.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    cancel(method: DIGITAL_ID_METHOD, transactionId: string) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/digital_id/cancel`,
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                method: method,
                transactionId: transactionId,
            },
        });
    }
}