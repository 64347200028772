import axios from "./Axios";
import Api from "./Api";

export default class InternalInfoApi {
    /**
     * Fetches company information.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    fetchCompanyInfo(token = undefined) {
        return axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/general/company`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
    }

    /**
     * Fetches the latest tools portal version.
     * @param {JWT} token - The token of the account.
     * @param {bool} isStaging - If the staging version should be fetched.
     * @returns  A Axios promise
     */
    fetchBuildVersion() {
        return axios({
            method: "GET",
            timeout: Api.Timeout,
            url: `${Api.Host}/version/staff`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {},
        });
    }
}
