
export interface Assignment {
    uuid: string | null;
    year: number | null;
    week: number | null;
    status: Status | null;
    carePlace: CarePlace | null;
    country: Country | null;
    county: County | null;
    pay: Pay | null;
    timesheet: Timesheet | null;
    timesheetTemplate: TimesheetTemplate | null;
    allowTravelsheet: boolean;
    bookingConfirmation: BookingConfirmation | null;
    filesWithSignatureCount: number; // files that have been signed
    filesRequiringSignatureFromStaffCount: number; // files that are yet to be signed by the staff
}

export const emptyAssignmentData: Assignment = {
    uuid: null,
    year: null,
    week: null,
    status: null,
    carePlace: null,
    country: null,
    county: null,
    pay: null,
    timesheet: null,
    timesheetTemplate: null,
    allowTravelsheet: false,
    bookingConfirmation: null,
    filesWithSignatureCount: 0,
    filesRequiringSignatureFromStaffCount: 0,
};

type CarePlace = {
    id: number;
    name: string;
};

type Country = {
    id: number;
    name: string;
    currencyCode: string;
};

type County = {
    id: number;
    name: string;
};

type Pay = {
    value: number;
    revisedValue: number;
};

type Status = {
    id: number;
    label: string;
};

type Timesheet = {
    date: string | Date;
};

type TimesheetTemplate = {
    id: number;
    label: string;
};

type BookingConfirmation = {
    date: string | Date;
};
