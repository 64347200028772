import React from 'react';
import TextField, { TextFieldProps } from '../TextField';
import { extractNumber } from 'utils/utils';

export interface NumberFieldProps
    extends Omit<TextFieldProps, 'onError' | 'onChange' | 'value'> {
    value: number;
    allowZero?: boolean;
    allowNegative?: boolean;
    asFloat?: boolean;
    maxValue?: number;
    minValue?: number;
    onChange: (
        value: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
    onError?: (b: boolean) => void;
}

type LocalValue = {
    string: string;
    number: number;
};

/**
 * NumberField is a component that allows users to input numeric values.
 * It provides validation for minimum and maximum values, as well as options
 * to allow zero, negative numbers, and floating-point numbers.
 * The component also supports error handling and helper text.
 *
 * IMPORTANT this component should only be used where the value is a number and not a string formatted as a number.
 * If you want a string formatted as a number just use the extractNumber on the onChange with a normal TextField instead.
 */
export default function NumberField({
    allowZero = true,
    allowNegative = false,
    asFloat = false,
    maxValue,
    minValue,
    value,
    disabled,
    onError,
    onChange,
    helperText,
    ...rest
}: NumberFieldProps): JSX.Element {
    const [localValue, setLocalValue] = React.useState<LocalValue>({
        // this means that we default to an empty string if the value is 0.
        // So if the actual value IS 0 that is loaded it will not update the string value to a 0.
        // But we allow typing a 0 in the textfield.
        string: '', 
        number: 0,
    });
    const [error, setError] = React.useState(false);

    // Set the local value when the value prop changes
    // This should only happen onMount during normal use
    React.useEffect(() => {
        if (localValue.number !== value) {
            setLocalValue({
                string: extractString(value.toString()),
                number: value,
            });
        }
    }, [value]);

    React.useEffect(() => {
        validate(localValue.number);
    }, [localValue.number]);

    const handleOnChange = (
        s: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        s = extractString(s);

        let newVal = asFloat ? parseFloat(s) : parseInt(s);
        if (Number.isNaN(newVal)) {
            newVal = 0;
        }

        if (minValue !== undefined && newVal < minValue) {
            newVal = minValue;
            s = newVal.toString();
        }
        if (maxValue !== undefined && newVal > maxValue) {
            newVal = maxValue;
            s = newVal.toString();
        }

        // Don't update the parents value if it ends with a dot without any following numbers
        if (onChange && !s.endsWith('.')) {
            onChange(newVal, event);
        }
        setLocalValue({
            string: s,
            number: newVal,
        });
    };

    const extractString = (s: string) =>
        extractNumber(s, asFloat ? 2 : 0, allowNegative);

    const validate = (s: number) => {
        let err = false;
        if ((!allowZero && s === 0) || (!allowNegative && s < 0)) {
            err = true;
        }

        setError(err);
        if (onError) {
            onError(err);
        }
    };

    return (
        <TextField
            {...rest}
            value={localValue.string}
            onChange={handleOnChange}
            variant={disabled ? 'standard' : 'outlined'}
            type={'text'}
            inputMode='numeric'
            disabled={disabled}
            error={error}
            helperText={error ? 'Inkorrekt värde' : helperText}
        />
    );
}

interface NumberFieldAsStringProps
    extends Omit<NumberFieldProps, 'value' | 'onChange'> {
    value: string;
    onChange: (
        value: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
}

// Not sure if this is needed anymore, but it's here if we need it.
export const NumberFieldAsString = ({
    value,
    onChange,
    ...rest
}: NumberFieldAsStringProps): JSX.Element => {
    const handleOnChange = (
        s: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        onChange(s.toString() || '0', event);
    };

    return (
        <NumberField
            {...rest}
            value={parseFloat(value) || 0}
            onChange={handleOnChange}
        />
    );
};
