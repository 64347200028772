import React from 'react';
import { Route, Switch } from 'react-router';
import jwt_decode from 'jwt-decode';
import LoginPage from 'pages/LoginPage/LoginPage';
import LogoutPage from 'pages/logoutPage/logoutPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage/ForgotPasswordPage';
import ChangePasswordPage from 'pages/ForgotPasswordPage/ChangePasswordPage';
import LandingPage from 'pages/LandingPage/LandingPage';
import AssignmentPage from 'pages/assignment/AssignmentPage';
import AssignmentListPage from 'pages/assignment/ListPage';
import AssignmentListAllPage from 'pages/assignment/ListAllPage';
import AssignmentTimereport from 'pages/assignment/TimesheetPage';
import MyProfilePage from 'pages/myProfile/Page';
import ReplySMSPage from 'pages/replySMS/ReplySMS';
import PageNotFound from 'pages/ErrorPages/PageNotFound';
import { RequiresAuth } from 'components/PrivateRoute';
import { RequiresLoggedOut } from 'components/PublicRoute';
import useMeStore from 'store/useMeStore';
import UnsubscribeFromEmailPage from 'pages/unsubscribe/email/Page';
import Questionnaire from 'pages/questionnaire/View';

export interface authData {
    isAuthed: boolean;
    isLoggedIn: boolean;
}

export interface LocationState {
    redirectedFrom: null | string;
}

export const getRoutes = () => {
    const useEvaluateAuth = function (): authData {
        const { me, token, isLoggedIn } = useMeStore(state => state);
        if (isLoggedIn === false) {
            return { isAuthed: false, isLoggedIn };
        }
        let decodedToken: any = null;
        try {
            decodedToken = jwt_decode(token);
        } catch (e) {
            decodedToken = null;
        }
        var dateNow = new Date();
        var tokenDate = decodedToken
            ? new Date(decodedToken.exp * 1000)
            : new Date();
        const ulevel = me?.ulevel;
        if (
            ulevel === 3 &&
            isLoggedIn &&
            tokenDate.getTime() > dateNow.getTime()
        ) {
            return { isAuthed: true, isLoggedIn };
        } else {
            return { isAuthed: false, isLoggedIn };
        }
    };

    return (
        <Switch>
            <Route
                exact
                path='/'
                render={() => (
                    <RequiresAuth auth={useEvaluateAuth}>
                        <LandingPage />
                    </RequiresAuth>
                )}
            />
            <Route
                exact
                path='/uppdrag'
                render={() => (
                    <RequiresAuth auth={useEvaluateAuth}>
                        <AssignmentListPage />
                    </RequiresAuth>
                )}
            />
            <Route
                exact
                path='/uppdrag/alla'
                render={() => (
                    <RequiresAuth auth={useEvaluateAuth}>
                        <AssignmentListAllPage />
                    </RequiresAuth>
                )}
            />
            <Route
                exact
                path='/uppdrag/:id'
                render={() => (
                    <RequiresAuth auth={useEvaluateAuth}>
                        <AssignmentPage />
                    </RequiresAuth>
                )}
            />
            <Route
                exact
                path='/uppdrag/:id/tidrapport'
                render={() => (
                    <RequiresAuth auth={useEvaluateAuth}>
                        <AssignmentTimereport />
                    </RequiresAuth>
                )}
            />
            <Route
                path='/min-profil'
                render={() => (
                    <RequiresAuth auth={useEvaluateAuth}>
                        <MyProfilePage />
                    </RequiresAuth>
                )}
            />
            <Route
                exact
                path='/logga-ut'
                render={() => (
                    <RequiresAuth auth={useEvaluateAuth}>
                        <LogoutPage />
                    </RequiresAuth>
                )}
            />
            <Route
                exact
                path='/logga-in'
                render={() => (
                    <RequiresLoggedOut auth={useEvaluateAuth}>
                        <LoginPage />
                    </RequiresLoggedOut>
                )}
            />
            <Route
                exact
                path='/glomt-uppgift'
                render={() => (
                    <RequiresLoggedOut auth={useEvaluateAuth}>
                        <ForgotPasswordPage />
                    </RequiresLoggedOut>
                )}
            />
            <Route
                exact
                path='/nytt-losenord'
                render={() => (
                    <RequiresLoggedOut auth={useEvaluateAuth}>
                        <ChangePasswordPage />
                    </RequiresLoggedOut>
                )}
            />
            <Route
                exact
                path='/svara-sms/:smshash'
                render={() => <ReplySMSPage />}
            />
            <Route
                exact
                path='/avregistrera/email'
                render={() => <UnsubscribeFromEmailPage />}
            />
            <Route
                exact
                path='/formular/:uuid'
                render={() => <Questionnaire />}
            />
            <Route component={PageNotFound} />
        </Switch>
    );
};
