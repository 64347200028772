export const primary = {
    //Generated with http://mcg.mbitson.com/#!?mcgpalette0=%2316aabd&mcgpalette1=%230c90a6 based on the main color
    veryLight: "#B9E6EB",
    light: "#39B6C7",
    main: "#16aabd",
    dark: "#028FA3",
    contrastText: "#fff",
    50: "#e3f5f7",
    100: "#b9e6eb",
    200: "#8bd5de",
    300: "#5cc4d1",
    400: "#39b7c7",
    500: "#16aabd",
    600: "#13a3b7",
    700: "#1099ae",
    800: "#0c90a6",
    900: "#067f98",
    1000: "#023f4b",
    A100: "#c4f4ff",
    A200: "#91eaff",
    A400: "#5ee0ff",
    A700: "#45dbff",
};

export const yellow = {
    light: "#FCECC5",
    main: "#F5BF3D",
    dark: "#745506",
    contrastText: "#424242",
    100: "#FCECC5",
    200: "#FADF9E",
    300: "#F9D786",
    400: "#F7CF6E",
    500: "#F5BF3D",
    600: "#DAA00B",
    700: "#BD8B0A",
    800: "#9B7208",
    900: "#745506",
    1000: "#4b3602",
};
