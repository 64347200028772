import React, { useState, useRef, useEffect } from 'react';

import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import grey from '@mui/material/colors/grey';
import Typography from '@mui/material/Typography';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import Button from 'components/interface/Button/Button';
import ConfirmationMenu from 'components/interface/ConfirmationMenu/ConfirmationMenu';
import TextField from 'components/interface/textField/TextField';
import { QueuedFile } from '../File';
import ReactGA from 'react-ga4';

const useStyles = makeStyles()((theme, params, classes) => ({
    base: {
        width: '100%',
        background: grey[100],
        border: '1px dashed ' + grey[600],
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing(3, 2),
        margin: '1px',
        [`&.${classes.dragging}`]: {
            borderColor: theme.palette.primary.main,
            borderWidth: '2px',
            margin: '0px',
        },
    },
    dragging: {},
    addFilesWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '36px',
    },
    addFileButton: {
        marginLeft: theme.spacing(),
    },
    queueContainer: {
        'width': '100%',
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'flex-start',
        'alignItems': 'center',
        '&> :first-child': {
            marginTop: theme.spacing(2),
        },
    },
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        marginTop: theme.spacing(2),
    },
    noUserSelect: {
        userSelect: 'none',
    },
    description: {
        userSelect: 'none',
        marginBottom: theme.spacing(2),
    },
}));

export default function FileDropZone({
    queue,
    onDrop,
    onRemove,
    onRename,
    onUpload,
    uploading,
}) {
    const { classes, cx } = useStyles();
    const dropRef = useRef();
    const inputRef = useRef();
    const dragCounter = useRef(0);
    const [dragging, setDragging] = useState(false);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [renameFileData, setRenameFileData] = useState({ id: 0, name: '' });

    const pushNotification = useNotificationStore(state => state.push);

    useEffect(() => {
        let div = dropRef.current;
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        div.addEventListener('dragover', handleDrag);
        div.addEventListener('drop', handleDrop);
        return () => {
            div.removeEventListener('dragenter', handleDragIn);
            div.removeEventListener('dragleave', handleDragOut);
            div.removeEventListener('dragover', handleDrag);
            div.removeEventListener('drop', handleDrop);
            dragCounter.current = 0;
        };
    }, []);

    const handleDrag = e => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };
    const handleDragIn = e => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
        }
    };
    const handleDragOut = e => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current > 0) return;
        setDragging(false);
    };
    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            onDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
            dragCounter.current = 0;
        }
    };

    const handleChange = e => {
        if (e.target.files && e.target.files.length > 0) {
            onDrop(e.target.files);
        }
    };

    const handleClick = () => {
        const input = inputRef.current;
        if (input) input.click();
    };

    /** Rename */
    const handleRename = (id, name) => {
        setRenameFileData({ id, name });
        setRenameDialogOpen(true);
    };

    const handleRenameChange = value => {
        setRenameFileData({ ...renameFileData, name: value });
    };

    const confirmRename = () => {
        if (renameFileData.name.length === 0) {
            pushNotification(
                NotificationTypes.error,
                'Filnamnet får inte vara tom.',
                'file-rename-error',
                1
            );
        } else if (renameFileData.id === 0) {
            cancelRename();
        } else {
            onRename(renameFileData.id, renameFileData.name);
            cancelRename();
        }
    };

    const cancelRename = () => {
        setRenameFileData({ id: 0, name: '' });
        setRenameDialogOpen(false);
    };

    const renderAddFiles = () => (
        <div className={classes.addFilesWrapper}>
            {!dragging ? (
                <>
                    <Typography
                        variant='body1'
                        color='textSecondary'
                        className={classes.noUserSelect}>
                        Släpp filer i rutan eller
                    </Typography>
                    <Button
                        className={classes.addFileButton}
                        color='primary'
                        variant='contained'
                        onClick={handleClick}>
                        Tryck här
                    </Button>
                </>
            ) : (
                <Typography
                    variant='body1'
                    color='textSecondary'
                    className={classes.noUserSelect}>
                    Släpp filerna för att ladda upp
                </Typography>
            )}
            <input
                ref={inputRef}
                type='file'
                style={{ display: 'none' }}
                onChange={handleChange}
                multiple
            />
        </div>
    );

    const renderFooter = () => {
        return (
            <div className={classes.footer}>
                <Typography
                    variant='body2'
                    color='textPrimary'
                    className={classes.description}>
                    Du kan välja att ladda upp ett eller flera dokument på samma
                    gång. När du är klar klickar du på “Ladda upp”.
                </Typography>
                <Button
                    className={classes.addFileButton}
                    color='primary'
                    variant='contained'
                    onClick={() => onUpload()}
                    endIcon={<UploadIcon />}
                    loading={uploading}>
                    Ladda upp
                </Button>
            </div>
        );
    };

    const renameDialog = () => (
        <ConfirmationMenu
            open={renameDialogOpen}
            message={'Är du säker på att du vill redigera filnamnet?'}
            title={'Redigera filnamn'}
            cancelLabel={'Avbryt'}
            confirmLabel={'Ja, redigera filnamn'}
            cancelFunction={cancelRename}
            confirmFunction={confirmRename}
            onClose={cancelRename}
            fullWidth>
            <TextField
                value={renameFileData.name}
                placeholder='Filnamn...'
                onChange={handleRenameChange}
                type='text'
                fullWidth
            />
        </ConfirmationMenu>
    );

    return (
        <div
            ref={dropRef}
            className={cx(classes.base, { [classes.dragging]: dragging })}>
            {renderAddFiles()}
            <div className={classes.queueContainer}>
                {queue
                    ? queue.map(file => (
                          <QueuedFile
                              key={file.id}
                              {...file}
                              onRemove={onRemove}
                              onRename={handleRename}
                          />
                      ))
                    : undefined}
            </div>
            {queue.length > 0 ? renderFooter() : undefined}
            {renameDialog()}
        </div>
    );
}

FileDropZone.propTypes = {
    queue: PropTypes.array.isRequired,
    onDrop: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
};
