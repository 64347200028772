import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Content from 'components/Content/Content';
import MiniNav from 'components/Header/mobile/MiniNav';
import BottomNavigation from 'components/interface/bottomNavigation/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InfoSelectedIcon from '@mui/icons-material/Info';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import AssignmentSelectedIcon from '@mui/icons-material/Assignment';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import CloudUploadSelectedIcon from '@mui/icons-material/CloudUpload';
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import BarChartIcon from '@mui/icons-material/BarChartRounded';
import InformationComponent from './InformationComponent';
import FilesComponent from './FilesComponent';
import StatsComponent from './StatsComponent';
import TimereportComponent from './TimereportComponent';
import PageInfoText from 'components/interface/InfoText/mobile/InfoText';
import { AssignmentStatusChip } from '../../StatusChip';
import Alert, {
    STATUS as ALERT_STATUS,
} from 'components/interface/alert/Alert';

const useStyles = makeStyles()((theme, params, classes) => ({
    titleWrapper: {
        width: '100%',
        padding: theme.spacing(0, 2),
    },
    content: {
        paddingTop: theme.spacing(2) + ' !important',
    },
    alertBox: {
        marginBottom: theme.spacing(1),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    alert: {
        '&:not(:first-of-type)': {
            marginTop: theme.spacing(1),
        },
    },
}));

export default function AssignmentMobile({
    informationProps,
    timesheetProps,
    filesProps,
    statsProps,
    renderTimesheetButton,
    onSignFile,
}) {
    const { classes, cx } = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [activePage, setActivePage] = React.useState(1);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        let page = parseInt(params.get('page'));
        if (page) {
            if (page > 4) {
                page = 1;
            }
            if (page !== activePage) {
                setActivePage(prev => page);
            }
        }
    }, [location.search]);

    const handlePageChange = (event, newValue, doPush) => {
        setActivePage(prev => newValue);
        if (doPush) {
            history.push('?page=' + newValue);
        } else {
            history.replace('?page=' + newValue);
        }
    };

    const renderInformation = () => (
        <InformationComponent {...informationProps} />
    );

    const renderFiles = () => {
        let fileArr = [...filesProps.files];
        let hasBookingConfirmationFile = false;
        for (let i = 0; i < fileArr.length; i++) {
            if (
                fileArr[i].isGenerated &&
                fileArr[i].name?.toLowerCase().includes('bokningsbekräftelse')
            ) {
                hasBookingConfirmationFile = true;
                break;
            }
        }
        if (!!informationProps?.data?.timesheet?.date) {
            const timesheetFile = {
                id: -1,
                name: 'Tidrapport.pdf',
                isGenerated: true,
                date: informationProps?.data?.timesheet?.date,
                uploader: {},
                onDownload: informationProps.fetchTimesheetPDF,
            };
            fileArr = [timesheetFile, ...fileArr];
        }
        if (
            !!informationProps?.data?.bookingConfirmation?.date &&
            !hasBookingConfirmationFile
        ) {
            const bookingConfirmationFile = {
                id: -1,
                name: 'Bokningsbekräftelse.pdf',
                isGenerated: true,
                date: informationProps?.data?.bookingConfirmation?.date,
                uploader: {},
                onDownload: informationProps.fetchBookingConfirmationPDF,
            };
            fileArr = [bookingConfirmationFile, ...fileArr];
        }
        return (
            <FilesComponent
                {...filesProps}
                files={fileArr}
                onSignFile={onSignFile}
            />
        );
    };

    const renderTimereport = () => (
        <TimereportComponent
            {...informationProps}
            totalReportedDays={timesheetProps?.timesheet?.totalReportedDays}
            totalReportedTime={timesheetProps?.timesheet?.totalHours}
            renderTimesheetButton={renderTimesheetButton}
            isTimereportLoading={timesheetProps.loading}
        />
    );

    const renderStats = () => (
        <StatsComponent
            stats={statsProps.stats}
            isStatsLoading={statsProps.loading}
        />
    );

    const renderActivePage = () => {
        switch (activePage) {
            case 1:
                return renderInformation();
            case 2:
                return renderTimereport();
            case 3:
                return renderFiles();
            case 4:
                return renderStats();
            default:
                return null;
        }
    };

    const renderBottomNav = () => {
        return (
            <BottomNavigation
                value={activePage > 4 ? 4 : activePage}
                onChange={handlePageChange}>
                <BottomNavigationAction
                    label='Info'
                    value={1}
                    icon={
                        activePage === 1 ? <InfoSelectedIcon /> : <InfoIcon />
                    }
                />
                <BottomNavigationAction
                    label='Tidrapport'
                    value={2}
                    icon={
                        activePage === 2 ? (
                            <AssignmentSelectedIcon />
                        ) : (
                            <AssignmentIcon />
                        )
                    }
                />
                <BottomNavigationAction
                    label='Dokument'
                    value={3}
                    icon={
                        activePage === 3 ? (
                            <CloudUploadSelectedIcon />
                        ) : (
                            <CloudUploadIcon />
                        )
                    }
                />
                <BottomNavigationAction
                    label='Statistik'
                    value={4}
                    icon={<BarChartIcon />}
                />
            </BottomNavigation>
        );
    };

    const renderAlerts = () => {
        let alerts = [];

        if (informationProps?.data?.filesRequiringSignatureFromStaffCount > 0) {
            alerts.push(
                <Alert
                    key='signature'
                    classes={classes.alert}
                    status={ALERT_STATUS.WARNING}
                    text={`${informationProps?.data?.filesRequiringSignatureFromStaffCount} dokument behöver signeras med e-legitimation. Navigera till dokument via knappen längst ned på sidan, klicka sedan på knappen "Signera dokument".`}
                    fullWidth
                    withBorder
                />
            );
        }

        if (alerts.length > 0) {
            return <div className={classes.alertBox}>{alerts}</div>;
        }
        return null;
    };

    return (
        <>
            <MiniNav title='Uppdrag' />
            <div className={classes.titleWrapper}>
                <PageInfoText
                    title={informationProps?.data?.carePlace?.name}
                    subtitle={
                        informationProps?.data?.uuid
                            ? `${informationProps?.data?.year}, v.${informationProps?.data?.week}`
                            : ' '
                    }
                    noWeek
                    loading={informationProps?.loading}>
                    <AssignmentStatusChip
                        status={informationProps?.data?.status?.id}
                    />
                </PageInfoText>
            </div>
            <Content classes={classes.content}>
                {renderAlerts()}
                {renderActivePage()}
            </Content>
            {renderBottomNav()}
        </>
    );
}
