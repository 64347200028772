import { AxiosResponse } from 'axios';

export enum DIGITAL_ID_METHOD {
    BANKID = 'BANKID',
}

export interface DigitalIdResponse {
    transactionId: string;
    status: STATUS;
    message?: string;
    data?: any;
    qrCode?: string;
}

export enum STATUS {
    NOT_STARTED = 'NOT_STARTED',
    STARTED = 'STARTED',
    PENDING = 'PENDING',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
}

export type DigitalIdRequestEndpoint = (
    method: DIGITAL_ID_METHOD,
    token: string,
    transactionId?: string,
    payload?: DigitalIdPayload
) => Promise<AxiosResponse<any, any>>;

export type DigitalIdPayload = {
    [x: string]: any;
};
export function BuildDigitalIdResponse(body: any): DigitalIdResponse | null {
    if(body.status) {
        return {
            transactionId: body.transactionId,
            status: body.status,
            message: body.message,
            data: body.data,
            qrCode: body.qrCode,
        };
    }
    return null
}
