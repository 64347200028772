import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import Button from 'components/interface/Button/Button';
import Header from 'components/Header/Header';
import Content from 'components/Content/Content';
import Footer from 'components/Footer/Footer';
import PageInfoText from 'components/interface/InfoText/desktop/InfoText';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import SendIcon from '@mui/icons-material/SendOutlined';
import CheckIcon from '@mui/icons-material/CheckCircleOutlined';
import { AssignmentInfo } from 'components/interface/timesheet/typings';
import Spinner from 'react-spinkit';
import Typography from '@mui/material/Typography';
import AnimateHeight from 'react-animate-height';

const useStyles = makeStyles()(theme => ({
    returnButton: {
        display: 'flex',
        width: '100%',
        marginTop: theme.spacing(2),
        justifyContent: 'space-between',
    },
    submitStatus: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitStatusIcon: {
        width: '75px !important',
        height: '75px !important',
        marginBottom: theme.spacing(2),
    },
    submitStatusText: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        userSelect: 'none',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    timesheetWrapper: {
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
}));

interface Props {
    children: JSX.Element;
    assignmentID: string;
    assignment: AssignmentInfo | null;
    year: number | null;
    week: number | null;
    loading: boolean;
    submitting: boolean;
    success: boolean;
    canSubmit: boolean;
    onSubmit: () => void;
}

export default function View({
    children,
    assignment,
    year,
    week,
    submitting,
    success,
    canSubmit,
    onSubmit,
}: Props): JSX.Element {
    const { classes } = useStyles();
    const history = useHistory();

    const renderSubmittedPage = () => {
        const submittingPage = () => (
            <div className={classes.submitStatus}>
                <Spinner
                    name='circle'
                    fadeIn='none'
                    className={classes.submitStatusIcon}
                />
                <Typography
                    variant='h6'
                    color='textPrimary'
                    className={classes.submitStatusText}
                    align='center'>
                    <b>Skickar in tidrapport</b>
                </Typography>
            </div>
        );
        const successPage = () => (
            <div className={classes.submitStatus}>
                <CheckIcon className={classes.submitStatusIcon} />
                <Typography
                    variant='h6'
                    color='textPrimary'
                    className={classes.submitStatusText}
                    align='center'>
                    <b>Tidrapporten har skickats in</b>
                </Typography>
            </div>
        );
        return success ? successPage() : submittingPage();
    };

    return (
        <>
            <Header />
            <Content>
                <PageInfoText
                    title={assignment?.carePlace?.name}
                    subtitle={assignment ? `${year}, v.${week}` : null}
                />
                <div className={classes.returnButton}>
                    <Button
                        variant='outlined'
                        color='default'
                        onClick={() => {
                            // If there is no history, push the root location and go there.
                            if (history.length < 2) {
                                history.push('/');
                            } else {
                                history.goBack();
                            }
                        }}
                        startIcon={<BackIcon />}>
                        Gå tillbaka
                    </Button>
                    {success || !canSubmit ? null : (
                        <Button
                            color='primary'
                            variant='contained'
                            onClick={onSubmit}
                            disabled={submitting}
                            endIcon={<SendIcon />}>
                            Skicka in
                        </Button>
                    )}
                </div>
                <AnimateHeight
                    duration={500}
                    height={!(submitting || success) ? 0 : 'auto'}>
                    {renderSubmittedPage()}
                </AnimateHeight>
                <AnimateHeight
                    duration={500}
                    height={submitting || success ? 0 : 'auto'}>
                    <div className={classes.timesheetWrapper}>{children}</div>
                </AnimateHeight>
            </Content>
            <Footer />
        </>
    );
}
