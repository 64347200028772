import React, { Component } from 'react';
import { makeStyles } from 'tss-react/mui';
import { convertStatusToString } from 'utils/utils_legacy';
import { ColumnWrapper, Column } from 'components/interface/Widget/Widget';
import Skeleton from '@mui/lab/Skeleton';
import Header from 'components/Header/Header';
import Content from 'components/Content/Content';
import Footer from 'components/Footer/Footer';
import { AssignmentStatusChip } from '../../StatusChip';
import InformationWidget from './InformationWidget';
import FilesWidget from './FilesWidget';
import PageInfoText from 'components/interface/InfoText/desktop/InfoText';
import Alert, {
    STATUS as ALERT_STATUS,
} from 'components/interface/alert/Alert';

const useStyles = makeStyles({ name: { AssignmentDesktop } })(
    (theme, _params, _classes) => ({
        alertBox: {
            marginTop: theme.spacing(2),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        alert: {
            '&:not(:first-of-type)': {
                marginTop: theme.spacing(1),
            },
        },
    })
);

export default function AssignmentDesktop({
    informationProps,
    timesheetProps,
    filesProps,
    statsProps,
    renderTimesheetButton,
    onSignFile
}) {
    const { classes } = useStyles();

    const renderPage = () => {
        let fileArr = [...filesProps.files];
        let hasBookingConfirmationFile = false;
        for (let i = 0; i < fileArr.length; i++) {
            if (
                fileArr[i].isGenerated &&
                fileArr[i].name?.toLowerCase().includes('bokningsbekräftelse')
            ) {
                hasBookingConfirmationFile = true;
                break;
            }
        }
        if (!!informationProps?.data?.timesheet?.date) {
            const timesheetFile = {
                id: -1,
                name: 'Tidrapport.pdf',
                isGenerated: true,
                date: informationProps?.data?.timesheet?.date,
                uploader: {},
                onDownload: informationProps.fetchTimesheetPDF,
            };
            fileArr = [timesheetFile, ...fileArr];
        }
        if (
            !!informationProps?.data?.bookingConfirmation?.date &&
            !hasBookingConfirmationFile
        ) {
            const bookingConfirmationFile = {
                id: -1,
                name: 'Bokningsbekräftelse.pdf',
                isGenerated: true,
                date: informationProps?.data?.bookingConfirmation?.date,
                uploader: {},
                onDownload: informationProps.fetchBookingConfirmationPDF,
            };
            fileArr = [bookingConfirmationFile, ...fileArr];
        }

        const renderAlerts = () => {
            let alerts = [];

            if (informationProps?.data?.filesRequiringSignatureFromStaffCount > 0) {
                alerts.push(
                    <Alert
                        key='signature'
                        classes={classes.alert}
                        status={ALERT_STATUS.WARNING}
                        text={`${
                            informationProps?.data?.filesRequiringSignatureFromStaffCount
                        } dokument behöver signeras med e-legitimation. Du hittar ${
                            informationProps?.data
                                ?.filesRequiringSignatureFromStaffCount > 1
                                ? 'dem'
                                : 'den'
                        } i listan nedan, klicka sedan på knappen "Signera dokument".`}
                        fullWidth
                        withBorder
                    />
                );
            }

            if (alerts.length > 0) {
                return <div className={classes.alertBox}>{alerts}</div>;
            }
            return null;
        };

        return (
            <>
                <PageInfoText
                    title={informationProps?.data?.carePlace?.name}
                    subtitle={
                        informationProps?.data?.uuid
                            ? `${informationProps?.data?.year}, v.${informationProps?.data?.week}`
                            : null
                    }>
                    {informationProps?.loading ||
                    !informationProps?.data?.status?.id ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        <AssignmentStatusChip
                            status={informationProps?.data?.status?.id}
                        />
                    )}
                </PageInfoText>
                {renderAlerts()}

                <ColumnWrapper>
                    <Column grow>
                        <InformationWidget
                            {...informationProps}
                            stats={statsProps.stats}
                            totalReportedDays={timesheetProps?.timesheet?.totalReportedDays}
                            totalReportedTime={timesheetProps?.timesheet?.totalHours}
                            renderTimesheetButton={renderTimesheetButton}
                            isStatsLoading={statsProps.loading}
                            isTimereportLoading={timesheetProps.loading}
                        />
                    </Column>
                    <Column small>
                        <FilesWidget {...filesProps} files={fileArr} onSignFile={onSignFile} />
                    </Column>
                </ColumnWrapper>
            </>
        );
    };

    return (
        <>
            <Header />
            <Content>{renderPage()}</Content>
            <Footer />
        </>
    );
}
