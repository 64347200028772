import { create as _create, StateCreator } from "zustand";

const resetters: (() => void)[] = [];

// All store build with this wrapper will be reset on logging out
export const create = (<T extends unknown>(f: StateCreator<T> | undefined) => {
    if (f === undefined) return create
    const store = _create(f)
    const initialState = store.getState()
    resetters.push(() => {
      store.setState(initialState, true)
    })
    return store
  }) as typeof _create
  

// This will NOT reset the user store
export const resetAllStores = () => {
    for (const resetter of resetters) {
        resetter();
    }
};