import useBuildVersionStore from 'store/useBuildVersionStore';

export default {
    onUpdate: registration => {
        const setInstalling = useBuildVersionStore.getState().setInstalling;
        const setIsWaiting = useBuildVersionStore.getState().setIsWaiting;
        const setWaitingWorker = useBuildVersionStore.getState().setWaitingWorker;

        const waitingServiceWorker = registration.waiting;
        if (waitingServiceWorker) {
            setWaitingWorker(registration.waiting)
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    setInstalling(false);
                    setIsWaiting(false);
                    window.location.reload();
                }
            });
            setIsWaiting(true);
        }
    },
    onRegistration: registration => {
        const setRegistration = useBuildVersionStore.getState().setRegistration;
        setRegistration(registration);
    },
    onSuccess: registration => {
        const setInstalling = useBuildVersionStore.getState().setInstalling;
        setInstalling(false);
    },
    onNoServiceWorkers: () => {
        const setInstalling = useBuildVersionStore.getState().setInstalling;
        setInstalling(false);
    },
};
