import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Logo from 'components/interface/Logo/Logo';
import { Typography } from '@mui/material';

const useStyles = makeStyles<void, 'desktop' | 'mobile' | 'loading'>()(
    (theme, _params, classes) => ({
        base: {
            height: '100%',
            width: '100%',
            display: 'flex',
        },
        bodyWrapper: {
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
        },
        body: {
            position: 'relative',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundImage: 'url(/images/pattern.png)',
            [`&.${classes.mobile}`]: {
                height: '100%',
                overflow: 'scroll',
            },
            [`&.${classes.desktop}`]: {
                maxWidth: '500px',
                minHeight: 'min(100vh - 128px, 700px)',
                maxHeight: 'calc(100vh - 128px)',
                borderRadius: '20px',
                overflow: 'hidden',
            },
            [`&.${classes.loading}`]: {
                backgroundImage: 'url(/images/pattern_animation.gif)',
            },
        },
        content: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            [`&.${classes.mobile}`]: {
                paddingTop: 'calc(32px + env(safe-area-inset-top, 0px))',
                paddingBottom: 'calc(32px + env(safe-area-inset-bottom, 0px))',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            [`&.${classes.desktop}`]: {
                padding: theme.spacing(3),
                overflowY: 'auto',
            },
        },
        desktop: {},
        mobile: {},
        loading: {},
        background: {
            height: '100%',
            flexGrow: '1',
            backgroundImage: 'url(/images/bg_smaller_compressed.jpg)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            opacity: '0.80',
        },
        preloadLoadingAnimation: {
            backgroundImage: 'url(/images/pattern_animation.gif)',
            display: 'hidden',
        },
        logoWrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: theme.spacing(3),
        },
        logo: {
            '& path': {
                color: theme.palette.primaryDark.main,
                fill: theme.palette.primaryDark.main,
            },
        },
        welcomeText: {
            userSelect: 'none',
            color: theme.palette.primaryDark.main,
            paddingBottom: theme.spacing(4),
        },

        footer: {
            width: '100%',
            height: '64px',
            minHeight: '64px',
            position: 'relative',
            paddingBottom: 'calc(env(safe-area-inset-bottom, 0px))',
        },
    })
);

interface Props {
    isMobile: boolean;
    loading: boolean;
    children: JSX.Element | Array<JSX.Element>;
    footer?: React.ReactNode;
}

export default function LandingPageWrapper({
    isMobile,
    loading,
    children,
    footer,
}: Props) {
    const { classes, cx } = useStyles();

    const renderBody = () => (
        <div
            className={cx(classes.body, {
                [classes.desktop]: !isMobile,
                [classes.mobile]: isMobile,
                [classes.loading]: loading,
            })}>
            <div
                className={cx(classes.content, {
                    [classes.desktop]: !isMobile,
                    [classes.mobile]: isMobile,
                })}>
                <div
                    className={cx(classes.logoWrapper, {
                        [classes.desktop]: !isMobile,
                    })}>
                    <Logo className={classes.logo} />
                </div>
                {children}
            </div>
            {footer && <div className={classes.footer}>{footer}</div>}
        </div>
    );

    return (
        <div className={classes.base}>
            {isMobile ? (
                renderBody()
            ) : (
                <div className={classes.bodyWrapper}>{renderBody()}</div>
            )}
            {!isMobile ? <div className={classes.background} /> : undefined}
            <div className={classes.preloadLoadingAnimation} />
        </div>
    );
}

interface WelcomeTextProps {
    title: string;
    subtitle?: string;
}

export const WelcomeText = ({ title, subtitle }: WelcomeTextProps) => {
    const { classes } = useStyles();
    return (
        <>
            <div className={classes.welcomeText}>
                <Typography variant='h6' fontFamily='poppins'>
                    <b>{title}</b>
                </Typography>
                {subtitle ? (
                    <Typography
                        variant='body2'
                        fontFamily='poppins'
                        fontWeight={500}>
                        {subtitle}
                    </Typography>
                ) : null}
            </div>
        </>
    );
};
