export interface RequestedQuestionnaireInfo {
    questionnaire: QuestionnaireSimple;
    isSubmitted: boolean;
}
export interface QuestionnaireSimple {
    name: string;
    description: string;
    headers: Header[];
    sections: Section[];

    themeColor: string;
}

export interface Header {
    title: string;
    order: number;

    fields: HeaderField[];
}

export interface HeaderField {
    title: string;
    value: string;
    order: number;
}

export interface Section {
    title: string;
    questions: Question[];
    instructions: string;
}

export enum QUESTION_TYPE {
    TEXT = 'text',
    BOOLEAN = 'boolean',
    MULTIPLE_CHOICE = 'multiple_choice',
    NUMERIC_SCALE = 'numeric_scale',
}

export interface Question {
    id: number;
    type: QUESTION_TYPE;
    question: string;
    options: string[]; // Only used for QuestionTypeMultipleChoice
    allowComment: boolean;
}

export type Answers = Record<number, Answer>;

export function answersToArray(answers: Answers): Answer[] {
    return Object.values(answers);
}

export interface Answer {
    questionID: number;
    answer: string | boolean | string[] | number;
    comment: string;
}
