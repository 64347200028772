import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import MiniNav from 'components/Header/mobile/MiniNav';
import Content from 'components/Content/Content';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/CheckCircleOutlined';
import Spinner from 'react-spinkit';
import PageInfoText from 'components/interface/InfoText/mobile/InfoText';
import { AssignmentInfo } from 'components/interface/timesheet/typings';

const useStyles = makeStyles()(theme => ({
    titleWrapper: {
        width: '100%',
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(2),
    },
    submitStatus: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',
        height: '100%',
        top: '0',
        left: '0',
    },
    submitStatusIcon: {
        width: '75px !important',
        height: '75px !important',
        marginBottom: theme.spacing(2),
    },
    submitStatusText: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        userSelect: 'none',
    },
}));

interface Props {
    children: JSX.Element;
    assignmentID: string;
    assignment: AssignmentInfo | null;
    year: number | null;
    week: number | null;
    loading: boolean;
    submitting: boolean;
    success: boolean;
    canSubmit: boolean;
    onSubmit: () => void;
}

export default function View({
    children,
    assignment,
    year,
    week,
    submitting,
    success,
    loading,
    canSubmit,
    onSubmit,
}: Props): JSX.Element {
    const { classes } = useStyles();
    const history = useHistory();

    const renderSubmittedPage = () => {
        const submittingPage = () => (
            <div className={classes.submitStatus}>
                <Spinner
                    name='circle'
                    fadeIn='none'
                    className={classes.submitStatusIcon}
                />
                <Typography
                    variant='h6'
                    color='textPrimary'
                    className={classes.submitStatusText}
                    align='center'>
                    <b>Skickar in tidrapport</b>
                </Typography>
            </div>
        );
        const successPage = () => (
            <div className={classes.submitStatus}>
                <CheckIcon className={classes.submitStatusIcon} />
                <Typography
                    variant='h6'
                    color='textPrimary'
                    className={classes.submitStatusText}
                    align='center'>
                    <b>Tidrapporten har skickats in</b>
                </Typography>
                <Typography
                    variant='body1'
                    align='center'
                    color='textPrimary'
                    className={classes.submitStatusText}>
                    Navigera tillbaka till uppdraget via pilen i vänstra hörnet.
                </Typography>
            </div>
        );
        return success ? successPage() : submittingPage();
    };

    const handleGoBack = () => {
        // If there is no history, push the root location and go there.
        if (history.length < 2) {
            history.push('/');
        } else {
            history.goBack();
        }
    };

    return (
        <>
            <MiniNav
                title='Tidrapportering'
                saveFunc={canSubmit && !submitting && !success ? onSubmit : undefined}
                saveText='Skicka in'
                clearFunc={handleGoBack}
            />
            <div className={classes.titleWrapper}>
                <PageInfoText
                    title={assignment?.carePlace?.name}
                    subtitle={assignment ? `${year}, v.${week}` : ' '}
                    noWeek
                    loading={loading}
                />
            </div>
            <Content>
                {submitting || success ? renderSubmittedPage() : children}
            </Content>
        </>
    );
}
