import axios from './Axios';
import Api from './Api';

export default class CountryAPI {
    /**
     * Looks up the postal code and returns the data if it exists.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    lookupPostalCode(token: string, code: string) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/country/postal_code`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                code,
            },
        });
    }
}
