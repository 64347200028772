import React, { Component } from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { Widget, Section } from 'components/interface/Widget/Widget';
import PageInfoText from 'components/interface/InfoText/desktop/InfoText';
import NotFound from 'components/interface/NotFound/NotFound';
import File from 'components/interface/files/File';
import FileDropZone from 'components/interface/files/desktop/FileDropZone';
import BlockRoute from 'components/interface/BlockRoute/BlockRoute';
import ReactGA from 'react-ga4';

const useStyles = makeStyles()((theme, params, classes) => ({
    description: {
        width: '100%',
        padding: theme.spacing(2, 2, 1, 2),
        userSelect: 'none',
    },
    filesWrapper: {
        width: '100%',
        maxHeight: '350px',
        minHeight: '232px',
        overflow: 'auto',
        padding: theme.spacing(0, 2),
        paddingBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dropZone: {
        width: '100%',
        padding: theme.spacing(0, 2, 2, 2),
    },
    notFound: {
        maxWidth: '348px',
    },
}));

export default function FilesWidget({
    files,
    queue,
    onDownload,
    onTrash,
    onUpload,
    onEnqueue,
    onDequeue,
    onRenameQueuedFile,
    loading,
    uploading,
    error,
    onSignFile,
}) {
    const { classes, cx } = useStyles();

    if (loading) {
        files = Array(4).fill({
            id: 0,
            name: '',
            uploader: {},
            category: {},
            size: 0,
        });
    }

    return (
        <Widget title='Dokument'>
            <Typography
                variant='body2'
                color='textPrimary'
                className={classes.description}>
                Här kan du ladda upp/ned dokument kopplat till ditt uppdrag.
            </Typography>
            <div className={classes.filesWrapper}>
                {files
                    ? files.map((file, i) => (
                          <File
                              key={file.id + '' + i}
                              {...file}
                              onDownload={file?.onDownload || onDownload}
                              onTrash={onTrash}
                              isLoading={loading}
                              onSignFile={onSignFile}
                          />
                      ))
                    : undefined}
                {files.length === 0 && !loading ? (
                    <NotFound
                        className={classes.notFound}
                        variant={'normal'}
                        title='Det finns inga filer.'
                        body='Ladda upp den första nedan.'
                        isMobile={false}
                    />
                ) : undefined}
            </div>
            <div className={classes.dropZone}>
                <FileDropZone
                    queue={queue}
                    onDrop={onEnqueue}
                    onRemove={onDequeue}
                    onRename={onRenameQueuedFile}
                    onUpload={onUpload}
                    uploading={uploading}
                />
            </div>
            <BlockRoute
                shouldBlockNavigation={queue.length > 0}
                confirmFunc={() => {
                    ReactGA.event({
                        category: 'Files',
                        action: 'Save changes prompt',
                        label: 'stay',
                    });
                }}
                cancelFunc={() => {
                    ReactGA.event({
                        category: 'Files',
                        action: 'Save changes prompt',
                        label: 'leave',
                    });
                }}
            />
        </Widget>
    );
}
