import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';
import { User, UserSetting, SETTINGS } from 'typings/user'



type State = {
    me: User | null;
    puppeteer: User | null;
    token: string;
    isLoggedIn: boolean;
    isLoggingOut: boolean;
}

type Actions = {
    setToken: (token: string) => void
    setUserData: (payload: SessionData) => void
    setIsLoggingOut: (val: boolean) => void
    logout: () => void
    setSettings: (settings: { [key in SETTINGS]: UserSetting }) => void
    setEmail: (email: string) => void
    setName: (name: string, surname: string) => void
}

const initialState: State = {
    me: null,
    puppeteer: null,
    token: '',
    isLoggedIn: false,
    isLoggingOut: false,
};

type Store = State & Actions

export type userDataPayload = {
    user: User | null;
    puppeteer: User | null;
    token: string;
}

export type SessionData = {
    user: User;
    puppeteer?: User | null;
    token: string;
}

// this makes sure that the SessionData is valid before setting it.
const validateSessionData = (sd: SessionData): boolean => {
    if (typeof sd.token !== "string") {
        return false
    }
    if (!sd.user) {
        return false
    }
    if (typeof sd.user.id !== "number" || sd.user.id < 1) {
        return false;
    }
    if (sd.user.ulevel !== 3) {
        return false;
    }

    return true
}

const useMeStore = create<Store>()(
    persist(
        (set) => ({
            ...initialState,
            setToken: token => set({ token: token }),
            setUserData: (payload: SessionData) => {
                if (validateSessionData(payload)) {
                    set({
                        me: payload.user,
                        puppeteer: payload.puppeteer,
                        token: payload.token,
                        isLoggedIn: true,
                    })
                }
            },
            setIsLoggingOut: val => set({ isLoggingOut: val }),
            logout: () =>
                set(state => ({
                    ...initialState,
                    isLoggingOut: state.isLoggingOut,
                })),
            setSettings: settings =>
                set(state => ({
                    me: { ...state.me, userSettings: settings } as User,
                })),
            setEmail: email =>
                set(state => ({
                    me: { ...state.me, username: email } as User,
                })),
            setName: (name, surname) =>
                set(state => ({
                    me: { ...state.me, name: name, surname: surname } as User,
                })),
        }),
        {
            name: 'user', // name of item in the storage (must be unique)
            version: 6,
            storage: createJSONStorage(() => localStorage)
        }
    )
);

export default useMeStore;
