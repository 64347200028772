import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Timesheet, TimesheetDay, TimesheetDayInterval } from '../typings';
import Typography from '@mui/material/Typography';
import grey from '@mui/material/colors/grey';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import TextField from 'components/interface/textField/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import NumberField from 'components/interface/textField/formats/NumberField';
import { WEEKDAY } from '../utils';
import { red, pink } from '@mui/material/colors';
import { Tooltip, lighten } from '@mui/material';
import SickOutlined from '@mui/icons-material/SickOutlined';

const useStyles = makeStyles()(theme => ({
    table: {
        marginBottom: theme.spacing(2),
        background: '#fff',
    },
    headerCell: {
        background: theme.palette.primary.main,
        color: '#fff',
        fontSize: '14px',
    },
    subheaderCell: {
        background: grey[200],
        color: grey[800],
        fontSize: '14px',
        fontWeight: '400',
    },
    cell: {
        'height': '48px',
        'wordBreak': 'break-word',
        'hyphens': 'auto',
        'textAlign': 'center',
        'paddingLeft': theme.spacing(2),
        'paddingRight': theme.spacing(2),
        'border': '1px solid ' + grey[800],
        '&:last-child': {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    cellSums: {
        background: theme.palette.primary['200'],
    },
    publicHoliday: {
        color: pink[900],
        background: pink[50],
    },
    isSickHours: {
        background: lighten(red[50], 0.4),
        borderTop: '2px dotted ' + grey[500],
    },
    sickHoursIcon: {
        color: red[900],
    },
}));

interface Props {
    timesheet: Timesheet;
    canSubmit: boolean;
    loading: boolean;
    enableSickHours: boolean;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
}

export default function StartStopTimesheet({
    timesheet,
    canSubmit,
    loading,
    enableSickHours,
    onUpdateTimesheetDay,
}: Props): JSX.Element {
    const { classes, cx } = useStyles();
    const template = timesheet.template;

    const renderHeaderCell = (text: string, key: number) => {
        return (
            <TableCell
                align='center'
                key={key}
                colSpan={1}
                className={cx(classes.headerCell, classes.cell)}>
                {text}
            </TableCell>
        );
    };

    const renderFooter = () => {
        const { totalTitle, footnote } = template;
        const totalHours = timesheet?.totalHours;
        return (
            <TableRow>
                <TableCell className={classes.cell}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        totalTitle
                    )}
                </TableCell>
                <TableCell
                    colSpan={1}
                    className={cx(classes.cell, classes.cellSums)}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        totalHours || ''
                    )}
                </TableCell>
                <TableCell
                    colSpan={2}
                    className={classes.cell}
                    {...(loading
                        ? {}
                        : {
                              dangerouslySetInnerHTML: {
                                  __html: footnote?.text || '',
                              },
                          })}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='300px'
                        />
                    ) : null}
                </TableCell>
            </TableRow>
        );
    };

    const renderPage = (): JSX.Element => {
        return (
            <Table
                className={classes.table}
                padding='none'
                style={{ tableLayout: 'fixed' }}>
                <TableHead>
                    <TableRow>
                        {renderHeaderCell('', 0)}
                        {renderHeaderCell('Start', 1)}
                        {renderHeaderCell('Slut', 2)}
                        {renderHeaderCell('Rast', 3)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timesheet.days.map((day, index) =>
                        day.isOverflow ? null : (
                            <Row
                                key={index}
                                {...day}
                                index={index}
                                canSubmit={canSubmit}
                                loading={loading}
                                enableSickHours={enableSickHours}
                                onUpdateTimesheetDay={onUpdateTimesheetDay}
                            />
                        )
                    )}
                    {renderFooter()}
                </TableBody>
            </Table>
        );
    };

    return renderPage();
}

interface RowProps extends TimesheetDay {
    index: number;
    canSubmit: boolean;
    loading: boolean;
    enableSickHours: boolean;
    onUpdateTimesheetDay: (
        updateFunc: (day: TimesheetDay) => TimesheetDay,
        index: number
    ) => void;
}

const Row = ({
    month,
    day,
    weekday,
    holiday,
    intervals,
    index,
    canSubmit,
    loading,
    enableSickHours,
    onUpdateTimesheetDay,
}: RowProps) => {
    const { classes, cx } = useStyles();

    const handleUpdateTimesheetDay = (
        value: Partial<TimesheetDayInterval>,
        columnIndex: number,
        isSickHours: boolean
    ) => {
        onUpdateTimesheetDay(prev => {
            const d = { ...prev };
            d.intervals = [...d.intervals];
            d.intervals[columnIndex] = {
                ...d.intervals[columnIndex],
                ...value,
                isSickHours,
            };

            return d;
        }, index);
    };

    const buildIntervalCells = (
        value: TimesheetDayInterval,
        intervalIndex: number
    ) => {
        return (
            <>
                <TableCell
                    key={intervalIndex + '-start'}
                    colSpan={1}
                    className={cx(classes.cell, {
                        [classes.isSickHours]: value.isSickHours,
                    })}>
                    <TextField
                        value={value.start || ''}
                        onChange={v =>
                            handleUpdateTimesheetDay(
                                { start: v },
                                intervalIndex,
                                value.isSickHours
                            )
                        }
                        type='time'
                        error={!value?.start && !!value?.stop}
                        disabled={!canSubmit}
                        variant='standard'
                        noBorder={!(!value?.start && !!value?.stop)}
                        InputProps={{
                            startAdornment: value.isSickHours ? (
                                <InputAdornment position='start'>
                                    <Tooltip
                                        title='Sjuktimmar'
                                        placement='top'
                                        disableInteractive>
                                        <SickOutlined
                                            className={classes.sickHoursIcon}
                                        />
                                    </Tooltip>
                                </InputAdornment>
                            ) : null,
                        }}
                    />
                </TableCell>
                <TableCell
                    key={intervalIndex + '-stop'}
                    colSpan={1}
                    className={cx(classes.cell, {
                        [classes.isSickHours]: value.isSickHours,
                    })}>
                    <TextField
                        value={value?.stop || ''}
                        onChange={v =>
                            handleUpdateTimesheetDay(
                                { stop: v },
                                intervalIndex,
                                value.isSickHours
                            )
                        }
                        type='time'
                        error={!value?.stop && !!value?.start}
                        disabled={!canSubmit}
                        variant='standard'
                        noBorder={!(!value?.stop && !!value?.start)}
                    />
                </TableCell>
                <TableCell
                    key={intervalIndex + '-break'}
                    colSpan={1}
                    className={cx(classes.cell, {
                        [classes.isSickHours]: value.isSickHours,
                    })}>
                    {value.isSickHours ? null : (
                        <NumberField
                            value={value?.breakInMinutes || 0}
                            onChange={v =>
                                handleUpdateTimesheetDay(
                                    { breakInMinutes: v },
                                    intervalIndex,
                                    value.isSickHours
                                )
                            }
                            inputProps={{
                                style: { textAlign: 'right' },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        minuter
                                    </InputAdornment>
                                ),
                            }}
                            disabled={!canSubmit}
                            allowZero
                            maxValue={120}
                            noBorder
                            white={false}
                        />
                    )}
                </TableCell>
            </>
        );
    };

    return (
        <>
            <TableRow>
                <TableCell
                    className={cx(classes.cell, {
                        [classes.publicHoliday]: holiday?.IsPublicHoliday,
                    })}
                    rowSpan={enableSickHours ? 2 : 1}>
                    {loading ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='96px'
                        />
                    ) : (
                        <Tooltip
                            enterTouchDelay={500}
                            title={holiday?.Name}
                            placement='top'
                            disableInteractive>
                            <span>
                                <Typography variant='body2' color='inherit'>
                                    {`${WEEKDAY[weekday]} ${day}/${month}`}
                                </Typography>
                                {holiday?.IsMajorHoliday ? (
                                    <Typography
                                        variant='caption'
                                        color='inherit'>
                                        storhelg
                                    </Typography>
                                ) : null}
                            </span>
                        </Tooltip>
                    )}
                </TableCell>
                {buildIntervalCells(
                    intervals && intervals[0]
                        ? intervals[0]
                        : {
                              start: '',
                              stop: '',
                              breakInMinutes: 0,
                              isSickHours: false,
                          },
                    0
                )}
            </TableRow>
            <TableRow>
                {enableSickHours
                    ? buildIntervalCells(
                          intervals && intervals[1]
                              ? intervals[1]
                              : {
                                    start: '',
                                    stop: '',
                                    breakInMinutes: 0,
                                    isSickHours: true,
                                },
                          1
                      )
                    : null}
            </TableRow>
        </>
    );
};
