import { create } from 'store/create';

type Store = {
    code: number,
    error: boolean,
    setCode: (
        code: number,
    ) => void
    reset: () => void
}

const useHTTPStatusCodeStore = create<Store>(set => ({
    code: 0,
    error: false,
    setCode: (code: number) => set({ code: code, error: code > 0 }),
    reset: () => set({ code: 0, error: false }),
}));

export default useHTTPStatusCodeStore;
