import React, { useEffect, useState } from 'react';

 const useWindowWidth = () => {

    const [windowWidth, setWindowWidth] = useState({
        width: window.innerWidth,
        isMobile: window.innerWidth <= 1023,
        mobileThreshold: 1023,
    });

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        }, []);

    const handleResize = () => {
        const width = window.innerWidth;
        setWindowWidth({
            width: width,
            isMobile: width <= windowWidth.mobileThreshold,
        });
    }


    return windowWidth
}

export default useWindowWidth;