import React from 'react';
import Api from 'api/Api';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import {
    Timesheet,
    AssignmentInfo,
    TIMESHEET_SETTING,
    TimesheetResponse,
    buildTimesheetPayload,
    OtherTimeReportedHoursForWeek,
} from 'components/interface/timesheet/typings';
import useMeStore from 'store/useMeStore';
import { AxiosResponse } from 'axios';

export default function useTimesheet(assignmentID: string) {
    const token = useMeStore(state => state.token);
    const [timesheet, setTimesheet] = React.useState<Timesheet | null>(null);
    const [otherTimeReportedHoursForWeek, setOtherTimeReportedHoursForWeek] =
    React.useState<OtherTimeReportedHoursForWeek>({
        hours: 0,
        count: 0,
    });
    const [assignmentInfo, setAssignmentInfo] =
        React.useState<AssignmentInfo | null>(null);
    const [timesheetSetting, setTimesheetSetting] =
        React.useState<TIMESHEET_SETTING>(TIMESHEET_SETTING.NORMAL);
    const [loading, setLoading] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [enableSickHours, setEnableSickHours] =
        React.useState<boolean>(false);

    const pushNotification = useNotificationStore(state => state.push);

    React.useEffect(() => {
        fetch();
    }, [assignmentID]);

    const fetch = React.useCallback(async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        setError(false);
        try {
            const response = await Api.assignment.fetchAssignmentTimesheet(
                token,
                assignmentID
            );

            const { status, data } =
                response as AxiosResponse<TimesheetResponse>;
            if (status === 200) {
                setAssignmentInfo(
                    data?.assignmentInfo ? data.assignmentInfo : null
                );
                if (data?.timesheetSetting) {
                    setTimesheetSetting(data?.timesheetSetting);
                }
                setOtherTimeReportedHoursForWeek(
                    data?.otherTimeReportedHoursForWeek || {
                        hours: 0,
                        count: 0,
                    }
                );
                if (data.timesheet) {
                    const ts = data.timesheet;
                    setTimesheet(ts);
                }
            } else {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setError(true);
            pushNotification(
                NotificationTypes.error,
                'Det gick inte att hämta tidrapport.',
                'assignment',
                2
            );
        }
        setLoading(false);
    }, [assignmentID, token]);

    const submit = async () => {
        if ((submitting && !success) || !timesheet) {
            return;
        }
        setSuccess(false);
        setSubmitting(true);
        try {
            const response = await Api.assignment.submitAssignmentTimesheet(
                token,
                assignmentID,
                buildTimesheetPayload(
                    timesheet,
                    enableSickHours,
                    timesheetSetting
                )
            );
            const { status } = response;
            if (status === 200) {
                setSuccess(true);
                fetch();
                pushNotification(
                    NotificationTypes.success,
                    'Tidrapporten har skickats in.',
                    'assignment',
                    1
                );
            } else {
                throw new Error('');
            }
        } catch (err: any) {
            console.log(err);
            pushNotification(
                NotificationTypes.error,
                err?.response?.data?.message || 'Det gick inte att skicka in tidrapporten, försök igen.',
                'assignment',
                2
            );
        }
        setSubmitting(false);
    };

    return {
        submit,
        timesheet,
        setTimesheet,
        assignmentInfo,
        timesheetSetting,
        loading,
        submitting,
        success,
        error,
        enableSickHours,
        otherTimeReportedHoursForWeek,
        setEnableSickHours,
    };
}
