import React from 'react';
import App from 'pages/App';
import useDocumentTitle from 'components/hooks/useDocumentTitle';
import useWindowWidth from 'components/hooks/useWindowWidth';
import LandingPageWrapper from 'components/landingPage/Wrapper';
import api from 'api/Api';
import { EMAIL_TYPE } from 'typings/email';
import { useLocation } from 'react-router';
import Content from './Content';

export default function UnsubscribeFromEmailPage() {
    useDocumentTitle('Avregistrera email');
    const { isMobile } = useWindowWidth();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        console.log(id)
        if (id === null) {
            setError(true);
            setLoading(false)
            return;
        }
        const type = params.get('type');
        unsubscribeFromEmailsOfType(id, type as EMAIL_TYPE );
    }, []);

    const unsubscribeFromEmailsOfType = async (id: string, emailType: EMAIL_TYPE) => {
        try {
            const response = await api.email.unsubscribeFromEmailsOfType(id, emailType);
            const { status } = response;
            if (status !== 200) {
                throw new Error('');
            }
        } catch (err) {
            console.log(err);
            setError(true);
        }
        setLoading(false);
    };

    return (
        <App>
            <LandingPageWrapper isMobile={isMobile} loading={loading}>
                <Content loading={loading} error={error} />
            </LandingPageWrapper>
        </App>
    );
}
