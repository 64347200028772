import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import TextField, { Label } from 'components/interface/textField/TextField';
import Button from 'components/interface/Button/Button';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import Api from 'api/Api';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import useMeStore from 'store/useMeStore';

const useStyles = makeStyles<void, 'loading'>()((theme, _params, classes) => ({
    body: {
        maxWidth: '350px',
        width: '100%',
        flexShrink: 0,
        opacity: 1,
        transition: 'opacity 2.0s ease-in-out',
        [`&.${classes.loading}`]: {
            transition: 'opacity 1.0s ease-in-out',
            opacity: 0,
        },
    },
    welcomeText: {
        userSelect: 'none',
        color: theme.palette.primaryDark.main,
        paddingBottom: theme.spacing(4),
    },
    welcomeTextTitle: {
        paddingBottom: theme.spacing(1),
    },
    textFieldBase: {
        width: '100%',
        maxWidth: '350px',
        flexShrink: 0,
        marginTop: theme.spacing(0.5),
    },
    textField: {
        maxWidth: '350px',
    },
    buttonWrapper: {
        paddingTop: theme.spacing(4),
    },
    sendButton: {
        width: '100%',
        height: '40px',
    },
    gotToLoginButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '50%',
        textDecoration: 'underline',
        color: theme.palette.primaryDark.main,
        userSelect: 'none',
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: theme.spacing(2),
    },
    label: {
        'color': theme.palette.primaryDark.main,
        '& p': {
            fontWeight: 'bold',
        },
    },
    desktop: {},
    loading: {},
}));

// Minimum amount of time that the loading should take, in ms.
// This ensures that the loading animation is smooth
const LOADING_MIN_TIME = 1200;

interface Props {
    setLoading: (v: boolean) => void;
    hash: string;
}

export default function ReplySMS({
    setLoading: setParentLoading,
    hash,
}: Props) {
    const { classes } = useStyles();
    const [message, setMessage] = React.useState('');
    const [sent, setSent] = React.useState(false);
    const placeholder = 'Jag är intresserad';
    const maxMessageLength = 200;
    const [loading, setLoading] = React.useState(true);
    const pushNotification = useNotificationStore(state => state.push);
    const isLoggedIn = useMeStore(state => state.isLoggedIn);

    React.useEffect(() => {
        setLoading(false);
    }, []);

    const delaySetLoading = (success: boolean, start: Date) => {
        const end = new Date();
        var diffMS = LOADING_MIN_TIME - (end.getTime() - start.getTime());
        setTimeout(
            () => {
                setParentLoading(false);
                setLoading(false);
                setSent(success);
            },
            diffMS > 0 ? diffMS : 1
        );
    };

    const send = async () => {
        setLoading(true);
        setParentLoading(true);
        const start = new Date();
        try {
            const resp = await Api.openAPI.replySMS(
                hash,
                message.length > 0 ? message : placeholder
            );
            const { status } = resp;
            if (status === 200) {
                delaySetLoading(true, start);
            } else {
                throw new Error('');
            }
        } catch (err: any) {
            delaySetLoading(false, start);
            pushNotification(
                NotificationTypes.error,
                err?.response?.data?.message || 'Något gick fel, försök igen.',
                'sms-fail',
                1
            );
        }
    };

    const handleMessageUpdate = (v: string) => {
        if (v.length > maxMessageLength) {
            v = v.substring(0, maxMessageLength);
        }
        setMessage(v);
    };

    const buttons = () => {
        return (
            <div className={classes.buttonWrapper}>
                {sent ? (
                    <>
                        <div className={classes.gotToLoginButton}>
                            <NavLink exact to={isLoggedIn ? '/' : '/logga-in'}>
                                <Typography variant='body2' color='inherit'>
                                    <b>Tillbaka till login</b>
                                </Typography>
                            </NavLink>
                        </div>
                    </>
                ) : (
                    <Button
                        color='primaryDark'
                        variant='contained'
                        onClick={send}
                        className={classes.sendButton}
                        disableRipple
                        disableElevation>
                        <Typography variant='body1' color='inherit'>
                            <b>Skicka svar</b>
                        </Typography>
                    </Button>
                )}
            </div>
        );
    };
    const textFields = () => {
        return (
            <div className={classes.inputWrapper}>
                <div className={classes.label}>
                    <Label
                        text={
                            'Frivilligt meddelande (' +
                            message.length +
                            '/' +
                            maxMessageLength +
                            ')'
                        }
                        inheritColor
                    />
                </div>
                <TextField
                    id='message'
                    classes={{
                        base: classes.textFieldBase,
                        field: classes.textField,
                    }}
                    value={message || ''}
                    onChange={handleMessageUpdate}
                    placeholder={placeholder}
                    variant='filled'
                    multiline
                    minRows={5}
                    maxRows={5}
                    white
                    autoFocus
                    noAutofillColor
                />
            </div>
        );
    };

    return (
        <div
            className={clsx(classes.body, {
                [classes.loading]: loading,
            })}>
            <div className={classes.welcomeText}>
                <Typography variant='h5' className={classes.welcomeTextTitle}>
                    <b>{sent ? 'Tack för ditt svar!' : 'Intresseanmälan'}</b>
                </Typography>
                <Typography variant='body1'>
                    {sent
                        ? 'Nu är allt klart, du kan stänga ned fliken.'
                        : 'Fyll i ett frivilligt meddelande nedan. Klicka sedan på knappen för att skicka.'}
                </Typography>
            </div>
            {sent ? null : textFields()}
            {buttons()}
        </div>
    );
}
