import React from 'react';
import useWindowWidth from 'components/hooks/useWindowWidth';
import SnackbarWrapper from 'components/interface/SnackbarWrapper';
import { useTheme } from '@mui/material/styles';
import useNotificationStore from 'store/useNotificationStore';

export default function NotificationManager({}) {
    const theme = useTheme();
    const notifications = useNotificationStore(state => state.notifications);
    const pop = useNotificationStore(state => state.pop);

    const { isMobile } = useWindowWidth();

    const renderNotification = () => {
        const { duration } = theme.transitions;
        const notification = notifications[0];
        const d = new Date().getTime();

        const diff = notification.shownTimestamp
            ? d - notification.shownTimestamp
            : 0;
        return (
            <SnackbarWrapper
                variant={notification.type || 'info'}
                isOpen={true}
                key={notification.timestamp}
                message={notification.message?.toString() || ''}
                onClose={pop}
                isMobile={isMobile}
                autoHideDuration={4000 - diff}
                transitionDuration={{
                    enter:
                        diff > duration.enteringScreen
                            ? 0
                            : duration.enteringScreen,
                    exit: duration.leavingScreen,
                }}
            />
        );
    };

    return <>{notifications.length ? renderNotification() : undefined}</>;
}
