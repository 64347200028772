import React from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    hasLowercase,
    hasUppercase,
    hasDigit,
    hasOnlyLegalCharacters,
    PASSWORD_MIN_LENGTH,
} from 'utils/utils_legacy';
import Typography from '@mui/material/Typography';
import { red, green } from '@mui/material/colors';
import SuccessIcon from '@mui/icons-material/Done';
import FailIcon from '@mui/icons-material/Close';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    base: {
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    item: {
        'display': 'flex',
        'alignItems': 'center',
        '&:not(:first-of-type)': {
            marginTop: theme.spacing(0.5),
        },
        'color': red[500],
    },
    icon: {
        marginRight: theme.spacing(0.5),
    },
    success: {
        color: green[900],
    },
    tooltipWrapper: {
        width: '100%',
    },
    tooltip: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
    },
    tooltipArrow: {
        color: '#fff',
    },
}));

interface PasswordRequirementsChecker {
    password: string;
    tooltip?: boolean;
    children?: React.ReactNode;
    placement?: TooltipProps['placement'];
    open?: boolean;
}

export default function PasswordRequirementsChecker({
    password,
    tooltip,
    children,
    placement,
    open,
}: PasswordRequirementsChecker) {
    const { classes, cx } = useStyles();
    const [amount, setAmount] = React.useState(false);
    const [lowercase, setLowercase] = React.useState(false);
    const [uppercase, setUppercase] = React.useState(false);
    const [digit, setDigit] = React.useState(false);
    const [onlyLegalCharacters, setOnlyLegalCharacters] = React.useState(false);

    React.useEffect(() => {
        setAmount(password.length >= PASSWORD_MIN_LENGTH);
        setLowercase(hasLowercase(password));
        setUppercase(hasUppercase(password));
        setDigit(hasDigit(password));
        setOnlyLegalCharacters(hasOnlyLegalCharacters(password));
    }, [password]);

    const render = () => (
        <div className={classes.base}>
            <Typography
                className={classes.title}
                variant='body1'
                color='textPrimary'>
                <b>Lösenordskrav</b>
            </Typography>
            <div className={cx(classes.item, { [classes.success]: amount })}>
                {amount ? (
                    <SuccessIcon className={classes.icon} fontSize='small' />
                ) : (
                    <FailIcon className={classes.icon} fontSize='small' />
                )}
                <Typography variant='body2' color='inherit'>
                    <b>Minst 8 tecken</b>
                </Typography>
            </div>
            <div className={cx(classes.item, { [classes.success]: lowercase })}>
                {lowercase ? (
                    <SuccessIcon className={classes.icon} fontSize='small' />
                ) : (
                    <FailIcon className={classes.icon} fontSize='small' />
                )}
                <Typography variant='body2' color='inherit'>
                    <b>Minst 1 liten bokstav</b>
                </Typography>
            </div>
            <div className={cx(classes.item, { [classes.success]: uppercase })}>
                {uppercase ? (
                    <SuccessIcon className={classes.icon} fontSize='small' />
                ) : (
                    <FailIcon className={classes.icon} fontSize='small' />
                )}
                <Typography variant='body2' color='inherit'>
                    <b>Minst 1 stor bokstav</b>
                </Typography>
            </div>
            <div className={cx(classes.item, { [classes.success]: digit })}>
                {digit ? (
                    <SuccessIcon className={classes.icon} fontSize='small' />
                ) : (
                    <FailIcon className={classes.icon} fontSize='small' />
                )}
                <Typography variant='body2' color='inherit'>
                    <b>Minst 1 siffra</b>
                </Typography>
            </div>
            <div
                className={cx(classes.item, {
                    [classes.success]: onlyLegalCharacters,
                })}>
                {onlyLegalCharacters ? (
                    <SuccessIcon className={classes.icon} fontSize='small' />
                ) : (
                    <FailIcon className={classes.icon} fontSize='small' />
                )}
                <Typography variant='body2' color='inherit'>
                    <b>
                        Endast tillåtna specialsymboler ! @ # $ % ^ & *
                        (frivilligt)
                    </b>
                </Typography>
            </div>
        </div>
    );

    return !!tooltip ? (
        <Tooltip
            open={open === undefined ? true : open}
            title={render()}
            placement={placement || 'right'}
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}>
            <div className={classes.tooltipWrapper}>{children}</div>
        </Tooltip>
    ) : (
        render()
    );
}
