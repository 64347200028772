import { create } from 'store/create';

const initialState = {
    rows: [],
    count: 0,
    timestamp: null,
};

export const useListStore = create(set => ({
    ...initialState,
    set: payload =>
        set({
            rows: payload.rows,
            count: payload.count,
            timestamp: new Date().getTime(),
        }),
    // This can be done when fetching the page for the assignment, so it updates the row with the new data
    updateRow: payload =>
        set(state => ({
            rows: state.rows.map(v =>
                v.uuid === payload.uuid ? { ...payload } : v
            ),
        })),
    reset: () => set({ ...initialState }),
}));

const initialFilterState = {
    page: 0,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    onlyCurrent: true,
    orderAttr: 'asc',
    orderBy: 'week',
    orderByOptions: [
        { value: 'week', name: 'Vecka' },
        { value: 'careplace', name: 'Vårdgivare' },
    ],
    version: 1, // this is to identify changes
};

export const useFilterStore = create(set => ({
    ...initialFilterState,
    set: payload =>
        set(state => ({ ...payload, version: new Date().getTime() })),
    reset: payload =>
        set({
            ...initialFilterState,
            ...payload,
            version: new Date().getTime(),
        }),
}));
