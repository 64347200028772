import React from 'react';

import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/lab/Skeleton';

const useStyles = makeStyles()(theme => ({
    base: {
        userSelect: 'none',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(4),
    },
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-start',
    },
    subtitle: {
        marginLeft: theme.spacing(2),
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexShrink: '0',
    },
    noTopMargin: {
        marginTop: 0,
    },
    description: {
        paddingTop: theme.spacing(1),
    },
}));

interface Props {
    children?: JSX.Element | Array<JSX.Element>;
    title?: string | Function;
    subtitle?: string | null;
    loading?: boolean;
    noTopMargin?: boolean;
    description?: string;
}

export default function PageInfoText({
    children,
    title,
    subtitle,
    loading,
    noTopMargin,
    description,
}: Props): JSX.Element {
    const { classes, cx } = useStyles();

    const renderTitle = () =>
        typeof title === 'function' ? (
            title()
        ) : (
            <Typography variant='h3' color='textPrimary'>
                <b>{title}</b>
            </Typography>
        );
    const renderSubtitle = () => (
        <div className={classes.subtitle}>
            {loading || !subtitle ? (
                <Skeleton
                    animation='pulse'
                    variant='text'
                    width='120px'
                    height='32px'
                />
            ) : (
                <Typography variant='h5' color='textPrimary'>
                    {subtitle}
                </Typography>
            )}
        </div>
    );
    return (
        <div
            className={cx(classes.base, {
                [classes.noTopMargin]: noTopMargin,
            })}>
            <div className={classes.header}>
                <div className={classes.titleWrapper}>
                    {loading || !title ? (
                        <Skeleton
                            animation='pulse'
                            variant='text'
                            width='356px'
                            height='56px'
                        />
                    ) : (
                        renderTitle()
                    )}
                    {!!subtitle ? renderSubtitle() : undefined}
                </div>
                <div className={classes.actions}>{children}</div>
            </div>
            {description ? (
                <Typography
                    className={classes.description}
                    variant='body1'
                    color='textPrimary'>
                    {description}
                </Typography>
            ) : undefined}
        </div>
    );
}
