import { Typography } from '@mui/material';
import React from 'react';
import { Answer, Question } from './typings';
import { makeStyles } from 'tss-react/mui';
import TextField from 'components/interface/textField/TextField';
import {
    MultipleChoiceOption,
    MultipleChoiceOptionButton,
} from './MultipleChoicesType';

const useStyles = makeStyles<void>()((theme, _params, _classes) => ({
    booleanOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: theme.spacing(2),
    },
    booleanOptions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: theme.spacing(2),
    },
}));

interface BooleanTypeProps {
    question: Question;
    answer: Answer;
    onChange: (updateFunc: (prev: Answer) => Answer) => void;
}

export default function BooleanType({
    question,
    answer,
    onChange,
}: BooleanTypeProps) {
    const { classes } = useStyles();

    return (
        <div className={classes.booleanOption}>
            {question.allowComment && (
                <TextField
                    value={answer.comment}
                    onChange={v =>
                        onChange(prev => ({
                            ...prev,
                            comment: v,
                        }))
                    }
                    placeholder='Kommentar'
                    multiline
                />
            )}
            <div className={classes.booleanOptions}>
                <MultipleChoiceOption>
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        Ja
                    </Typography>
                    <MultipleChoiceOptionButton
                        onClick={() =>
                            onChange(prev => ({
                                ...prev,
                                answer: true,
                            }))
                        }>
                        {answer.answer === true ? '✓' : ''}
                    </MultipleChoiceOptionButton>
                </MultipleChoiceOption>
                <MultipleChoiceOption>
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        Nej
                    </Typography>
                    <MultipleChoiceOptionButton
                        onClick={() =>
                            onChange(prev => ({
                                ...prev,
                                answer: false,
                            }))
                        }>
                        {answer.answer === false ? '✓' : ''}
                    </MultipleChoiceOptionButton>
                </MultipleChoiceOption>
            </div>
        </div>
    );
}
