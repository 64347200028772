export interface Profession {
    id: number;
    label: string;
    identifier: ProfessionIdentifier;
}

export enum ProfessionIdentifier {
    Doctor = "DOK",
    Nurse = "SSK",
    AssistantNurse = "USK",
    Sociologist = "SOCIONOM",
    None = '',
}