
// Source https://www.quirksmode.org/js/cookies.html
export function setCookie(name,value,days,domain) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/" + ((domain) ? "; Domain=" + domain : "");
}

// Source https://www.quirksmode.org/js/cookies.html
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

// Source https://www.quirksmode.org/js/cookies.html
export function eraseCookie(name, domain) {
    setCookie(name,"",-1, domain);
}