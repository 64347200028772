import React from 'react';
import { makeStyles } from 'tss-react/mui';
import useWindowWidth from 'components/hooks/useWindowWidth';

const useStyles = makeStyles<void, "mobile">()((theme, _, classes) => ({
    content: {
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'flex-start',
        'alignItems': 'center',
        'maxWidth': '1414px',
        'width': '100%',
        'height': '100%',
        'paddingBottom': '250px',
        '@media (max-width: 1478px)': {
            //1478 = 1414+64
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        [`&.${classes.mobile}`]: {
            padding: theme.spacing(0, 2),
            paddingBottom:
                'calc(' +
                theme.spacing(8) +
                ' + env(safe-area-inset-bottom))',
        },
    },
    mobile: {},
}));

interface Props {
    children: JSX.Element | Array<JSX.Element>;
    classes?: string;
}

export default function Content({ children, classes: classesProps }: Props): JSX.Element {
    const { classes, cx } = useStyles();
    const { isMobile } = useWindowWidth();
    return (
        <div
            className={cx(
                classes.content,
                { [classes.mobile]: isMobile },
                classesProps
            )}>
            {children}
        </div>
    );
}
