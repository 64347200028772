import axios from 'axios';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import useHTTPStatusCodeStore from 'store/useHTTPStatusCodeStore';
import { Logout } from 'store/logout';

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Checks if there is an error
        if (response.status !== 200) {
            handleUnauthorized(response);
            return Promise.reject(response);
        }
        return response;
    },
    function (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            handleUnauthorized(error.response);
        }
        return Promise.reject(error);
    }
);

const handleUnauthorized = response => {
    const { status, data } = response;
    const pushNotification = useNotificationStore.getState().push;
    const setCode = useHTTPStatusCodeStore.getState().setCode;

    // UNAUTHORIZED
    if (status === 401) {
        const message = data;
        // if the token has expired, should be able to refresh in the future!
        const expired = !message.invalid && message.expired;
        // if the token is invalid, should fire GA event.
        const invalid = message.invalid;

        const method = response?.config?.method;
        if (
            !expired &&
            !invalid &&
            method !== 'post' &&
            method !== 'patch' &&
            method !== 'delete' &&
            method !== 'put'
        ) {
            // redirect to 401 page here
            setCode(status);
        } else if (
            method === 'post' ||
            method === 'patch' ||
            method === 'delete' ||
            method === 'put'
        ) {
            pushNotification(
                NotificationTypes.error,
                'Du saknar behörighet.',
                'unauthorized',
                1
            );
        }

        // Should not check for expired when we handle token refreshing
        if (expired || invalid) {
            Logout()
        }
    }
};

export default axios;
