import React from "react";

import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import grey from "@mui/material/colors/grey";
import Skeleton from "@mui/lab/Skeleton";

const useStyles = makeStyles()((theme, params, classes) => ({
    table: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    rowWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        marginBottom: theme.spacing(2),
        [`&.${classes.mobile}`]: {
            marginBottom: theme.spacing(1),
        },
    },
    row: {
        display: "flex",
        "& + &": {
            marginTop: theme.spacing(0.5),
        },
    },
    rowNoSelect: {
        userSelect: "none",
    },
    spacer: {
        display: "flex",
        flex: "1 1 0%",
        borderBottom: "1px solid " + grey[300],
        margin: "0 5px 4px",
    },
    mobile: {},
}));

const DetailsTable = ({ children, title, isMobile }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={classes.table}>
            <Typography
                className={cx(classes.title, { [classes.mobile]: isMobile })}
                variant={isMobile ? "h6" : "body2"}
                color="textPrimary"
            >
                <b>{title}</b>
            </Typography>
            <div className={classes.rowWrapper}>{children}</div>
        </div>
    );
};

const DetailsRow = ({
    name,
    value,
    tooltipText,
    tooltipPlacement,
    noSelect,
    loading,
    isMobile,
}) => {
    const { classes, cx } = useStyles();

    const row = () => (
        <div className={cx(classes.row, { [classes.rowNoSelect]: noSelect })}>
            <Typography
                align="left"
                variant={isMobile ? "body1" : "body2"}
                color="textSecondary"
            >
                {name}
            </Typography>
            <div className={classes.spacer}></div>
            {loading ? (
                <Skeleton animation="pulse" variant="text" width="64px" />
            ) : (
                <Typography
                    align="right"
                    variant={isMobile ? "body1" : "body2"}
                    color="textPrimary"
                >
                    {value}
                </Typography>
            )}
        </div>
    );
    const withTooltip = () => (
        <Tooltip
            enterTouchDelay={500}
            title={tooltipText}
            placement={tooltipPlacement}
        >
            {row()}
        </Tooltip>
    );
    if (tooltipText) {
        return withTooltip();
    } else {
        return row();
    }
};

export { DetailsTable, DetailsRow };
