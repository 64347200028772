import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware';

export enum LOGIN_METHOD {
    NORMAL= 'NORMAL',
    BANKID = 'BANKID',
}

type State = {
    method: LOGIN_METHOD,
}

type Actions = {
    setMethod: (token: LOGIN_METHOD) => void;
}

type Store = State & Actions

const initialState: State = {
    method: LOGIN_METHOD.NORMAL,
};

// This store will not reset with all the other stores, as it uses the normal create function.
const useLoginStore = create<Store>()(
    persist(
        (set) => ({
            ...initialState,
            setMethod: (method: LOGIN_METHOD) => set({ method: method }),
        }),
        {
            name: 'login', // name of item in the storage (must be unique)
            version: 1,
            storage: createJSONStorage(() => localStorage)
        }
    )
);

export default useLoginStore;