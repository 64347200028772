import { styled, Typography } from '@mui/material';
import React from 'react';
import { Answer, Question } from './typings';
import { grey } from '@mui/material/colors';

const MultipleChoices = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gap: theme.spacing(4),
}));

export const MultipleChoiceOption = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: theme.spacing(1),
}));

export const MultipleChoiceOptionButton = styled('div')(({ theme }) => ({
    'padding': theme.spacing(1),
    'minHeight': '32px',
    'minWidth': '32px',
    'maxHeight': '32px',
    'maxWidth': '32px',
    'borderRadius': 16,
    'cursor': 'pointer',
    'backgroundColor': grey[300],
    'transition': 'background-color 0.2s',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'fontSize': '32px',
    'fontWeight': 700,
    '&:hover': {
        backgroundColor: grey[400],
    },
}));

interface MultipleChoicesTypeProps {
    question: Question;
    answer: Answer;
    onChange: (updateFunc: (prev: Answer) => Answer) => void;
}

export default function MultipleChoicesType({
    question,
    answer,
    onChange,
}: MultipleChoicesTypeProps) {
    const a = answer.answer as string[];
    return (
        <MultipleChoices>
            {question.options.map(option => (
                <MultipleChoiceOption key={option}>
                    <Typography
                        variant='body2'
                        color='textPrimary'
                        fontFamily='Poppins'>
                        {option}
                    </Typography>
                    <MultipleChoiceOptionButton
                        onClick={() =>
                            onChange(prev => {
                                const a = (prev.answer as string[]) || [];
                                return {
                                    ...prev,
                                    answer: a.includes(option)
                                        ? a.filter(o => o !== option)
                                        : [...a, option],
                                };
                            })
                        }>
                        {a.includes(option) ? '✓' : ''}
                    </MultipleChoiceOptionButton>
                </MultipleChoiceOption>
            ))}
        </MultipleChoices>
    );
}
