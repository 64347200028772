import Alert, { STATUS } from 'components/interface/alert/Alert';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { formatHoursToHHMM } from 'utils/utils_legacy';
import { minutesToHoursDecimals } from 'utils/utils';
import {
    calculateTotalReportedTimeAsNumber,
    OtherTimeReportedHoursForWeek,
    Timesheet,
    TIMESHEET_SETTING,
    TimesheetDayInterval,
} from '../typings';

const useStyles = makeStyles()(theme => ({
    errors: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),
    },
    error: {
        '&:not(:first-of-type)': {
            marginTop: theme.spacing(1),
        },
    },
}));

const calculateRestError = (timesheet: Timesheet): Array<React.ReactNode> => {
    // Returns the rest between two days in hours
    const getRestBetween = (
        _from?: TimesheetDayInterval,
        _to?: TimesheetDayInterval
    ): number => {
        if (!_from || !_to) {
            return 24;
        }
        const fromStart = _from?.start?.split(':');
        const from = _from?.stop?.split(':');
        const to = _to?.start?.split(':');
        if (!from || !to) {
            return 24;
        }
        // calculate rest between two times

        const fromStartHour = parseInt(fromStart[0]);
        let fromHour = parseInt(from[0]);
        // this is for example if the from time is 23:00 and the to time is 01:00
        // we should add 24 to the fromHour so we can compare correctly
        if (fromHour < fromStartHour) {
            fromHour += 24; // we should always add 24 to the fromHour so we can compare correctly
        }
        const fromMinute = parseInt(from[1]);
        const toHour = parseInt(to[0]) + 24; // we should always add 24 to the toHour so we can compare correctly
        const toMinute = parseInt(to[1]);

        return (
            toHour -
            fromHour +
            minutesToHoursDecimals(toMinute - fromMinute + '')
        );
    };
    const monToTue = getRestBetween(
        timesheet.days[0]?.intervals[0],
        timesheet.days[1]?.intervals[0]
    );
    const tueToWed = getRestBetween(
        timesheet.days[1]?.intervals[0],
        timesheet.days[2]?.intervals[0]
    );
    const wedToThu = getRestBetween(
        timesheet.days[2]?.intervals[0],
        timesheet.days[3]?.intervals[0]
    );
    const thuToFri = getRestBetween(
        timesheet.days[3]?.intervals[0],
        timesheet.days[4]?.intervals[0]
    );
    const friToSat = getRestBetween(
        timesheet.days[4]?.intervals[0],
        timesheet.days[5]?.intervals[0]
    );
    const satToSun = getRestBetween(
        timesheet.days[5]?.intervals[0],
        timesheet.days[6]?.intervals[0]
    );

    const errors: Array<React.ReactNode> = [];

    if (monToTue < 11) {
        errors.push(
            <i key='1'>
                <br />
                Måndag till tisdag {formatHoursToHHMM(monToTue, true)}.
            </i>
        );
    }
    if (tueToWed < 11) {
        errors.push(
            <i key='2'>
                <br />
                Tisdag till onsdag {formatHoursToHHMM(tueToWed, true)}.
            </i>
        );
    }
    if (wedToThu < 11) {
        errors.push(
            <i key='3'>
                <br />
                Onsdag till torsdag {formatHoursToHHMM(wedToThu, true)}.
            </i>
        );
    }
    if (thuToFri < 11) {
        errors.push(
            <i key='4'>
                <br />
                Torsdag till fredag {formatHoursToHHMM(thuToFri, true)}.
            </i>
        );
    }
    if (friToSat < 11) {
        errors.push(
            <i key='5'>
                <br />
                Fredag till lördag {formatHoursToHHMM(friToSat, true)}.
            </i>
        );
    }
    if (satToSun < 11) {
        errors.push(
            <i key='6'>
                <br />
                Lördag till söndag {formatHoursToHHMM(satToSun, true)}.
            </i>
        );
    }

    return errors;
};

interface Props {
    timesheet: Timesheet;
    timesheetSetting: TIMESHEET_SETTING;
    show40hWarning: boolean;
    showRestWarning: boolean;
    otherTimeReportedHoursForWeek: OtherTimeReportedHoursForWeek;
}

// Only use for start stop timesheets.
const Warnings = ({
    timesheet,
    timesheetSetting,
    show40hWarning,
    showRestWarning,
    otherTimeReportedHoursForWeek,
}: Props): JSX.Element => {
    const { classes } = useStyles();

    const renderError = () => {
        const errors: Array<React.ReactNode> = [];
        if (show40hWarning) {
            const total = timesheet
                ? calculateTotalReportedTimeAsNumber(
                      timesheet,
                      timesheetSetting
                  )
                : 0;
            const othersHours = otherTimeReportedHoursForWeek.hours || 0;
            const othersCount = otherTimeReportedHoursForWeek.count || 0;
            if (total + othersHours > 40) {
                errors.push(
                    <Alert
                        key={'40h'}
                        classes={classes.error}
                        status={STATUS.WARNING}
                        text={
                            <>
                                Obs! Veckoarbetstid överstiger 40 timmar, se
                                övertidsregler för gällande uppdrag.
                                {othersHours > 0 && (
                                    <>
                                        <i>
                                            <br />
                                            Du har tidrapporterat{' '}
                                            <b>{othersHours}</b> timmar för{' '}
                                            <b>
                                                {othersCount === 1
                                                    ? 'ett annat'
                                                    : `${othersCount} andra`}
                                            </b>{' '}
                                            uppdrag under vecka {timesheet.week}
                                            .
                                        </i>
                                        <i>
                                            <br />
                                            Totalt <b>
                                                {total + othersHours}
                                            </b>{' '}
                                            timmar.
                                        </i>
                                    </>
                                )}
                            </>
                        }
                        withBorder
                    />
                );
            }
        }

        // only works for start stop timesheets
        if (
            showRestWarning &&
            timesheetSetting === TIMESHEET_SETTING.START_STOP_TIME
        ) {
            const restError = calculateRestError(timesheet);
            if (restError.length > 0) {
                errors.push(
                    <Alert
                        key='restErr'
                        classes={classes.error}
                        status={STATUS.WARNING}
                        text={
                            <>
                                Obs! Dygnsvila är lägre än 11h, se gällande
                                regler.
                                {restError}
                            </>
                        }
                        withBorder
                    />
                );
            }
        }

        if (errors.length > 0) {
            return <div className={classes.errors}>{errors}</div>;
        }
        return null;
    };

    return <>{renderError()}</>;
};

const WarningsMemo = React.memo(Warnings, (prevProps, curProps) => {
    return prevProps.timesheet?.totalHours === curProps.timesheet?.totalHours;
});

export default WarningsMemo;
