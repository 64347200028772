import axios from './Axios';
import Api from './Api';

export default class PDFApi {

    /**
     * Fetches an PDF.
     * @param {JWT} token - The token of the account.
     * @param {string} type - The type of PDF that is requested
     * @param {Object} data - The data that should be sent in the request
     * @returns  A Axios promise
     */
    fetchPDF(token = undefined, type = '', data = {}) {
        return axios({
            method: 'POST',
            timeout: Api.Timeout,
            url: `${Api.Host}/include/api/pdf/create/pdf_token.php`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + token
            },
            data : new URLSearchParams({
                type: type,
                data: JSON.stringify(data)}).toString(),
        });
    }
}