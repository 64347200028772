import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import { Answers, QuestionnaireSimple, answersToArray } from '../typings';
import React from 'react';
import api from 'api/Api';

interface UseQuestionnaireProps {
    uuid: string;
}

export default function useQuestionnaire({ uuid }: UseQuestionnaireProps) {
    const pushNotification = useNotificationStore(state => state.push);
    const [questionnaire, setQuestionnaire] =
        React.useState<QuestionnaireSimple | null>(null);
    const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(false);

    React.useEffect(() => {
        fetch();
    }, [uuid]);

    const fetch = async () => {
        setLoading(true);
        setFetchError(false);
        try {
            const resp = await api.questionnaire.getQuestionnaire(uuid);
            const { data, status } = resp;
            if (status === 200) {
                setQuestionnaire(data.questionnaire);
                setIsSubmitted(data.isSubmitted);
            } else {
                throw new Error('');
            }
        } catch (err: any) {
            pushNotification(
                NotificationTypes.error,
                err?.response?.data?.message || 'Något gick fel, försök igen.',
                'questionnaire',
                1
            );
            setFetchError(true);
        }
        setLoading(false);
    };

    const submit = async (answers: Answers) => {
        setLoading(true);
        setError(false);
        try {
            const resp = await api.questionnaire.submitQuestionnaire(
                uuid,
                answersToArray(answers)
            );
            const { status } = resp;
            if (status === 200) {
                setIsSubmitted(true);
            } else {
                throw new Error('');
            }
        } catch (err: any) {
            pushNotification(
                NotificationTypes.error,
                err?.response?.data?.message || 'Något gick fel, försök igen.',
                'questionnaire',
                1
            );
            setError(true);
        }
        setLoading(false);
    };

    const resetError = () => {
        setError(false);
        setFetchError(false);
    };

    return {
        questionnaire,
        isSubmitted,
        loading,
        error,
        fetchError,
        submit,
        resetError,
    };
}
