import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = makeStyles()((theme, params, classes) => ({
    user: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "center",
        userSelect: 'none',
    },
    avatar: {
        width: "32px",
        height: "32px",
        maxWidth: "32px",
        maxHeight: "32px",
        minWidth: "32px",
        minHeight: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.primary[100],
        borderRadius: "50px",
        marginRight: theme.spacing(1),
        userSelect: "none",
    },
    avatarIcon: {
        color: theme.palette.primary[900],
    },
    userInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    mobileText: {
        color: theme.palette.primaryDark[500],
    },
}));

export default function UserAvatar({ me, puppeteer, isMobile }) {
    const { classes, cx } = useStyles();

    const remoteNameLabel = (fullName) => (
        <Typography
            variant="caption"
            color="textPrimary"
            className={classes.remoteLogin}
        >
            Inloggad av {puppeteer.name + " " + puppeteer.surname}
        </Typography>
    );

    const desktop = () => (
        <>
            <Typography variant="subtitle2" color="textPrimary">
                {me?.name?.split(" ")[0] + " " + me?.surname?.split(" ")[0]}
            </Typography>
            <Typography variant="caption" color="textSecondary">
                {me?.username}
            </Typography>
        </>
    );

    const mobile = () => (
        <>
            <Typography
                variant="body1"
                color="inherit"
                className={classes.mobileText}
            >
                <b>
                    {me?.name?.split(" ")[0] +
                        " " +
                        me?.surname?.split(" ")[0]}
                </b>
            </Typography>
            <Typography
                variant="caption"
                color="inherit"
                className={classes.mobileText}
            >
                <b>{me?.username}</b>
            </Typography>
        </>
    );

    return (
        <>
            {!!puppeteer && !!puppeteer?.id
                ? remoteNameLabel()
                : undefined}
            <div className={classes.user}>
                <div className={classes.avatar}>
                    <PersonIcon className={classes.avatarIcon} />
                </div>
                <div className={classes.userInfo}>
                    {isMobile ? mobile() : desktop()}
                </div>
            </div>
        </>
    );
}

UserAvatar.propTypes = {
    me: PropTypes.object.isRequired,
    puppeteer: PropTypes.object,
    isMobile: PropTypes.bool,
};
