    import axios from './Axios';
import Api from './Api';



export default class StaffAPI {

    /**
     * Fetches the staff tites.
     * @param {JWT} token - The token of the account.
     * @returns  A Axios promise
     */
    fetchStaffTitles(token = undefined) {
        return axios({
            method: 'GET',
            timeout: Api.Timeout,
            url: `${Api.Host}/staff/titles`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });
    }
}