import React from 'react';
import { Redirect } from 'react-router-dom';
import HTTPStatusInterceptor from './HTTPStatusInterceptor';
import { useLocation } from 'react-router-dom';
import { authData, LocationState } from 'Routes';

interface Props {
    children: JSX.Element;
    auth: () => authData;
}

const blackList = ['/logga-ut', '/'];

export const RequiresAuth = ({ children, auth }: Props) => {
    const location = useLocation<LocationState>();
    const { isAuthed } = auth();
    return isAuthed ? (
        <HTTPStatusInterceptor>{children}</HTTPStatusInterceptor>
    ) : (
        <Redirect
            exact
            to={{
                pathname: '/logga-in',
                search:
                location.pathname && !blackList.includes(location.pathname)
                    ? `?redirectedFrom=${encodeURIComponent(location.pathname + location.search)}`
                    : undefined,
            }}
        />
    );
};
