import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { Bars } from 'react-loader-spinner';
import ConfirmationMenu from '../ConfirmationMenu/ConfirmationMenu';

const useStyles = makeStyles()(theme => ({
    base: {
        borderRadius: '500px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textTransform: 'none',
    },
    upperCase: {
        textTransform: 'uppercase',
    },
    noShadow: {
        boxShadow: 'none !important',
    },
    white: {
        backgroundColor: '#fff',
    },
}));

export interface ButtonProps extends Omit<MuiButtonProps, 'color' | 'variant'> {
    classes?: any;
    color?:
        | 'default'
        | 'gray' // note that we use gray instead of grey here, this is because we can't override the default grey.
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'bluegreen'
        | 'primaryDark'
        | 'yellow'
        | 'white'
        | 'error';
    variant?: 'text' | 'outlined' | 'contained';
    noShadow?: boolean;
    upperCase?: boolean;
    loading?: boolean;
    white?: boolean;
    requireConfirmation?: boolean;
    confirmationTitle?: string;
    confirmationMessage?: React.ReactNode;
}

// eslint-disable-next-line react/display-name
const Button = React.forwardRef<any, ButtonProps>(
    (
        {
            classes: classesprops,
            color,
            variant,
            noShadow = true,
            upperCase,
            loading,
            children,
            onClick,
            white,
            requireConfirmation,
            confirmationTitle,
            confirmationMessage,
            ...others
        }: ButtonProps,
        ref
    ) => {
        if (!variant) {
            variant = 'text';
        }
        if (!color || color === 'default') {
            color = 'gray';
        }
        const { classes, cx } = useStyles();
        const [confirmationOpen, setConfirmationOpen] = React.useState(false);
        const mixedClass = { ...classesprops };
        mixedClass.root = cx(
            classes.base,
            {
                [classes.noShadow]: noShadow,
                [classes.upperCase]: upperCase,
                [classes.white]: variant === 'outlined' && white,
            },
            mixedClass?.root
        );

        const handleOnClick = (e: any) => {
            if (requireConfirmation) {
                setConfirmationOpen(true);
            } else {
                onClick && onClick(e);
            }
        };

        const renderUpdateDialog = () =>
            requireConfirmation && (
                <ConfirmationMenu
                    open={confirmationOpen}
                    title={confirmationTitle || 'Är du säker?'}
                    message={confirmationMessage}
                    confirmLabel='Ja'
                    confirmFunction={(e: any) => {
                        setConfirmationOpen(false);
                        onClick && onClick(e);
                    }}
                    cancelLabel={'Avbryt'}
                    cancelFunction={() => setConfirmationOpen(false)}
                    onClose={() => setConfirmationOpen(false)}
                    maxWidth='sm'
                />
            );

        return (
            <>
                <MuiButton
                    {...others}
                    ref={ref}
                    color={color}
                    variant={variant}
                    classes={mixedClass}
                    onClick={loading ? undefined : handleOnClick}>
                    {loading ? (
                        <Bars
                            height='20'
                            width='20'
                            color={variant === 'contained' ? 'white' : 'black'}
                        />
                    ) : (
                        children
                    )}
                </MuiButton>
                {requireConfirmation && renderUpdateDialog()}
            </>
        );
    }
);

export default Button;
