import React from 'react';
import App from 'pages/App';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import useDocumentTitle from 'components/hooks/useDocumentTitle';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as FourOFourSVG } from 'assets/404.svg';
import Button from 'components/interface/Button/Button';
import useMeStore from 'store/useMeStore';


const useStyles = makeStyles<void, 'show'>()((theme, _params, classes) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        opacity: '0',
        transition: 'opacity 0.5s ease-in-out',
        [`&.${classes.show}`]: {
            opacity: '1',
        },
    },
    base: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '500px',
        width: '100%',
        flexShrink: 0,
        padding: theme.spacing(0, 2),
    },
    svg: {
        width: '100%',
        height: 'auto',
        margin: theme.spacing(4),
    },
    text: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
    },
    show: {},
}));

export default function NotFoundPage() {
    useDocumentTitle('404');
    const { classes, cx } = useStyles();
    const [show, setShow] = React.useState(false);
    const isLoggedIn = useMeStore(state => state.isLoggedIn);

    React.useEffect(() => {
        let timer: NodeJS.Timer = setTimeout(() => {
            setShow(true);
        }, 500);
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);

    return (
        <App>
            <div className={cx(classes.wrapper, { [classes.show]: show })}>
                <div className={classes.base}>
                    <FourOFourSVG className={classes.svg} />
                    <div className={classes.text}>
                        <Typography
                            variant='h6'
                            color='textPrimary'
                            align='left'>
                            Nu är du vilse
                        </Typography>
                        <Typography
                            variant='body1'
                            color='textPrimary'
                            align='left'>
                            Klicka på knappen för att hitta tillbaka.
                        </Typography>
                    </div>

                    <NavLink to={isLoggedIn ? '/' : '/logga-in'}>
                        <Button variant='contained' color='primaryDark'>
                            Klick här för att gå tillbaka
                        </Button>
                    </NavLink>
                </div>
            </div>
        </App>
    );
}
