import React from 'react';
import { makeStyles } from 'tss-react/mui';
import MuiTextField, {
    TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles<void, 'noMargin' | 'outlineWhite' | 'noBorder'>()(
    (theme, _, classes) => ({
        base: {
            //width: "100%",
            maxWidth: '300px',
        },
        root: {
            height: '40px',
            [`&.${classes.noBorder}`]: {
                '&::after': {
                    border: 'none !important',
                    content: 'none',
                },
                '&::before': {
                    border: 'none !important',
                    content: 'none',
                },
            },
        },
        inputOutlined: {
            padding: theme.spacing(1, 2),
        },
        white: {
            'backgroundColor': '#fff',
            //'borderRadius': theme.shape.borderRadius,
            '& > div:focus': {
                backgroundColor: '#fff',
            },
        },
        fieldWrapper: {
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
        },
        field: {
            width: '100%',
            maxWidth: '300px',
            [`&.${classes.noMargin}`]: {
                margin: 0,
            },
        },
        noMargin: {},
        zIndex: {
            'zIndex': '1',
            '& > div': {
                zIndex: '1',
            },
            '& > input': {
                zIndex: '1',
            },
            '& > textarea': {
                zIndex: '1',
            },
        },
        outline: {
            border: 'none',
            background: theme.palette.background.backgroundColorDark,
            [`&.${classes.outlineWhite}`]: {
                background: '#fff',
            },
        },
        outlineWhite: {},
        fullWidth: {
            width: '100% !important',
            maxWidth: '100% !important',
        },
        multiline: {
            height: 'unset',
        },
        heightUnset: {
            height: 'unset',
        },
        noAutofillColor: {
            '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
            },
            '&:-webkit-autofill:hover': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
            },
            '&:-webkit-autofill:focus': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
            },
            '&:-webkit-autofill:active': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
            },
        },
        noBorder: {
            border: 'none',
        },
    })
);

interface LabelProps {
    text: string;
    inheritColor?: boolean;
    noMargin?: boolean;
}

const Label = ({ text, inheritColor, noMargin }: LabelProps) => (
    <Typography
        variant='body2'
        color={inheritColor ? 'inherit' : 'textSecondary'}
        style={{ userSelect: 'none', marginBottom: noMargin ? '0px' : '4px' }}>
        {text}
    </Typography>
);

export type TextFieldProps = Omit<
    MuiTextFieldProps,
    'onChange' | 'variant' | 'InputProps'
> & {
    classes?: any;
    onChange?: (
        value: string,
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
    variant?: 'filled' | 'outlined' | 'standard';
    InputProps?: Partial<OutlinedInputProps>;
    label?: string;
    endButton?: React.ReactNode;
    fullWidth?: boolean;
    multiline?: boolean;
    heightUnset?: boolean;
    white?: boolean;
    noAutofillColor?: boolean;
    noBorder?: boolean;
};

const TextField = React.forwardRef<any, TextFieldProps>(
    (
        {
            classes: classesProps,
            onChange,
            variant = 'outlined',
            InputProps,
            label,
            endButton,
            fullWidth,
            multiline,
            heightUnset,
            white = true,
            noAutofillColor,
            noBorder,
            margin,
            ...other
        }: TextFieldProps,
        ref
    ) => {
        const { classes, cx } = useStyles();
        const classesInputProps: any = {};
        if (variant === 'filled') {
            classesInputProps['notchedOutline'] = cx(classes.outline, {
                [classes.outlineWhite]: !!white,
            });
            classesInputProps['root'] = cx(
                InputProps?.classes?.root,
                classes.zIndex
            );
            variant = 'outlined';
        } else if (variant === 'outlined') {
            classesInputProps['notchedOutline'] = cx(
                InputProps?.classes?.notchedOutline,
                { [classes.noBorder]: noBorder }
            );
            classesInputProps['root'] = cx(InputProps?.classes?.root);
        } else if (variant === 'standard') {
            classesInputProps['root'] = cx(InputProps?.classes?.root, {
                [classes.noBorder]: noBorder,
            });
        }

        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(e.target.value, e);
            }
        };

        return (
            <div
                className={cx(classes.base, classesProps?.base, {
                    [classes.fullWidth]: fullWidth,
                })}>
                {label ? <Label text={label} /> : undefined}
                <span className={classes.fieldWrapper}>
                    <MuiTextField
                        ref={ref}
                        margin={margin || 'dense'}
                        variant={variant}
                        InputProps={{
                            ...InputProps,
                            classes: {
                                ...classesInputProps,
                                root: cx(
                                    classes.root,
                                    classesInputProps?.root,
                                    {
                                        [classes.multiline]: multiline,
                                        [classes.heightUnset]: heightUnset,
                                        [classes.white]:
                                            white && variant === 'outlined',
                                    }
                                ),
                                input: cx(classesProps?.input, {
                                    [classes.inputOutlined]:
                                        variant === 'outlined' && !multiline,
                                    [classes.noAutofillColor]: noAutofillColor,
                                }),
                            },
                        }}
                        className={cx(classes.field, classesProps?.field, {
                            [classes.noMargin]: !margin,
                            [classes.fullWidth]: fullWidth,
                        })}
                        fullWidth={fullWidth}
                        multiline={multiline}
                        onChange={handleOnChange}
                        {...other}
                    />
                    {endButton ? endButton : undefined}
                </span>
            </div>
        );
    }
);

export default TextField;
export { Label };
