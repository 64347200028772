import React from 'react';
import { makeStyles } from 'tss-react/mui';
import useCompany from 'store/useCompanyStore';
import useWindowWidth from 'components/hooks/useWindowWidth';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme, params, classes) => ({
    base: {
        width: '100%',
        minHeight: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#2e2e2e',
        color: '#fff',
        position: 'absolute',
        left: '0',
        bottom: '0',
        padding: '0 0',
    },
    footer: {
        padding: theme.spacing(2, 4),
        maxWidth: '1414px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    provider: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        color: 'rgba(255, 255, 255, 0.7)',
    },
    providerText: {
        fontFamily: "'Ubuntu', sans-serif",
        fontWeight: '700',
    },
    providerName: {
        fontFamily: "'Ubuntu', sans-serif",
        fontWeight: '700',
        color: '#EB5565',
    },
    footerInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
}));

export default function Footer() {
    const { company } = useCompany();
    const { isMobile } = useWindowWidth();
    const { classes, cx } = useStyles();

    const renderFooter = () => {
        return (
            <div className={classes.base}>
                <div className={classes.footer}>
                    <div className={classes.provider}>
                    <Typography color='inherit' variant='caption' className={classes.providerText}>
                            Provided by{' '}<span className={classes.providerName}>{company.provider}</span>
                    </Typography>
                    <Typography color='inherit' variant='caption' >
                    Version {process.env.REACT_APP_BUILD_VERSION}
                    </Typography>
                    </div>
                    <Typography color='white' variant='body2' className={classes.footerInfo}>
                        <span>
                            <b>KONTAKTA OSS</b>
                        </span>
                        <span>{company.name}</span>
                        <span>{company.address}</span>
                        <span>{company.zip + ' ' + company.city}</span>
                        <span>{'Org. nr: ' + company.organizationNumber}</span>
                        <span>{'Telefon: ' + company.phone}</span>
                        <a href={'mailto:' + company.mail}>{company.mail}</a>
                    </Typography>
                </div>
            </div>
        );
    };

    return <>{!isMobile ? renderFooter() : undefined}</>;
}
