import React, { Component } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    DetailsTable,
    DetailsRow,
} from 'components/interface/DetailsTable/DetailsTable';
import { Widget } from 'components/interface/Widget/Widget';
import Currency from 'components/interface/currency/Currency';

const useStyles = makeStyles()((theme, params, classes) => ({
    columnWrapper: {
        display: 'flex',
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
    },
    item: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(2),
    },
}));

export default function InformationWidget({
    data,
    loading,
    error,
    stats,
    totalReportedDays,
    totalReportedTime,
    renderTimesheetButton,
    isStatsLoading,
    isTimereportLoading,
}) {
    const { classes, cx } = useStyles();

    const renderInformationData = () => {
        return (
            <DetailsTable title='Information'>
                <DetailsRow
                    name={'Land'}
                    value={data?.country?.name || '-'}
                    loading={loading}
                />
                <DetailsRow
                    name={'Region'}
                    value={data?.county?.name || '-'}
                    loading={loading}
                />
                <DetailsRow
                    name={'Bruttoersättning'}
                    value={
                        <Currency
                            value={data?.pay?.revisedValue || data?.pay?.value}
                            currencyCode={data?.country?.currencyCode}
                        />
                    }
                    loading={loading}
                />
            </DetailsTable>
        );
    };
    const renderTimesheetData = () => {
        return (
            <>
                <DetailsTable title='Tidrapport'>
                    <DetailsRow
                        name={'Totalt antal timmar'}
                        value={totalReportedTime}
                        loading={isTimereportLoading}
                    />
                    <DetailsRow
                        name={'Antal rapporterade dagar'}
                        value={totalReportedDays}
                        loading={isTimereportLoading}
                    />
                    <DetailsRow
                        name={'Status'}
                        value={
                            !!data?.timesheet?.date
                                ? 'Inskickad'
                                : 'Ej inskickad'
                        }
                        loading={isTimereportLoading}
                    />
                </DetailsTable>
                <div style={{ paddingTop: '32px' }}>
                    {renderTimesheetButton()}
                </div>
            </>
        );
    };
    const renderStatsData = () => {
        return (
            <DetailsTable title='Statistik hos vårdgivare'>
                <DetailsRow
                    name={'Antal uppdrag'}
                    value={stats?.totalAssignments + ' st'}
                    tooltipText={'Inkluderar alla uppdrag'}
                    tooltipPlacement='left'
                    loading={isStatsLoading}
                />
                <DetailsRow
                    name={'Antal avslutade uppdrag'}
                    value={stats?.finishedAssignments + ' st'}
                    tooltipText={"Inkluderar uppdrag med status 'Avslutad'"}
                    tooltipPlacement='left'
                    loading={isStatsLoading}
                />
            </DetailsTable>
        );
    };

    return (
        <Widget title='Om uppdraget'>
            <div className={classes.columnWrapper}>
                <div className={classes.column}>
                    <div className={classes.item}>
                        {renderInformationData()}
                    </div>
                    <div className={classes.item}>{renderStatsData()}</div>
                </div>
                <div className={classes.column}>
                    <div className={classes.item}>{renderTimesheetData()}</div>
                </div>
            </div>
        </Widget>
    );
}
