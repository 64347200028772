import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { green, pink, red, grey, purple } from '@mui/material/colors';
import { statusToString } from 'utils/assignment';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles()((theme, { color }, classes) => ({
    base: {
        'borderRadius': '50px',
        'display': 'inline-flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'padding': theme.spacing(0.5, 1),
        'userSelect': 'none',
        'height': '22px',
        'flexShrink': 0,
        '& span': { lineHeight: '1', fontWeight: '500' },
        'border': '1px solid ' + color.text,
    },
    icon: {
        height: '24px',
        width: '24px',
        [`&.${classes.large}`]: {
            height: '32px',
            width: '32px',
        },
    },
    background: { backgroundColor: color.background },
    text: { color: color.text, whiteSpace: 'nowrap' },
    large: {},
}));

export const STATUS = {
    POSITIVE: 1,
    NEUTRAL: 2,
    NEGATIVE: 3,
    PRIMARY: 4,
    GRAY: 5,
};

const Chip = ({
    classes: classesProps,
    color,
    text,
    icon: Icon,
    tooltipText,
    large,
}) => {
    const { classes, cx } = useStyles({ color });

    const render = () => (
        <div
            className={cx(classes.base, classes.background, classesProps, {
                [classes.icon]: !!Icon,
                [classes.large]: !!large,
            })}>
            {!!Icon ? (
                <Icon className={classes.text} fontSize='small' />
            ) : (
                <Typography variant='caption' className={classes.text}>
                    {text}
                </Typography>
            )}
        </div>
    );

    const wrapInTooltip = () => (
        <Tooltip
            title={
                typeof tooltipText === 'string' ? (
                    <Typography variant='caption' color='inherit'>
                        {tooltipText}
                    </Typography>
                ) : (
                    tooltipText
                )
            }
            placement='top'
            disableInteractive>
            {render()}
        </Tooltip>
    );

    return tooltipText ? wrapInTooltip() : render();
};

Chip.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.object,
    icon: PropTypes.object,
};

export default function StatusChip({ status, ...rest }) {
    const theme = useTheme();

    const getColor = () => {
        let obj = {};
        switch (status) {
            case 1:
                obj.background = green[100];
                obj.text = green[900];
                break;
            case 2:
                obj.background = theme.palette.yellow[100];
                obj.text = theme.palette.yellow[900];
                break;
            case 3:
                obj.background = pink[50];
                obj.text = pink[900];
                break;
            case 4:
                obj.background = theme.palette.primary[50];
                obj.text = theme.palette.primary[1000];
                break;
            default:
                obj.background = grey[200];
                obj.text = grey[900];
                break;
        }
        return obj;
    };

    return <Chip color={getColor()} {...rest} />;
}

StatusChip.propTypes = {
    status: PropTypes.number.isRequired,
    ...Chip.propTypes,
};

export function AssignmentStatusChip({ status, ...rest }) {
    const theme = useTheme();

    const getColor = () => {
        let obj = {};
        switch (status) {
            case 3:
                obj.background = green[100];
                obj.text = green[900];
                break;
            case 4:
                obj.background = theme.palette.yellow[100];
                obj.text = theme.palette.yellow[900];
                break;
            case 5:
                obj.background = pink[50];
                obj.text = pink[900];
                break;
            default:
                obj.background = grey[200];
                obj.text = grey[900];
                break;
        }
        return obj;
    };

    return <Chip color={getColor()} text={statusToString(status)} {...rest} />;
}

AssignmentStatusChip.propTypes = {
    status: PropTypes.number.isRequired,
};
