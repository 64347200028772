import React from 'react';
import App from 'pages/App';
import Page from 'components/Page';
import { LoadingGrid } from 'components/interface/Loading';
import useDocumentTitle from 'components/hooks/useDocumentTitle';

const MyProfile = React.lazy(() => import('components/myProfile/View'));

export default function UserPage() {
    useDocumentTitle('Min profil');

    return (
        <App>
            <Page>
                <React.Suspense fallback={<LoadingGrid />}>
                    <MyProfile />
                </React.Suspense>
            </Page>
        </App>
    );
}
