import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Logo from 'components/interface/Logo/Logo';
import InstallBanner from '../installBanner';
import UpdateBanner from '../updateBanner';
import { isMobile } from 'react-device-detect';
import useInstallerStore from 'store/useInstallerStore';
import { User } from 'typings/user';
import { NavigationParent, NavigationChildProps } from './Navigation';
import { useRouteMatch } from 'react-router';
import UserButton from './UserButton';

const useStyles = makeStyles()((theme, _params, _classes) => ({
    base: {
        width: '100%',
        minHeight: '60px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#ffffff',
        borderBottom: '1px solid ' + theme.palette.divider,
        position: 'sticky',
        top: '0',
        left: '0',
        zIndex: '100',
    },
    header: {
        'width': '100%',
        'maxWidth': '1414px',
        'height': '60px',
        'display': 'flex',
        'flexDirection': 'row',
        'justifyContent': 'space-between',
        'alignItems': 'center',
        '@media (max-width: 1478px)': {
            //1478 = 1414+64
            padding: theme.spacing(0, 4),
        },
    },
    navigation: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: '32px',
    },
}));

interface HeaderProps {
    me: User;
    puppeteer: User | null;
}

export default function Header({ me, puppeteer }: HeaderProps) {
    const showInstallBanner = useInstallerStore(state => state.show);
    const { classes } = useStyles();

    const children: Array<NavigationChildProps> = [
        {
            text: 'Aktuella uppdrag',
            link: '/uppdrag',
            active: !!useRouteMatch({
                path: '/uppdrag',
                strict: false,
                exact: true,
            }),
        },
        {
            text: 'Alla uppdrag',
            link: '/uppdrag/alla',
            active: !!useRouteMatch({
                path: '/uppdrag/alla',
                strict: false,
                exact: true,
            }),
        },
    ];

    return (
        <div className={classes.base}>
            {showInstallBanner && isMobile ? <InstallBanner /> : undefined}
            <UpdateBanner hide={showInstallBanner && isMobile} />
            <div className={classes.header}>
                <Logo variant='primary' isHeader />
                <div className={classes.navigation}>
                    <NavigationParent
                        text='Mina uppdrag'
                        active={
                            !!useRouteMatch({
                                path: '/uppdrag',
                                strict: false,
                            })
                        }
                        children={children}
                    />
                    <UserButton me={me} puppeteer={puppeteer} />
                </div>
            </div>
        </div>
    );
}
