import Api from 'api/Api';
import useMeStore from 'store/useMeStore';
import useNotificationStore, {
    NotificationTypes,
} from 'store/useNotificationStore';
import { isMobileSafari, isSafari } from 'react-device-detect';

export default function useAssignmentPDF() {
    const token = useMeStore(state => state.token);

    const pushNotification = useNotificationStore(state => state.push);

    const fetchTimesheetPDF = async (
        id: string,
        year?: number | null,
        week?: number | null
    ) => {
        Api.assignment
            .fetchAssignmentTimesheetPDF(token, id)
            .then(function (response) {
                const { status, data } = response;
                if (status === 200) {
                    const href = URL.createObjectURL(data);

                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    let name = 'tidrapport';
                    if (year && week) {
                        name = `tidrapport_${year}_${week}`;
                    }
                    name += '.pdf';
                    link.setAttribute('download', name); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } else {
                    throw new Error('Failed fetching pdf');
                }
            })
            .catch(function (err) {
                console.log(err);
                pushNotification(
                    NotificationTypes.error,
                    err?.response?.data?.message ||
                        'Det gick inte att hämta tidrapport, försök igen.',
                    'pdf-fetch-fail',
                    1
                );
            });
    };

    const fetchBookingConfirmationPDF = (id: string) => {
        const windowReference = window.open();
        const fetch = async () => {
            try {
                const response =
                    await Api.assignment.fetchAssignmentBookingConfirmationPDF(
                        token,
                        id
                    );
                const { status, data } = response;
                if (status === 200) {
                    if (data.fileURL?.length > 0 && windowReference) {
                        windowReference.location.replace(data.fileURL);
                    } else if (data.token?.length > 0) {
                        openPDF(data.token, windowReference);
                    } else {
                        throw new Error('Failed fetching pdf');
                    }
                } else {
                    throw new Error('');
                }
            } catch (err: any) {
                console.log(err);
                pushNotification(
                    NotificationTypes.error,
                    err?.response?.data?.message ||
                        'Det gick inte att hämta tidrapport, försök igen.',
                    'assignment',
                    2
                );
            }
        };
        fetch();
    };

    function openPDF(token: string, windowReference?: Window | null) {
        const query = new URLSearchParams({
            token: token,
            inline: isMobileSafari || isSafari ? 'false' : 'true',
        }).toString();
        if (windowReference) {
            windowReference.location.replace(
                Api.OldHost + '/view_pdf.php?' + query
            );
        } else {
            window.open(
                Api.OldHost + '/view_pdf.php?' + query,
                isMobileSafari || isSafari ? '_self' : '_blank'
            );
        }
    }

    return {
        fetchTimesheetPDF,
        fetchBookingConfirmationPDF,
    };
}
