import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { grey, red } from '@mui/material/colors';
import TrueIcon from '@mui/icons-material/Check';
import FalseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';

const useStyles = makeStyles<
    void,
    | 'isMobile'
    | 'noPadding'
    | 'disabled'
    | 'falseState'
    | 'nullState'
    | 'trueState'
    | 'center'
>()((theme, _, classes) => ({
    base: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: 0,
        [`&.${classes.isMobile}`]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            height: '48px',
        },
        [`&.${classes.noPadding}`]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        cursor: 'pointer',
        [`&.${classes.disabled}`]: {
            cursor: 'unset',
        },
        [`&.${classes.center}`]: {
            justifyContent: 'center',
        },
    },
    label: {
        userSelect: 'none',
        marginRight: theme.spacing(2),
    },
    switch: {
        display: 'flex',
        border: grey[400] + ' 1px solid',
        borderRadius: theme.shape.borderRadius,
    },
    button: {
        padding: theme.spacing(0.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [`&.${classes.falseState}:not(.${classes.disabled})`]: {
            background: red[50],
        },
        [`&.${classes.falseState}.${classes.disabled}`]: {
            background: alpha(red[50], 0.5),
        },

        [`&.${classes.nullState}:not(.${classes.disabled})`]: {
            background: grey[200],
        },
        [`&.${classes.nullState}.${classes.disabled}`]: {
            background: alpha(grey[200], 0.5),
        },

        [`&.${classes.trueState}:not(.${classes.disabled})`]: {
            background: theme.palette.primary[50],
        },
        [`&.${classes.trueState}.${classes.disabled}`]: {
            background: alpha(theme.palette.primary[50], 0.5),
        },
    },
    falseButton: {
        'borderRadius': '8px 0px 0px 8px',
        '&:hover': {
            background: alpha(red[50], 0.5),
        },
    },
    nullButton: {
        '&:hover': {
            background: alpha(grey[200], 0.5),
        },
        [`&.${classes.isMobile}`]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    trueButton: {
        'borderRadius': '0px 8px 8px 0px',
        '&:hover': {
            background: alpha(theme.palette.primary[50], 0.5),
        },
    },
    state: {
        color: grey[600],
        [`&.${classes.falseState}:not(.${classes.disabled})`]: {
            color: red[900],
        },
        [`&.${classes.falseState}.${classes.disabled}`]: {
            color: alpha(red[900], 0.5),
        },

        [`&.${classes.nullState}:not(.${classes.disabled})`]: {
            color: grey[900],
        },
        [`&.${classes.nullState}.${classes.disabled}`]: {
            color: alpha(grey[900], 0.5),
        },

        [`&.${classes.trueState}:not(.${classes.disabled})`]: {
            color: theme.palette.primary[900],
        },
        [`&.${classes.trueState}.${classes.disabled}`]: {
            color: alpha(theme.palette.primary[900], 0.5),
        },
    },
    icon: {
        fontSize: '14px',
        [`&.${classes.isMobile}`]: {
            fontSize: '16px',
        },
    },
    text: {
        width: '50px',
    },
    falseState: {},
    nullState: {},
    nullText: {
        lineHeight: '14px',
    },
    trueState: {},
    divider: { width: '1px', background: grey[400] },
    isMobile: {},
    noPadding: {},
    disabled: {},
    center: {},
}));

interface Props {
    value: boolean | null;
    label?: string;
    asText?: boolean;
    onChange: (value: boolean | null) => void;
    disableNull?: boolean;
    disabled?: boolean;
    noTooltipDelay?: boolean;
    isMobile?: boolean;
    noPadding?: boolean;
    center?: boolean;
    classes?: string;
}

export default function TriStateSwitch({
    value,
    label,
    asText,
    onChange,
    disableNull,
    isMobile,
    disabled,
    noTooltipDelay,
    noPadding,
    center,
    classes: classesProps,
}: Props): JSX.Element {
    const { classes, cx } = useStyles();
    const switchRef = React.useRef<HTMLDivElement>(null);

    const setTrue = () => onChange(true);
    const setFalse = () => onChange(false);
    const setNull = () => onChange(null);

    const handleClickText = (event: React.MouseEvent<HTMLElement>) => {
        if (disabled) {
            return;
        }
        if (
            switchRef.current &&
            event.target &&
            switchRef.current.contains(event.target as Node)
        ) {
            return;
        }
        if (value === true) {
            setFalse();
        } else if (value === false) {
            if (disableNull === true) {
                setTrue();
            } else {
                setNull();
            }
        } else if (value === null) {
            setTrue();
        }
    };

    return (
        <div
            className={cx(
                classes.base,
                { [classes.isMobile]: isMobile },
                { [classes.noPadding]: noPadding },
                { [classes.center]: center },
                { [classes.disabled]: disabled },
                classesProps
            )}
            onClick={handleClickText}>
            {label ? (
                <Typography
                    className={cx(classes.label, {
                        [classes.disabled]: disabled,
                    })}
                    variant='body1'
                    color='textPrimary'>
                    {label}
                </Typography>
            ) : null}
            <div className={classes.switch} ref={switchRef}>
                <Tooltip
                    placement='top'
                    enterDelay={noTooltipDelay ? 0 : 2000}
                    title={
                        <Typography color='inherit' variant='caption'>
                            Nej
                        </Typography>
                    }
                    disableInteractive>
                    <span>
                        <ButtonBase
                            onClick={setFalse}
                            className={cx(classes.button, classes.falseButton, {
                                [classes.falseState]: value === false,
                                [classes.disabled]: disabled,
                            })}
                            disabled={disabled}>
                            {asText ? (
                                <Typography
                                    className={cx(classes.text, classes.state, {
                                        [classes.falseState]: value === false,
                                        [classes.disabled]: disabled,
                                        [classes.isMobile]: isMobile,
                                    })}
                                    variant='body2'>
                                    Nej
                                </Typography>
                            ) : (
                                <FalseIcon
                                    className={cx(classes.icon, classes.state, {
                                        [classes.falseState]: value === false,
                                        [classes.disabled]: disabled,
                                        [classes.isMobile]: isMobile,
                                    })}
                                />
                            )}
                        </ButtonBase>
                    </span>
                </Tooltip>
                {disableNull ? undefined : (
                    <>
                        <div className={classes.divider} />
                        <Tooltip
                            placement='top'
                            enterDelay={noTooltipDelay ? 0 : 2000}
                            title={
                                <Typography color='inherit' variant='caption'>
                                    Ignorera
                                </Typography>
                            }
                            disableInteractive>
                            <span>
                                <ButtonBase
                                    onClick={setNull}
                                    className={cx(
                                        classes.button,
                                        classes.nullButton,
                                        {
                                            [classes.nullState]: value === null,
                                            [classes.disabled]: disabled,
                                            [classes.isMobile]: isMobile,
                                        }
                                    )}
                                    disabled={disabled}>
                                    <Typography
                                        variant='body1'
                                        color={
                                            value === null
                                                ? 'textPrimary'
                                                : 'textSecondary'
                                        }
                                        className={classes.nullText}>
                                        -
                                    </Typography>
                                </ButtonBase>
                            </span>
                        </Tooltip>
                    </>
                )}

                <div className={classes.divider} />
                <Tooltip
                    placement='top'
                    enterDelay={noTooltipDelay ? 0 : 2000}
                    title={
                        <Typography color='inherit' variant='caption'>
                            Ja
                        </Typography>
                    }
                    disableInteractive>
                    <span>
                        <ButtonBase
                            onClick={setTrue}
                            className={cx(classes.button, classes.trueButton, {
                                [classes.trueState]: value === true,
                                [classes.disabled]: disabled,
                            })}
                            disabled={disabled}>
                            {asText ? (
                                <Typography
                                    className={cx(classes.text, classes.state, {
                                        [classes.trueState]: value === true,
                                        [classes.isMobile]: isMobile,
                                        [classes.disabled]: disabled,
                                    })}
                                    variant='body2'>
                                    Ja
                                </Typography>
                            ) : (
                                <TrueIcon
                                    className={cx(classes.icon, classes.state, {
                                        [classes.trueState]: value === true,
                                        [classes.isMobile]: isMobile,
                                        [classes.disabled]: disabled,
                                    })}
                                />
                            )}
                        </ButtonBase>
                    </span>
                </Tooltip>
            </div>
        </div>
    );
}
